@media screen and (min-width: 40em)
.social-media-links {
    flex-grow: 1;
}

.social-media-list li a:hover {
    opacity:0.7;
    color: green;
    cursor: pointer;
    color: #767676;
}
