/*style = {{border:'1px solid red'}}*/

.navigation{

  /*'position':'relative','text-align':'center','top':'0', 'margin':'0', 'padding':'','width':'100%'*/
  display: flex;
  /*background-color: #f2f2f2;*/
  background-color: #ffffff;


  font-family: "Euclid-Regular",Arial,sans-serif;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  background: #fff;
  font-size: .75rem;
  text-align: center;
  /*position: relative;*/
  text-transform: uppercase;
  /*width: 17rem;*/
  width:100%;
  z-index: 999;
  /*box-shadow: 0 2px 2px -2px rgba(0,0,0,.2);*/
  border-bottom: 1px solid rgba(156,156,156,0.3);;
}

.shadow {


    /*box-shadow: 0 2px 2px -2px hsla(0,0%,60%,.4);*/

}
.navigation h1{
  margin: 10px 0px 10px;
  font-size: 16px;
  color: #191919;
}

.main-nav-banner-title-logo h1 {
  margin: 10px 0px 10px;
  font-size: 16px;
  color: #191919;

}

.main-nav-banner-title-logo:hover h1 {
  margin: 10px 0px 10px;
  font-size: 16px;
  color: #191919;
  cursor: pointer;
  text-decoration:underline;
}


.navigation ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  /*background-color: #f2f2f2*/
  background-color: #ffffff;
  text-align: center;
  /*box-shadow*/
}

.navigation ul li {
  /*float: right;*/
  margin: 0 ;
  padding: 0px;
  display: inline-block;
}

.navigation ul li a {
  font-size: 14px;
  color: #1E1E1E;
}

.navigation ul li a:hover {
  font-size: 14px;
  color: #1E1E1E;
  cursor: pointer;
}

.sticky{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    box-shadow: 0 1px 4px rgba(156,156,156,0.3);
}


/* New Navigation Styles*/
.main-nav-banner {
  display: flex;
  position:relative;
  align-items: center;
}

@media (min-width:600px)
.main-nav-banner-2 {
    padding-left:24px;
    padding-right: 24px;
}

.main-nav-banner-container{
  /* 68 76 80 85*/
  width : 100%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;

  align-items: center;

  align-content: center;

  justify-content: center;


}

.main-nav-banner-title {
  /*67 69 105*/

  margin: 0;
  box-sizing: border-box;

  flex-grow: 0;
  max-width: 33.33333333%;
  flex-basis: 33.33333333%;
}

.main-nav-banner-title-inner {
  /* 170 172*/

}

.main-nav-banner-title-logo{
  position: relative;
  display: block;
  margin: 0 auto;

  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  min-height: 1rem;
  /*min-height: 3rem;*/
}

.main-nav-banner-title-logo-strapline{
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 8px;
}

.main-nav-banner-account {
  /*67 69 105*/

  margin: 0;
  box-sizing: border-box;

  flex-grow: 0;
  max-width: 33.33333333%;
  flex-basis: 33.33333333%;
}

.main-nav-banner-account-inner {
  /* 170 173 */
  display: flex;
  text-align: right;
  padding-left: 16px;
  padding-right: 16px;
  justify-content: flex-end;

}

.main-nav-banner-account-controls {
  /* 209 180 177 182*/
  width: auto;
  border: 0;
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  outline: 0;
  position: relative;
  align-items: center;
  user-select: none;
  vertical-align: middle;
  justify-content: center;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
}

.main-nav-banner-account-controls-container {
  /*181 178*/
  width: 100%;
  display: inherit;
  align-items: inherit;
  justify-content: inherit;

  flex-direction: column;
}

.main-nav-banner-account-controls-icon{
  /*204 179 206*/
  display: inherit;

  margin-right: 0;

  margin-left: -2px;
}

.main-nav-banner-account-controls-icon{
  /*width: 1em;*/
  height: 1em;
  overflow: unset;
  flex-shrink: 0;
  user-select: none;
  font-size: 18px;

}

.main-nav-banner-account-login {
  /*221 224*/
  margin-top: 5px;
  /*margin: 0;*/

  font-size: 0.75rem;
  /*font-family: */
  font-weight: 400;
  line-height: 1.66;

  text-transform: uppercase;

}

.main-nav-banner-account-login-a-link{
  /*221 374 376 222 244*/

  /*221*/
  margin: 0;


  /*374*/
  color: rgb(33, 33, 33);
  cursor: pointer;

  /*376*/
  text-decoration: none;

  /*222*/
  font-size: 0.75rem;
  /*  font-family: */
  font-weight: 400;
  line-height: 1.43;

  /*244*/


}

.main-nav-banner-account-login-a-link:hover{
  /*221 374 376 222 244*/

  /*221*/
  margin: 0;

  /*374*/
  color: rgb(33, 33, 33);
  cursor: pointer;

  /*376*/
  text-decoration: underline;

  /*222*/
  font-size: 0.75rem;
  /*  font-family: */
  font-weight: 400;
  line-height: 1.43;

  /*244*/


}



.user-logo{
  color:  #c0c0c0;
  font-weight: 600;
}

.main-nav-banner-other {
  /*67 69 105*/

  margin: 0;
  box-sizing: border-box;

  flex-grow: 0;
  max-width: 33.33333333%;
  flex-basis: 33.33333333%;
}

.main-nav-banner-other-inner {
  /* 170 171 */
  /*display: flex;
  text-align: left;
  padding-left: 16px;
  padding-right: 16px;
  justify-content: flex-end;*/

  display: flex;
  text-align: left;
  padding-left: 16px;
  padding-right: 16px;

}


.main-nav-banner-links{
  width: 100%;
  display: block;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;

}

@media (min-width: 600px)
.main-nav-banner-links {
    padding-left: 24px;
    padding-right: 24px;
}

@media (min-width: 1280px)
.main-nav-banner-links-2 {
    max-width: 1440px;
}

.main-nav-banner-divider {
  border: none;
  height: 1px;
  margin: 0;
  flex-shrink: 0;
  background-color: #c0c0c0;
}

.main-nav-banner-links-container {
  /* 33 321 36*/
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  top: 0;
  position: sticky;
  background-color: transparent;

  box-shadow: none;

}

.main-nav-banner-links-contents {
  /* 336 */
  display: flex;
  overflow: hidden;
  min-height: 48px;
}

.main-nav-banner-links-contents-inner {
  /*341 342*/
  flex: 1 1 auto;
  display: inline-block;
  position: relative;
  white-space: nowrap;

  width: 100%;
}

.main-nav-banner-links-list {
  /*338 340*/
  display: flex;

  align-items: center;
  justify-content: center;

}

.main-nav-banner-nav-link {
  /* 209 384 320 386*/
  width: auto;
  border: 0;
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  outline: 0;
  align-items: center;
  user-select: none;
  border-radius: 0;
  vertical-align: middle;
  justify-content: center;
  text-decoration: none;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;

  /*384*/
  padding: 6px 12px;
  overflow: hidden;
  position: relative;
  font-size: 0.875rem;
  max-width: 264px;
  box-sizing: border-box;
  min-height: 48px;
  text-align: center;
  flex-shrink: 0;
  /*font-family: */
  font-weight: 500;
  line-height: 1.75;
  white-space: normal;
  text-transform: uppercase;

  /*320*/
  opacity:1 ;
  min-width: auto;

  /*386*/
  color: inherit;

}


@media (min-width: 600px)
.main-nav-banner-nav-link{}


.main-nav-banner-nav-link-item{
  /*393*/
  width: 100%;
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}


.main-nav-banner-nav-link-item-a-link{
  /*221 374 376 222 244*/

  /*221*/
  margin: 0;

  /*374*/
  color: rgb(33, 33, 33);
  cursor: pointer;

  /*376*/
  text-decoration: none;

  /*222*/
  font-size: 0.875rem;

  /*  font-family: */
  font-weight: 400;
  line-height: 1.43;

  /*244*/


}

@media screen and  (max-width: 700px)
{
  .main-nav-banner-nav-link-item-a-link
  {
    font-size: 0.75rem;
  }
}


.main-nav-banner-nav-link-item-a-link:hover {
  /*221 374 376 222 244*/

  /*221*/
  margin: 0;

  /*374*/
  color: rgb(33, 33, 33);
  cursor: pointer;


   text-decoration: underline;

  /*222*/
  font-size: 0.875rem;
  /*  font-family: */
  font-weight: 400;
  line-height: 1.43;

  /*244*/


}

.cart__summary__display {
    top: 0;
    right: 10;
    transform: scale(1) translate(50%, -50%);
    transform-origin: 100% 0%;



    color: rgba(0, 0, 0, 0.87);
    /*background-color: #69A740;*/
    background-color: #EFEFEF;
    border: 1px solid #888888;


    height: 20px;
    display: flex;
    padding: 0 6px;
    z-index: 1;
    position: absolute;
    flex-wrap: wrap;
    font-size: 0.75rem;
    min-width: 20px;
    box-sizing: border-box;
    transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    align-items: center;
    /*font-family: Euclid-Regular, Arial, sans-serif, Helvetica, Arial;*/
    font-weight: 500;
    line-height: 1;
    align-content: center;
    border-radius: 10px;
    flex-direction: row;
    justify-content: center;
}
