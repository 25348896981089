
.jumbotron{

  background-size: cover;
  height:1067px;


  background-image: url(/img/jumbotron/wine-toast-1600x1067.jpg);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat !important;
  /*
  height: 100%;
  left: 0;
  top: 0;*/
  width:80%;
  max-width: 1067px;



}
/*
.jumbotron-fluid .container-fluid {
  position: relative;
  z-index: 2;


}*/

.jumborton_controls a {
  text-decoration:none;
color: inherit;
}

.jumbotron__scrolldown{
  /*font-size: 2em;
  color: #006670;
  cursor:pointer;*/


  z-index: 100;
  position: absolute;
  top: 55%;
  left:50%;
  /*left: -2%;*/

  border: .0625rem solid #d8d9db;
  border-radius: 50%;
  /*border-color: rgba(0, 0, 0, 0.5);*/
  height: 42px;
  width: 42px;
  background-color: rgba(255, 255, 255, 0.5);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0.125rem 0.5rem hsla(0, 0%, 60%, .4);
  /*opacity: 50%;*/
}

/*
.intro .goBack:hover {
  cursor: pointer;
}*/

.jumbotron__scrolldown:hover
{
  cursor: pointer;
  /*opacity: 70%;*/
  background-color: rgba(255, 255, 255, 0.7);
  border-color: rgba(0, 0, 0, 0.7);
  transition: .25s ease-in-out;
}



/* The Homepage Hero Styling*/
.homepage__hero_original {

  position: relative;
  /*background: url(../img/jumbotron/tuscany_1600x1062.jpg);*/
  /*background: url(`${JumboImg}`);*/
  -webkit-background-size: cover;
  -mox-background-size: cover;
  background-size: cover;
  text-align: center;

  padding-top: 110px;
  /*min-height: 500px;*/
  min-height:800px;
  letter-spacing: 2px;
  /*width: 1488px; height:837px*/

  width: 100%;
  object-fit: contain;
  margin-bottom: 20px;
}


/* The Homepage Hero Styling*/
.homepage__hero {
  position: relative;
  /*background: url(/img/jumbotron/tuscany_1600x1062.jpg) no-repeat center center fixed;*/
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  text-align: center;
  color: #fff;
  padding-top: 110px;
  min-height: 500px;
  letter-spacing: 2px;
  width:100%;

}
