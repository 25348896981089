.user_card {
  width: 350px;
  margin-top: auto;
  margin-bottom: auto;
  background: #74cfbf;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
}

.form_container {
  margin-top: 20px;
}

.login_btn {
  width: 100%;
  /*background: #33ccff !important;*/
  /*color: white !important;*/
  color: #212121 !important;
  border-color:#212121 !important;
  background: transparent;
  transition: border-color 0.2s;
  align: center;

}

.login_btn:hover {
  cursor: pointer;
  color: white !important;
  border-color: white;
  background-color:#212121 !important;
}
.login_container {
  padding: 0 2rem;
}

.input-group-text {
  /*background: #f7ba5b !important;*/
  background: rgba(0, 0, 0, 0.12) !important;
  color: white !important;
  border: 0 !important;
  border-radius: 0.25rem 0 0 0.25rem !important;

  /*some extra styling*/
  outline: 0;
  border-bottom: 1px solid #d2d2d2 !important;
  transition: border-color 0.2s !important;
}


.authentication__page__shell {
  --page-shell-max-content-width: 1440px !important;
}
.authentication__page__content {
      display: block;
}

@media (min-height: 475px) and (min-width: 744px)
..authentication__panel__container  {
    padding: 40px !important;
}

.authentication__panel__container {
  -webkit-box-pack: center !important;
  display: flex !important;
  justify-content: center !important;
  margin-bottom: 40px;
}
.authentication__panel {
  border: 1px solid #B0B0B0B0 !important;
  border-radius: 12px !important;
  position: relative !important;
  max-width: 568px !important;
  width: 100% !important;
  margin-top: 32px !important;
  overflow: visible !important;

  /* some fancy stylings*/
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
  -webkit-box-shadow: 0 .5px 1px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 0 .5px 1px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
  border-radius: 2px;
}

@media (min-width: 744px) .panel__header {
  min-height: 64px !important;
}

.panel__header {
  -webkit-box-pack: justify !important;
  -webkit-box-align: center !important;
  margin-top: 20px;

  flex: 0 0 auto !important;
  align-items: center !important;
  justify-content: space-between !important;
  border-bottom: 1px solid #EBEBEB !important;
  padding-top: 0px !important;
  padding-left: 24px !important;
  padding-right: 24px !important;
  color: #222222 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  font-weight: 800 !important;

}


.panel__header  .pannel__header__leftbox {
  -webkit-box-flex: 0 !important;
  flex-grow: 0 !important;
  flex-shrink: 0 !important;
  flex-basis: 16px !important;
}
.panel__header  .pannel__header__title{
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  flex-grow: 0 !important;
  flex-basis: auto !important;
  text-align: center !important;
  margin-left: 16px;
  margin-right: 16px;
}
.panel__header  .pannel__header__rightbox {
  -webkit-box-flex: 0 !important;
  flex-grow: 0 !important;
  flex-shrink: 0 !important;
  box-sizing: border-box;
}

.login__panel__containter {
  padding: 32px !important;
  margin-top: 20px;
}

.registration__panel__containter {
  padding: 32px !important;
  margin-top: 20px;
}

.login__panel {

}
.authentication__page__bottom {
  min-height: 10px;
}

.login__register__link:hover {
  cursor: pointer !important;
  color: #000000 !important;
  text-decoration: underline !important;
}

.login__register__link:visited {
  color: #717171 !important;
  text-decoration: underline !important;
}

.login__register__link {
  font-size: inherit !important;
  font-family: inherit !important;
  font-style: inherit !important;
  font-variant: inherit !important;
  line-height: inherit !important;
  color: #222222 !important;
  text-decoration: underline !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
  outline: none !important;
}
