.sign-up-form {
  font-family: "Euclid-Regular",Arial,sans-serif;
  font-size: 12px;
}

.sign-up-form  label {
  display: inline-block;
  margin: auto;
  text-transform: uppercase;
}

.sign-up-form .nameDetails {
  width: 90%;

}

.sign-up-form .adressDetails {
  width: 90%;
}

.sign-up-form .form-field {
  border: 0;
  border-bottom: 1px solid #d2d2d2;
  outline: 0;
  /*font-size: 14px;*/
  color: #212121;
  background: transparent;
  transition: border-color 0.2s;
}

.confirm-sign-up-modal {
  position: fixed;
  left: 0;
  top: 0;
  overflow: auto;
  z-index: 999;
  height: 100%;
  width: 100%;

  background: rgba(0, 0, 0, 0.65);
}



/*
.main-page-content .intro-copy {
  text-align: center;
  color: #8D8478;
  margin-bottom: 40px;
}
*/

.confirm-sign-up-modal .modal-dialog {


  width: auto;
  display: inline-block;
  /* below is the main text fonts and styles*/
  font-family: 'Open Sans', sans-serif;

  position: relative;
  /*top: 30%;*/
  top: 0%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /*max-width: 50%; /*  modify for the width you want*/
  height: auto;
  /*margin: 10% auto;*/
  padding: 1rem 1.5rem;
  border: none;
  outline: none;
  border-radius: 4px;
  /*box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16);*/
  animation: openModal 1s ease;
}

.confirm-sign-up-modal .modal-dialog .modal-header {
   border-bottom: 0 none;

}

.confirm-sign-up-modal .modal-dialog .modal-header .modal-title {

  text-align: center;
  text-transform: uppercase;
  font-size: 22px;


  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0 none;


}

.confirm-sign-up-modal .modal-body {
  text-align: center;
  color: #8D8478;


  padding: 1.5rem 0;
  border-bottom: 0 none;
}

.confirm-sign-up-modal .modal-footer {
  border-top: 0 none;

}


.modal-footer .social-media-links .social-media-list{
  margin: 0;
  text-align: center;
}

.modal-footer .social-media-links .social-media-list .social-media_item{
  display: inline-block;
  list-style-type: none;
  margin: auto;
  text-align:left;
  color: #767676;
  font-size:14px;

  /*margin-left:20px;
  margin-right:20px;*/

}

.modal-footer .social-media-links .social-media-list .social-media_item a{
  color: #7676767;

}

.modal-footer .social-media-links .social-media-list .social-media_item .social-media_link{
  display: inline-block;
  background-repeat: no-repeat;
}

.modal-body .offering-copy {
  margin-top:10px;
  font-size: 14px;
  text-align: center;
  margin-left:20px;
  margin-right:20px;
  font-weight: 500;
}


.modal-col-img .img-centered {

  /*width: 100%;
  object-fit: contain;
  max-width: 400px;
  margin-left:20px;
  margin-right:20px;*/

  display: block;
  /* overflow: visible; */
  
  margin-left: auto;
  margin-right: auto;
  /* width: 100%; */
  align-items: center;
  width: 100%;
  object-fit: contain;

}



.request-info-form {
  font-family: "Euclid-Regular",Arial,sans-serif;
  font-size: 12px;
}

.request-info-form  label {
  display: inline-block;
  margin: auto;
  text-transform: uppercase;
}

.request-info-form .nameDetails {
  width: 90%;

}

.request-info-form .adressDetails {
  width: 90%;
}

.request-info-form .requestDetails {



}

.request-info-form .requestDetails .textarea__requestDetails
{
  width: 100%;
  height: 200px;
  resize: vertical;
  /* trying to make this risponsive
  object-fit: contain;*/
  box-sizing: border-box;


  /* rows = "10" cols="75"
}


.request-info-form .form-field {
  border: 0;
  border-bottom: 1px solid #d2d2d2;
  outline: 0;
  /*font-size: 14px;*/
  color: #212121;
  background: transparent;
  transition: border-color 0.2s;
}

/* sum custom buttons*/
.button__sqs_clone {
  padding: 5.5px 22px;
  border-radius: 100px;
  line-height: 22px;
  font-size: 12px;
  transition: all .2s ease-in-out;

  background-color: white;
  color: rgb(178, 173, 166);
  border-color: rgb(178, 173, 166);

}

  .button__sqs_clone_dark {
      background-color: #3E3E3E;
      color: #FFF;

      &:hover {
        background-color: #000;
      }
  }

  .submit-button-style{
    margin-top: 10px;
    color: #212121 !important;
    border-color:#212121 !important;
    background: transparent;
    transition: border-color 0.2s;
    align: center;
  }

  .submit-button-style:hover{
    color: white !important;
    border-color: white !important;
    background-color:#212121 !important;
  }
