/*Journal Page Styles*/


.main-page-content .journal__page__strapline {
  text-align: center;
  text-transform: uppercase;
  /*margin-bottom: 40px;*/
  font-size: 22px;
  margin: 1.5em auto .3em;
  /*margin-top: 20px;*/
  margin-top: 0px;
  font-weight: 600;
}

.main-page-content .journal__page__intro-copy {
  text-align: center;
  color: #8D8478;
  /*margin: 1.5em auto .3em;
  margin-bottom: 40px;*/


  /* extra syling to constrain the text*/
  max-width: 580px;
  font-weight:300;
  margin-left:auto;
  margin-right: auto;
  font-size: 18px;


}

.main-page-content .journal__page__intro-copy-paragraph {
  margin-top: 20px;
  margin-bottom: 20px;

  text-align: center;
  color: #8D8478;
  /*margin: 1.5em auto .3em;
  margin-bottom: 40px;*/

  max-width: 1000px;
  font-weight:300;
  margin-left:auto;
  margin-right: auto;
  font-size: 16px;

}


.journalMenu-slider__wrapper{

  position: relative;

}
.journalMenu-slider__wrapper .journalMenu-slider{
  /*border: 1px solid red;*/
  overflow: hidden;

  /*margin: 10px auto;*/

}

.slider__controls{

  z-index: 100;
  position: absolute;
  top: 50%;
  transform: translateY(50%);

}

.journalMenu-slider__wrapper .slider__prev{
  /*font-size: 2em;
  color: #006670;
  cursor:pointer;*/

  position: absolute;
  top: 50%;
  z-index: 1;
  left: -2%;


  border: .0625rem solid #d8d9db;
  border-radius: 50%;
  height: 42px;
  width: 42px;
  background-color: #fff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0.125rem 0.5rem hsla(0, 0%, 60%, .4);
}

.journalMenu-slider__wrapper .slider__next{
  /*font-size: 2em;
  color: #006670;
  cursor:pointer;*/

  position: absolute;
  top: 50%;
  z-index: 1;
  right: -2%;

  border: .0625rem solid #d8d9db;
  border-radius: 50%;
  height: 42px;
  width: 42px;
  background-color: #fff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0.125rem 0.5rem hsla(0, 0%, 60%, .4);
}


.journal__post{
  text-align: center;
  /*border: 1px solid black;*/
  /*width: 600px;*/
  /*style={{border:"1px solid red"}}*/
  height: auto;
  margin: 0px 2px;
  overflow: hidden;
}

.journal__post .journal-media{

}

.journal-image {
    /*trying to make the image resposive*/
    /* removed these class elements
    align-self-end mt-3 mb-3
    */
  width: 100%;
  object-fit: contain;
  max-width: 300px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin: auto;
}

.journal-image:hover {
  cursor: pointer;
}


.journal__post .journal__category {

  color: #ccaa6f;
  text-decoration: none;
  text-align: left;
  font-size: 12px; /*.75rem*/
  text-transform: uppercase;
  font-weight: 500;
  margin: auto;
  max-width: 300px; /*Same width as journal-image*/
}
.journal__post .journal-title {
  text-transform: none;
  font-size: 22px; /*1.375rem;*/
  line-height: 26px; /* 1.625rem;*/
  font-weight: 400;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  z-index: 2;
  text-align: center;
  align-items: center;
  max-width: 300px; /*Same width as journal-image*/
  min-height:50px;
}

.journal__post .journal-title {
    cursor: pointer;
    color: #414141;
}

.journal__post .journal-copy {
  font-size: 17px;
  text-align: center;
}

.journal__copy__strapline{
  color: #888888;
  text-decoration: none;
  font-size: 12px; /*.75rem*/
  text-transform: none;
  font-weight: 500;
  align-items:center;
  min-height:30px;
  max-height: 30px;
}

.journal__card__divider {
  content: '';
  display: block;
  width: 25px;
  height: 1px;
  background-color: #888888;
  margin:auto;
  margin-top: 5px;
  align-items:center;
  margin-bottom: 5px;
}

/* Journal Post Stylings*/
.journal__copy__date {
    color: #888888;
    font-weight: 400;
    font-size: 12px;
    text-transform: uppercase;
    align-items:center;
}


.journal__post-content-full
{

}
.journal__post-content
{
  background-color: #FFFFFF;
}

.main-journal-content
{
  font-family: 'Open Sans', sans-serif;
}

.main-journal-content .breadcrumbs {
  text-align: center;
  text-transform: uppercase;
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 16px;
  color: #8D8478;
}

.main-journal-content .strapline {
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 40px;
  font-size: 22px;
  max-width: 300px; /*Same width as journal-image*/
}


.intro {
  position: relative;
}

.main-journal-content .intro .intro-copy {
  text-align: center;
  margin-bottom: 20px;
}

.intro .journal__goBack{
  /*font-size: 2em;
  color: #006670;
  cursor:pointer;*/


  z-index: 100;
  position: absolute;
  top: 50%;
  /*left: -2%;*/

  border: .0625rem solid #d8d9db;
  border-radius: 50%;
  height: 42px;
  width: 42px;
  background-color: #fff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0.125rem 0.5rem hsla(0, 0%, 60%, .4);
}


.main-journal-content .journal__article__intro-copy {
  text-align: center;
  text-transform: uppercase;
  /*margin-bottom: 40px;*/
  font-size: 22px;
  margin: 1.5em auto .3em;
  /*margin-top: 20px;*/
  margin-top: 0px;
  font-weight: 600;
}

.main-journal-content .journal__article__strapline {
  text-align: center;
  color: #8D8478;
  /*margin: 1.5em auto .3em;
  margin-bottom: 40px;*/


  /* extra syling to constrain the text*/
  max-width: 580px;
  font-weight:300;
  margin-left:auto;
  margin-right: auto;
  font-size: 18px;
  margin-bottom: 20px;


}
/*
.intro .goBack:hover {
  cursor: pointer;
}*/

.intro .journal__goBack:hover
{
  cursor: pointer;
}

.journal-article-hero{
  position : relative;
  margin-bottom: 70px;
}



.journal-article-hero .hero-overlay{
  text-align: center;
  position: absolute;
  text-transform: lowercase;
  z-index: 0;
  width: 70%;
  /*top: 95%;*/
  bottom: -5%;
  transform: translateX(25%);
  background: white;
  padding: 20px 0px 20px;

}

.hero-overlay .shadow{
  position: relative;
  box-shadow: 0 1px 4px rgba(156, 156, 156, 0.3)
}

.hero-overlay p {
  text-align: center;
  font-size: 22px;
  margin: 10px 0;
}

.journal__content__paragraph {
    font-family: 'Open Sans', sans-serif;


}

.journal__content__paragraph li {
  margin: 40px;
}

.in-the-mix__soundcloud{

}

.player-wrapper
{
  overflow: hidden;
}

.reactplayer__soundcloud
{
  margin: 10px 0 10px;
  width: auto;
  height: auto;
}

/* The below are the original styling. above are the updated*/

.journal-content {
  font-family: 'Open Sans', sans-serif;
  padding: 20px;
  color: #414141;
}

.journal-post-item:hover {
  cursor: pointer;
}
.journal-content .journal-title {
  text-transform: none;
  font-size: 32px;
  margin-bottom: 10px;
  z-index: 2;
  text-align: center;
}

.journal-content .journal-copy {
  font-size: 17px;
  text-align: center;
}

/*
  Journal Image Sizes
  We should make the below reactive to screen size
*/

.journal-media img {
  height: 400px;
  width: 400px;
}

.journal-article-hero .journal-article-hero__image{
  display: block;
  /* overflow: visible */
  z-index:-1;
  margin-left: auto;
  margin-right: auto;

  /*width: 100%;*/

  /* trying to make the hero responsive*/
  align-items: center;
  width: 100%;
  object-fit: contain;

}

.journal-article-hero .journal-article-hero__image{
  height:600px;
}
