body {
  width:100% !important;
}
.all-page {

  width: 100%;
  max-width: 2000px;



}
.app-page-content{
    width: 100%;

    /*background-color: #f2f2f2;*/
    background-color: #FFFFFF;
    /*object-fit: contain;*/

}




h1 {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
}
li a {
  display: block;
  color: black;
  text-align: center;
  padding: 14px;
  text-decoration: none;
}

.main-page-intro{
  font-family: 'Open Sans', sans-serif;
  font-size: 17px;

}

.main-page-content {
  font-family: 'Open Sans', sans-serif;

}

.main-page-content .strapline {
  text-align: center;
  text-transform: uppercase;
  /*margin-bottom: 40px;*/
  font-size: 22px;
  margin: 1.5em auto .3em;
  /*margin-top: 20px;*/

}



.main-page-content .intro-copy {
  text-align: center;
  color: #8D8478;
  margin: 1.5em auto .3em;
  margin-bottom: 40px;
}

.main-page-content .body-copy {
  text-align: center;
  color: #8D8478;
  margin-bottom: 40px;
  margin-top: 40px;
}

.infoCard {
  border: none;
  box-shadow: 0 0 2px;
}

.offering-content {
  font-family: 'Open Sans', sans-serif;
  padding: 20px;
  color: #414141;
}
.offering-content .offering-title{
  text-transform: none;
  font-size: 32px;
  margin-bottom: 10px;
  z-index: 2;
  text-align: center;
}

.offering-content .offering-copy {
  font-size: 17px;
  text-align: center;
}


.img-centered {
  margin: 0 auto;

  /*trying to make the image resposive*/
  width: 100%;
  object-fit: contain;
  max-width: 400px;
}




.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /*background-color: #282c34;*/
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*custom buttons*/
.learn_more-button-style{
  margin-top: 10px;
  color: #212121 !important;
  border-color:#212121 !important;
  background: transparent;
  transition: border-color 0.2s;
  align: center;
}

.learn_more-button-style:hover{
  color: white !important;
  border-color: white;
  background-color:#212121 !important;
}
