.events_and_experiences__hero {
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;

}

.events_and_experiences__hero-md {
  margin-bottom: 30px;
  padding-top: 50px;
}

.events_and_experiences__hero-container {
  -webkit-box-flex: 0;
  /*flex: 0 0 83.33%;*/
  /*max-width: 83.33%;*/

  width: 100%;
  padding: 0;
  position: relative;
}

.events_and_experiences__hero-image {
  /*BlogHero__Image*/
  /*display:flex;*/
  /*BlogHero-Image--md*/
  margin-left: auto;

  /*BlogHero-Image--lg*/
  margin-right: 30px;

  /*BlogHero-Image--xl*/
  /*height: 428px;*/
  /*width: 760px;*/
  width: 100%;
  object-fit: contain;
}


.event-experience_Hero_Img_Centered {
    margin: 0 auto;
    width: 100%;
    object-fit: contain;
    height: auto;
    border-radius: 4px;
}

.events_and_experiences__hero-image-img-content {
  background: center center no-repeat;
  /*background-size: cover;*/
  display: flex;
  width: 100%;
  object-fit: contain;


}

.events_and_experiences__hero-image-img-content_{
  display: block;
  /* overflow: visible */
  z-index:-1;
  margin-left: auto;
  margin-right: auto;

  /*width: 100%;*/

  /* trying to make the hero responsive*/
  align-items: center;
  width: 100%;
  object-fit: contain;
  height:600px;

}

.Link__article {
  color: #2a2a2a;
  /*font-family: Beatrice,GT America,Helvetica,Verdana,sans-serif;*/
  font-weight: 700;
  line-height: 1.38;
  letter-spacing: -.25px;
  text-decoration: none;
}

.Link {
      font-size: 16px;
      transition: background .15s ease-out,color .25s ease-out,opacity .25s ease-out,box-shadow .15s ease-out,-webkit-box-shadow .15s ease-out;
}

.events_and_experiences__hero-page-description {

  left: 0;

  /*BlogHero__Content*/
  background: #fff;
  padding: 15px;

  /*BlogHero__Content-md*/
  bottom: 0;
  margin-right: 0;
  margin-bottom: 0;
  padding-left: 0;
  padding-bottom: 0;
  position: absolute;
  top: auto;


  /*BlogHero__Content-lg*/
  width: 420px;

  /*BlogHero__Content-xl*/
}

.events_and_experiences__hero-page-description-title {
  /*Link*/
  font-size: 16px;
  transition: background .15s ease-out,color .25s ease-out,opacity .25s ease-out,box-shadow .15s ease-out,-webkit-box-shadow .15s ease-out;


  /*Link__article*/
  color: #2a2a2a;
  /*font-family: Beatrice,GT America,Helvetica,Verdana,sans-serif;*/
  font-weight: 700;
  line-height: 1.38;
  letter-spacing: -.25px;
  text-decoration: none;

  /*BlogHero__Tag*/
  /*cursor: pointer;*/


}

.events_and_experiences__hero-page-description-title-main {
  /*Link*/
  font-size: 16px;
  transition: background .15s ease-out,color .25s ease-out,opacity .25s ease-out,box-shadow .15s ease-out,-webkit-box-shadow .15s ease-out;


  /*Link__article*/
  color: #2a2a2a;
  /*font-family: Beatrice,GT America,Helvetica,Verdana,sans-serif;*/
  font-weight: 700;
  line-height: 1.38;
  letter-spacing: -.25px;
  text-decoration: none;

  margin-bottom: 2px;
}

.events_and_experiences__hero-page-description-subtitle {
  font-size: 14px;
  line-height: 1.43;
  color: #737373;
  font-weight: normal;

}

.HeroDetails {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -flex-direction: column;
  padding-left: 10px;
  padding-right: 15px;
}

.Blurb {
  padding: 10px 0;
  margin-bottom: 5px;
  min-width:200px;
  width: 100%;
}

.Blurb__Content {
  font-size: 12px;
  margin-bottom: 10px;
}

.HeroDetails__SocialMedia {
  display: flex;
  margin-bottom: 35px;
}
.HeroDetails__social-media_link{
  font-size: 21px;
  margin-right: 20px;
  cursor: pointer;
}

.HeroDetails__social-media_link:hover {

}
