/*https://www.flaticon.com/categories/social-media*/
/*https://www.iconfinder.com/icons/5282542/linkedin_linkedin_logo_network_social_network_icon*/
/*https://fontawesome.com/icons?d=gallery&q=facebook*/
/*style = {{border:'1px solid black'}}*/
.footer {
  background: #F3F3F3;
  font-family: "Euclid-Regular",Arial,sans-serif;
  color: #212121;

}

.footer .footer-subscription {
  margin: auto;
  text-align: center;
}

.footer .footer-subscription h5 {
  display: block;
  margin: auto;
  font-size: 32px;
  margin-bottom: 20px;
}

.footer .footer-subscription p {
  display: block;
  margin: auto;
  font-size: 12px;
  margin-bottom: 10px;
}


.footer .footer-subscription .contact-us{
  display: block;
  margin: auto;
  font-size: 12px;
  margin-bottom: 20px;
}

.footer .social-media-header {
  text-align: center;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 800;
}

.footer .social-media-links .social-media-list{
  margin: 0;
  text-align: center;
}

.footer .social-media-links .social-media-list .social-media_item{
  display: inline-block;
  list-style-type: none;
  margin: auto;
  text-align:left;
  color: #767676;
}

.footer .social-media-links .social-media-list .social-media_item a{
  color: #7676767;
}

.footer .social-media-links .social-media-list .social-media_item .social-media_link{
  display: inline-block;
  background-repeat: no-repeat;
}

.footer .footer-notes {
  text-align: center;
  font-size: 12px;
  text-transform: uppercase;
  padding: 0px 20px;
}

.footer .footer-notes a {
  font-size: 14px;
  color: #1E1E1E;
}

.footer .footer-notes a:hover {
  font-size: 14px;
  color: #1E1E1E;
}


.socials {
  border-bottom: 1px solid rgba(30, 30, 30, 0.5);
}
.footer-notes {
  margin-top: 20px;
  font-family: 'Open Sans', sans-serif;
  padding: 20px;
  color: #414141;
  align-items: center;

}

.footer-notes .tta__logo {
  margin: 0 auto;
  width: 100%;
  max-width: 150px;
  object-fit: contain;
  align-items: center;
  margin-bottom: 20px;
}

.footer-notes .tta__logo:hover {
  cursor: pointer;
}

.footer-notes .footnote__copyright p{
  text-transform: none;
  font-size: 12px;
  margin-bottom: 10px;
  z-index: 2;
  align-items: center;
  text-align: center;
}



.footnote__tsandcs a {
  text-transform: none;
  font-size: 32px;
  margin-bottom: 10px;
  z-index: 2;
  align-items: center;
  text-align: center;
}
