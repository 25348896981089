.Side__Navbar {
  white-space: nowrap;
  margin-bottom: auto;
  /*margin-right: 24px;*/
  /*width       : 0;*/
  /*min-width: 308px;*/
  width: 100%;
  padding: 0;
  border-radius: 2px;
  border: 1px solid #e6e6e6;
  height: 100%;
}

.side__navigation__menu_flex {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}


.nav_links ul{
  margin: 0px 0px 0px 0px !important;
  padding: 0px 0px 0px 0px !important;
  /*margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
  list-style-type: none;*/

  /*
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #ffffff;
  text-align: center;
  */


  /*background-color: #f2f2f2*/
  /*box-shadow*/
}


.nav_links li {

  margin: 0;
  padding: 16px;
  width: 100%;
  text-decoration: none;
  display: list-item;
  /*text-align: -webkit-match-parent;*/

  border-style: none;
  display: flex;
  overflow: hidden;

  text-decoration: none;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 400;


  /*margin: 0 ;
  padding: 0px;
  border-bottom:  1px solid rgba(156,156,156,0.3);
  font-size: 14px;*/

  /*display: inline-block;*/
  /*float: right;*/

}

.nav_links_content{
  width: 100%;
}

.nav_links li :hover {
  color: #0071c2 !important;
  font-size: 16px;
  cursor: pointer;
  /*border-bottom:  1px solid black;*/

  /*text-transform: uppercase;*/
  text-decoration: none;
  font-weight: 400;
}

.nav_links_content :after {
  /*content: " ";
  height: 0;
  margin: 0 16px;
  border-radius: 2px;
  display: block;
  border: 1px #e6e6e6;
  border-style: solid solid none;*/
}

.icon {
       vertical-align: middle;

     }

/*
.nav_links ul li {
  margin: 0 ;
  padding: 0px;
  display: inline-block;
}*/

.navbarOther {
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
}
.menu-bars {
  /*margin-left: 2rem;*/
  color: black !important;
  background: none;

  width: 100%;
  text-decoration: none;
  display: list-item;
  text-align: -webkit-match-parent;

  border-style: none;
  display: flex;
  overflow: hidden;
  /*padding: 16px;*/
  text-decoration: none;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 400;
}

.menu-bars :hover {
  cursor: pointer;
  color: #0071c2 !important;
}

.sidebar-nav-menu {
  /*background-color: #060b26;*/
  background-color: white;
  width: 275px;
  height: 100%;
  display: flex;
  justify-content: center;
  /*position: fixed;*/
  transform: translateX(-0%);
  /*position: relative;*/
  position: absolute; /*this is a good one to use*/
  /*position: static;*/
  top: 0;
  left: -200%;
  transition: 850ms;
  z-index:5;

}

.sidebar-nav-menu.active {
  left: 0;
  /*align-items: left;*/
  transition: 350ms;
}

.dashboard-contents-container {
  position: relative;
  min-height: 800px;
}
