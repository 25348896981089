.cart__page__strapline {
    text-align: center;
    text-transform: uppercase;
    /* margin-bottom: 40px; */
    font-size: 22px;
    margin: 1.5em auto .3em;
    /* margin-top: 20px; */
    margin-top: 0px;
    font-weight: 600;
}

.stack {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.stack-element {
    margin: 0 0 1.5rem 1.5rem;
}

.stack-element-main {
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    width: 100%;
    margin-bottom: 5px;
}
.stack-element-inner {
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    width: 100%;
}

.cart-stack-element-title {
  margin: 0 0 1.5rem 1.5rem;
  flex: auto;
  max-width: 100%;
  position: relative;
  justify-content: center;
}

.spacing__large {
    margin-bottom: 1.5rem;
}

.cart__page__intro-copy {
    text-align: center;
    color: #8D8478;
    max-width: 580px;
    font-weight: 300;
    margin-left: auto;
    margin-right: auto;
    font-size: 22px;
}


.shopping-links-wrapper {
  display:flex;
  align-items: center;
  margin-top: 10px;
}
.shopping-links {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    z-index: 2;
    /* color: #8d8478; */
    color: #000;
    font-weight: 400;
    font-size: 14px;
    text-transform: uppercase;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    color: #8D8478;

}

.shopping-links-separator {
    background: #919191;
    border-radius: 50%;
    margin: 0 10px;
    width: 5px;
    height: 5px;
    display: flex;
    align-items: center;
}

.cart-link {
  text-decoration: none;
  color: #8D8478;
}

.cart-link:hover {
  cursor: pointer;
  text-decoration: none;
  color: black;

}

@media (min-width: 64rem)
.show-for-large-formats {
    display: block;
}
@media (min-width: 30rem)
.hide-for-small-screens {
    display: none;
}

.cart-page {
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #fff;

}

.cart-page-elevation {
  box-shadow: none;
}

.cart-title-container {
  /*width: 100%;
  display: flex;
  padding: 1rem 0;
  flex-wrap: nowrap;
  align-items: center;
  flex-direction: row;
  text-transform: uppercase;*/

  display: flex;
  justify-content: center;
  align-items: center;

}
.title__ruler{
  width: 25%;
  color: #ABABAB;
  /*background: #ABABAB;*/



}

.cart-entry{
  /*box-shadow:hsl(0, 0%, 80%) 0 0 16px;*/
  box-shadow: 0 0.125rem 0.5rem hsl(0deg 0% 60% / 40%)
}
.sticky-wrapper {
  position: relative;

}

.sticky-summary{
  position: sticky;
  /*position: fixed;
  width: 25%;*/
  /*position: relative;*/
  /*right: 0;*/
  /*top: 0;*/
  z-index: 1;
  /*right:0;*/

}



.cart-summary-title-container {
  width: 100%;
  display: block;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;

}

@media (min-width: 600px)
.cart-summary-title-container {
    padding-left: 24px;
    padding-right: 24px;
}

@media (min-width: 1280px)
.cart-summary-title-container-maxWidthLg {
    max-width: 1440px;
}

.cart__element__strapline {
    font-size: 1.25rem;
    text-align: center;
    /*font-family: Euclid-Regular, Arial, sans-serif, Helvetica, Arial;*/
    font-weight: 500;
    line-height: 1.6;
    margin: 0;
}
.product-image {
  width: 100%
}

.image-map-wrapper {
      text-align: center;
}

.cart_Img_Centered {
  margin: 0 auto;

  /*trying to make the image resposive*/
  width: 100%;
  object-fit: contain;
  max-width: 400px;
  max-height: 150px;
  height: auto;
  border-radius: 4px;
}

.cart_Event-title {
  text-transform: none;
  font-size: 18px; /*1.375rem;*/
  /*line-height: 26px; /* 1.625rem;*/
  font-weight: 400;
  /*margin: auto;*/
  /*margin-top: 10px;*/
  /*margin-bottom: 10px;*/
  /*z-index: 2;*/
  text-align: left;
  align-items: left;
  /*max-width: 300px; /*Same width as journal-image*/
  /*min-height:50px;*/
}

.text-right {
  text-align: right;
}

.remove-icon-wrapper {
  display: inline-block;
  position: relative;
}

.remove-icon-wrapper:hover {
  cursor: pointer;
}

.cart__copy__description {
    color: #888888;
    text-decoration: none;
    font-size: 12px;
    text-transform: none;
    font-weight: 500;
    align-items: left;
}

.my-cart-cards{
  width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.cart-summary-element-container {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #fff;
  max-width: 100%;
  flex-basis: 100%;
  border: 1px solid grey;
}

.cart-summary-content {
    margin: 12px 0;
    display: flex;
    flex-grow: 1;
    transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.quantity-button:hover {
    cursor: pointer;
}

.cart__buttons {
  z-index: 100;
  /*position: absolute;*/
  /*top: 50%;*/
  /* left: -2%; */
  /*border: .0625rem solid #d8d9db;*/
  border: none;
  /*border-radius: 50%;*/
  height: auto;
  /*width: 42px;*/
  background-color: #fff;
  /*display: inline-flex;*/
  justify-content: center;
  align-items: center;
  /*box-shadow: 0 0.125rem 0.5rem hsl(0deg 0% 60% / 40%);*/
  font-weight: 900;
}

.cart__buttons:hover {
    cursor: pointer;
}

.button_cursor:hover {
  cursor: pointer
}

.inc_dec_buttons {
  /*font-size:20px;
  height:20;
  color: #000;*/
  text-align: center;
  border: 1px solid;
  border-color: #888888;
  background: #EFEFEF;
  width: 24px;
  font-weight: 900;
}

.disable_inc {
  color: #fff
}

.quantity__controls {
    /*style = {{display:"flex", alignItems:"center",textAlign:"center"}}*/
    display: inline-flex;
    justify-content: center;
    align-items: center;


}

.quantity__controls__container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
}

.quantity__control_display{
  text-align: center;
  border: 1px solid;
  border-color: #888888;
  background: #FFFFFF;
  width: 24px;
}

.btn_hover_styles {
  background-color: #EFEFEF;
  color: red;
}

.bui-button {
  /*border: 1px solid #0071c2;*/
  /*border-radius: 2px;*/
  border: none;

  display: inline-flex;

  -webkit-box-align: center;
  align-items: center;
  display: block;

  -webkit-box-pack: center;
  justify-content: center;
  text-align: left;
  padding: 7px 15px;
  cursor: pointer;
  font-family: inherit;
  text-decoration: none;

  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;


  box-sizing: border-box;
  vertical-align: middle;
  position: relative;


  min-height: 36px;
  min-width: 36px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.bui-button:hover {
  text-decoration: underline;
}

.bui-button_cross{
  border: none;



}
.bui-button_cross_original {
  /*border: 1px solid #0071c2;*/
  /*border-radius: 2px;*/
  border: none;

  display: inline-flex;

  -webkit-box-align: center;
  align-items: center;
  display: block;

  -webkit-box-pack: center;
  justify-content: center;
  text-align: left;
  padding: 7px 15px;
  cursor: pointer;
  font-family: inherit;
  text-decoration: none;

  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;


  box-sizing: border-box;
  vertical-align: middle;
  position: relative;


  min-height: 36px;
  min-width: 36px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.order-summary-item-container {

    text-align: left;
    border: 1px solid;
    border-color: #888888;
    background: #EFEFEF;
    /*margin-bottom: 20px;*/
    margin:2px 2px 20px 2px;
    /*width: 24px;
    font-weight: 900;*/

}

.checkout-button {
  /*margin-top: 1rem;*/
  background-color: #212121;
  color: white;

  display: block;
  width: 100%;
  margin-left: 0;
  margin-right: 0;

  /*border styling*/
  border: 1px solid #212121;
  border-radius: .25rem;

  /*button text format*/
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  padding: 1rem 1.5rem;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  text-align: center;
  line-height: 1rem;

}

.checkout-button:hover {
    background-color: #212121;
    color: #767676;
}

.checkout-link {
  text-decoration: none;

}

.address-form-container {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #fff;
  width: 100%;

}

.payment-details-form-container {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #EFEFEF;;
  width: 100%;

}
.cart-title-container {
  width:100%
}

.address-form-wrapper {
  padding: 16px;
  flex-grow: 0;
  max-width: 100%;
  flex-basis: 100%;
  margin: 0;
  box-sizing: border-box;
}

.payment-details-form-wrapper {
  padding: 16px;
  flex-grow: 0;
  max-width: 100%;
  flex-basis: 100%;
  margin: 0;
  box-sizing: border-box;
}


.address-form-elements-wrapper {
    border: 0;
    margin: 0;
    display: inline-flex;
    padding: 0;
    position: relative;
    min-width: 0;
    flex-direction: column;
    vertical-align: top;
    text-transform: uppercase;
}

.address-form-input-wrapper {
  color: rgba(0, 0, 0, 0.87);
  cursor: text;
  display: inline-flex;

  font-size: 1rem;
  box-sizing: border-box;

  align-items: center;

  font-weight: 400;
  line-height: 1;
  line-height: 1.1876em;
  background-color: rgb(255, 255, 255);

}

.cart-page-forms-titles {
    color: #000;
    display: inline-block;
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 500;
    text-transform: uppercase;
}

.address-form-input {
    color: currentColor;
    width: 100%;
    border: 1px solid rgb(214, 214, 214);
    height: auto;
    margin: 0;
    display: block;
    padding: 0.75rem 1rem;
    position: relative;
    font-size: 0.875rem;
    background: none;
    box-sizing: content-box;

    animation-name: mui-auto-fill-cancel;
    letter-spacing: inherit;
    animation-duration: 10ms;
    -webkit-tap-highlight-color: transparent;
}

.payment-details-title-wrapper {
  justify-content: space-between;
  align-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.payment-details-title-container {
  display: flex;
  flex-grow: 1;
  transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  margin: 20px 5px 0 5px;
}

.age_confirm__container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
}

.cart-form-styles {

  /*class = "form-control form-control-sm form-field"*/
  vertical-align: middle;
  text-indent: 10px;
  width: 100%;
  border: 1px solid #ced4da;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background-color: #fff;
  font-size: 16px;
  height: calc(1.5em + .45rem + 2px);
  font: 400 ;


}  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aab7c4;
  opacity: 1; /* Firefox */
}

.cart-form-styles:focus {
  outline: none;
}

.cart-form-styles:hover {
  cursor: text;
}

.payment-form-cursor:hover {

}

.cart-form-text-element{
  display: flex;
  /*justify-content: center;*/
  vertical-align:middle;
  align-items: left;
  margin: 5px 0px;
  font-size: 12px;
}
