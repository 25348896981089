.review-header {
    font-weight: 700!important;
    font-size: 24px;
    line-height: 32px;
    text-rendering: optimizeLegibility;
}

.review-sub-heading {
  font-weight: 700!important;
  font-size: 18px;
  line-height: 24px;
}

.review-divider {
  margin-bottom: 0!important;

  background-color: transparent;
  display: block;
  height: 1px;
  border-width: 0;
  border-top: 1px solid #e7e7e7;
  line-height: 19px;
  margin-top: 0;

  width: calc(100% - 28px);
}

.review-form {

  width: 100%;

}

.review-comment-section {
  position: relative;
  padding: 14px;
}

.comment-area {
  height: 134px;
  margin-top: 0px;
  margin-bottom: 0px;

  background-color: #fff;
  width: 100%;
  padding: 5px 7px;
  resize: vertical;

  border: 1px solid #888C8C;
  border-radius: 3px;
  box-shadow: 0 1px 2px rgb(15 17 17 / 15%) inset;
  outline: 0;

  overflow: auto;
  vertical-align: top;
}

.review-button-section {
      text-align: right!important;
      padding: 14px;
}

.review-button {
  border-color: #D5D9D9;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  display: inline-block;
  padding: 0;
  text-align: center;
  text-decoration: none!important;
  vertical-align: middle;

  border-radius: 8px;
  box-shadow: 0 2px 5px 0 rgb(213 217 217 / 50%);
}
.review-button:hover {
  cursor: pointer;
}
.review-button-normal {

}
.review-button-primary {
  background: #FFD814;
  border-color: #FCD200;
}

.review-button-text {
    background-color: transparent;
    border: 0;
    display: block;
    font-size: 13px;
    line-height: 29px;
    margin: 0;
    outline: 0;
    padding: 0 10px 0 11px;
    text-align: center;
    white-space: nowrap;
}
