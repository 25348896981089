@import url(//cdn.rawgit.com/rtaibah/dubai-font-cdn/master/dubai-font.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*https://www.flaticon.com/categories/social-media*/
/*https://www.iconfinder.com/icons/5282542/linkedin_linkedin_logo_network_social_network_icon*/
/*https://fontawesome.com/icons?d=gallery&q=facebook*/
/*style = {{border:'1px solid black'}}*/
.footer {
  background: #F3F3F3;
  font-family: "Euclid-Regular",Arial,sans-serif;
  color: #212121;

}

.footer .footer-subscription {
  margin: auto;
  text-align: center;
}

.footer .footer-subscription h5 {
  display: block;
  margin: auto;
  font-size: 32px;
  margin-bottom: 20px;
}

.footer .footer-subscription p {
  display: block;
  margin: auto;
  font-size: 12px;
  margin-bottom: 10px;
}


.footer .footer-subscription .contact-us{
  display: block;
  margin: auto;
  font-size: 12px;
  margin-bottom: 20px;
}

.footer .social-media-header {
  text-align: center;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 800;
}

.footer .social-media-links .social-media-list{
  margin: 0;
  text-align: center;
}

.footer .social-media-links .social-media-list .social-media_item{
  display: inline-block;
  list-style-type: none;
  margin: auto;
  text-align:left;
  color: #767676;
}

.footer .social-media-links .social-media-list .social-media_item a{
  color: #7676767;
}

.footer .social-media-links .social-media-list .social-media_item .social-media_link{
  display: inline-block;
  background-repeat: no-repeat;
}

.footer .footer-notes {
  text-align: center;
  font-size: 12px;
  text-transform: uppercase;
  padding: 0px 20px;
}

.footer .footer-notes a {
  font-size: 14px;
  color: #1E1E1E;
}

.footer .footer-notes a:hover {
  font-size: 14px;
  color: #1E1E1E;
}


.socials {
  border-bottom: 1px solid rgba(30, 30, 30, 0.5);
}
.footer-notes {
  margin-top: 20px;
  font-family: 'Open Sans', sans-serif;
  padding: 20px;
  color: #414141;
  align-items: center;

}

.footer-notes .tta__logo {
  margin: 0 auto;
  width: 100%;
  max-width: 150px;
  object-fit: contain;
  align-items: center;
  margin-bottom: 20px;
}

.footer-notes .tta__logo:hover {
  cursor: pointer;
}

.footer-notes .footnote__copyright p{
  text-transform: none;
  font-size: 12px;
  margin-bottom: 10px;
  z-index: 2;
  align-items: center;
  text-align: center;
}



.footnote__tsandcs a {
  text-transform: none;
  font-size: 32px;
  margin-bottom: 10px;
  z-index: 2;
  align-items: center;
  text-align: center;
}

@media screen and (min-width: 40em)
.social-media-links {
    flex-grow: 1;
}

.social-media-list li a:hover {
    opacity:0.7;
    color: green;
    cursor: pointer;
    color: #767676;
}

.sign-up-form {
  font-family: "Euclid-Regular",Arial,sans-serif;
  font-size: 12px;
}

.sign-up-form  label {
  display: inline-block;
  margin: auto;
  text-transform: uppercase;
}

.sign-up-form .nameDetails {
  width: 90%;

}

.sign-up-form .adressDetails {
  width: 90%;
}

.sign-up-form .form-field {
  border: 0;
  border-bottom: 1px solid #d2d2d2;
  outline: 0;
  /*font-size: 14px;*/
  color: #212121;
  background: transparent;
  transition: border-color 0.2s;
}

.confirm-sign-up-modal {
  position: fixed;
  left: 0;
  top: 0;
  overflow: auto;
  z-index: 999;
  height: 100%;
  width: 100%;

  background: rgba(0, 0, 0, 0.65);
}



/*
.main-page-content .intro-copy {
  text-align: center;
  color: #8D8478;
  margin-bottom: 40px;
}
*/

.confirm-sign-up-modal .modal-dialog {


  width: auto;
  display: inline-block;
  /* below is the main text fonts and styles*/
  font-family: 'Open Sans', sans-serif;

  position: relative;
  /*top: 30%;*/
  top: 0%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /*max-width: 50%; /*  modify for the width you want*/
  height: auto;
  /*margin: 10% auto;*/
  padding: 1rem 1.5rem;
  border: none;
  outline: none;
  border-radius: 4px;
  /*box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16);*/
  -webkit-animation: openModal 1s ease;
          animation: openModal 1s ease;
}

.confirm-sign-up-modal .modal-dialog .modal-header {
   border-bottom: 0 none;

}

.confirm-sign-up-modal .modal-dialog .modal-header .modal-title {

  text-align: center;
  text-transform: uppercase;
  font-size: 22px;


  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0 none;


}

.confirm-sign-up-modal .modal-body {
  text-align: center;
  color: #8D8478;


  padding: 1.5rem 0;
  border-bottom: 0 none;
}

.confirm-sign-up-modal .modal-footer {
  border-top: 0 none;

}


.modal-footer .social-media-links .social-media-list{
  margin: 0;
  text-align: center;
}

.modal-footer .social-media-links .social-media-list .social-media_item{
  display: inline-block;
  list-style-type: none;
  margin: auto;
  text-align:left;
  color: #767676;
  font-size:14px;

  /*margin-left:20px;
  margin-right:20px;*/

}

.modal-footer .social-media-links .social-media-list .social-media_item a{
  color: #7676767;

}

.modal-footer .social-media-links .social-media-list .social-media_item .social-media_link{
  display: inline-block;
  background-repeat: no-repeat;
}

.modal-body .offering-copy {
  margin-top:10px;
  font-size: 14px;
  text-align: center;
  margin-left:20px;
  margin-right:20px;
  font-weight: 500;
}


.modal-col-img .img-centered {

  /*width: 100%;
  object-fit: contain;
  max-width: 400px;
  margin-left:20px;
  margin-right:20px;*/

  display: block;
  /* overflow: visible; */
  
  margin-left: auto;
  margin-right: auto;
  /* width: 100%; */
  align-items: center;
  width: 100%;
  object-fit: contain;

}



.request-info-form {
  font-family: "Euclid-Regular",Arial,sans-serif;
  font-size: 12px;
}

.request-info-form  label {
  display: inline-block;
  margin: auto;
  text-transform: uppercase;
}

.request-info-form .nameDetails {
  width: 90%;

}

.request-info-form .adressDetails {
  width: 90%;
}

.request-info-form .requestDetails {



}

.request-info-form .requestDetails .textarea__requestDetails
{
  width: 100%;
  height: 200px;
  resize: vertical;
  /* trying to make this risponsive
  object-fit: contain;*/
  box-sizing: border-box;


  /* rows = "10" cols="75"
}


.request-info-form .form-field {
  border: 0;
  border-bottom: 1px solid #d2d2d2;
  outline: 0;
  /*font-size: 14px;*/
  color: #212121;
  background: transparent;
  transition: border-color 0.2s;
}

/* sum custom buttons*/
.button__sqs_clone {
  padding: 5.5px 22px;
  border-radius: 100px;
  line-height: 22px;
  font-size: 12px;
  transition: all .2s ease-in-out;

  background-color: white;
  color: rgb(178, 173, 166);
  border-color: rgb(178, 173, 166);

}

  .button__sqs_clone_dark {
      background-color: #3E3E3E;
      color: #FFF;

      &:hover {
        background-color: #000;
      }
  }

  .submit-button-style{
    margin-top: 10px;
    color: #212121 !important;
    border-color:#212121 !important;
    background: transparent;
    transition: border-color 0.2s;
    align: center;
  }

  .submit-button-style:hover{
    color: white !important;
    border-color: white !important;
    background-color:#212121 !important;
  }

/*style = {{border:'1px solid red'}}*/

.navigation{

  /*'position':'relative','text-align':'center','top':'0', 'margin':'0', 'padding':'','width':'100%'*/
  display: flex;
  /*background-color: #f2f2f2;*/
  background-color: #ffffff;


  font-family: "Euclid-Regular",Arial,sans-serif;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  background: #fff;
  font-size: .75rem;
  text-align: center;
  /*position: relative;*/
  text-transform: uppercase;
  /*width: 17rem;*/
  width:100%;
  z-index: 999;
  /*box-shadow: 0 2px 2px -2px rgba(0,0,0,.2);*/
  border-bottom: 1px solid rgba(156,156,156,0.3);;
}

.shadow {


    /*box-shadow: 0 2px 2px -2px hsla(0,0%,60%,.4);*/

}
.navigation h1{
  margin: 10px 0px 10px;
  font-size: 16px;
  color: #191919;
}

.main-nav-banner-title-logo h1 {
  margin: 10px 0px 10px;
  font-size: 16px;
  color: #191919;

}

.main-nav-banner-title-logo:hover h1 {
  margin: 10px 0px 10px;
  font-size: 16px;
  color: #191919;
  cursor: pointer;
  text-decoration:underline;
}


.navigation ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  /*background-color: #f2f2f2*/
  background-color: #ffffff;
  text-align: center;
  /*box-shadow*/
}

.navigation ul li {
  /*float: right;*/
  margin: 0 ;
  padding: 0px;
  display: inline-block;
}

.navigation ul li a {
  font-size: 14px;
  color: #1E1E1E;
}

.navigation ul li a:hover {
  font-size: 14px;
  color: #1E1E1E;
  cursor: pointer;
}

.sticky{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    box-shadow: 0 1px 4px rgba(156,156,156,0.3);
}


/* New Navigation Styles*/
.main-nav-banner {
  display: flex;
  position:relative;
  align-items: center;
}

@media (min-width:600px)
.main-nav-banner-2 {
    padding-left:24px;
    padding-right: 24px;
}

.main-nav-banner-container{
  /* 68 76 80 85*/
  width : 100%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;

  align-items: center;

  align-content: center;

  justify-content: center;


}

.main-nav-banner-title {
  /*67 69 105*/

  margin: 0;
  box-sizing: border-box;

  flex-grow: 0;
  max-width: 33.33333333%;
  flex-basis: 33.33333333%;
}

.main-nav-banner-title-inner {
  /* 170 172*/

}

.main-nav-banner-title-logo{
  position: relative;
  display: block;
  margin: 0 auto;

  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  min-height: 1rem;
  /*min-height: 3rem;*/
}

.main-nav-banner-title-logo-strapline{
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 8px;
}

.main-nav-banner-account {
  /*67 69 105*/

  margin: 0;
  box-sizing: border-box;

  flex-grow: 0;
  max-width: 33.33333333%;
  flex-basis: 33.33333333%;
}

.main-nav-banner-account-inner {
  /* 170 173 */
  display: flex;
  text-align: right;
  padding-left: 16px;
  padding-right: 16px;
  justify-content: flex-end;

}

.main-nav-banner-account-controls {
  /* 209 180 177 182*/
  width: auto;
  border: 0;
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  outline: 0;
  position: relative;
  align-items: center;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  vertical-align: middle;
  justify-content: center;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
}

.main-nav-banner-account-controls-container {
  /*181 178*/
  width: 100%;
  display: inherit;
  align-items: inherit;
  justify-content: inherit;

  flex-direction: column;
}

.main-nav-banner-account-controls-icon{
  /*204 179 206*/
  display: inherit;

  margin-right: 0;

  margin-left: -2px;
}

.main-nav-banner-account-controls-icon{
  /*width: 1em;*/
  height: 1em;
  overflow: unset;
  flex-shrink: 0;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  font-size: 18px;

}

.main-nav-banner-account-login {
  /*221 224*/
  margin-top: 5px;
  /*margin: 0;*/

  font-size: 0.75rem;
  /*font-family: */
  font-weight: 400;
  line-height: 1.66;

  text-transform: uppercase;

}

.main-nav-banner-account-login-a-link{
  /*221 374 376 222 244*/

  /*221*/
  margin: 0;


  /*374*/
  color: rgb(33, 33, 33);
  cursor: pointer;

  /*376*/
  text-decoration: none;

  /*222*/
  font-size: 0.75rem;
  /*  font-family: */
  font-weight: 400;
  line-height: 1.43;

  /*244*/


}

.main-nav-banner-account-login-a-link:hover{
  /*221 374 376 222 244*/

  /*221*/
  margin: 0;

  /*374*/
  color: rgb(33, 33, 33);
  cursor: pointer;

  /*376*/
  text-decoration: underline;

  /*222*/
  font-size: 0.75rem;
  /*  font-family: */
  font-weight: 400;
  line-height: 1.43;

  /*244*/


}



.user-logo{
  color:  #c0c0c0;
  font-weight: 600;
}

.main-nav-banner-other {
  /*67 69 105*/

  margin: 0;
  box-sizing: border-box;

  flex-grow: 0;
  max-width: 33.33333333%;
  flex-basis: 33.33333333%;
}

.main-nav-banner-other-inner {
  /* 170 171 */
  /*display: flex;
  text-align: left;
  padding-left: 16px;
  padding-right: 16px;
  justify-content: flex-end;*/

  display: flex;
  text-align: left;
  padding-left: 16px;
  padding-right: 16px;

}


.main-nav-banner-links{
  width: 100%;
  display: block;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;

}

@media (min-width: 600px)
.main-nav-banner-links {
    padding-left: 24px;
    padding-right: 24px;
}

@media (min-width: 1280px)
.main-nav-banner-links-2 {
    max-width: 1440px;
}

.main-nav-banner-divider {
  border: none;
  height: 1px;
  margin: 0;
  flex-shrink: 0;
  background-color: #c0c0c0;
}

.main-nav-banner-links-container {
  /* 33 321 36*/
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  top: 0;
  position: -webkit-sticky;
  position: sticky;
  background-color: transparent;

  box-shadow: none;

}

.main-nav-banner-links-contents {
  /* 336 */
  display: flex;
  overflow: hidden;
  min-height: 48px;
}

.main-nav-banner-links-contents-inner {
  /*341 342*/
  flex: 1 1 auto;
  display: inline-block;
  position: relative;
  white-space: nowrap;

  width: 100%;
}

.main-nav-banner-links-list {
  /*338 340*/
  display: flex;

  align-items: center;
  justify-content: center;

}

.main-nav-banner-nav-link {
  /* 209 384 320 386*/
  width: auto;
  border: 0;
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  outline: 0;
  align-items: center;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border-radius: 0;
  vertical-align: middle;
  justify-content: center;
  text-decoration: none;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;

  /*384*/
  padding: 6px 12px;
  overflow: hidden;
  position: relative;
  font-size: 0.875rem;
  max-width: 264px;
  box-sizing: border-box;
  min-height: 48px;
  text-align: center;
  flex-shrink: 0;
  /*font-family: */
  font-weight: 500;
  line-height: 1.75;
  white-space: normal;
  text-transform: uppercase;

  /*320*/
  opacity:1 ;
  min-width: auto;

  /*386*/
  color: inherit;

}


@media (min-width: 600px)
.main-nav-banner-nav-link{}


.main-nav-banner-nav-link-item{
  /*393*/
  width: 100%;
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}


.main-nav-banner-nav-link-item-a-link{
  /*221 374 376 222 244*/

  /*221*/
  margin: 0;

  /*374*/
  color: rgb(33, 33, 33);
  cursor: pointer;

  /*376*/
  text-decoration: none;

  /*222*/
  font-size: 0.875rem;

  /*  font-family: */
  font-weight: 400;
  line-height: 1.43;

  /*244*/


}

@media screen and  (max-width: 700px)
{
  .main-nav-banner-nav-link-item-a-link
  {
    font-size: 0.75rem;
  }
}


.main-nav-banner-nav-link-item-a-link:hover {
  /*221 374 376 222 244*/

  /*221*/
  margin: 0;

  /*374*/
  color: rgb(33, 33, 33);
  cursor: pointer;


   text-decoration: underline;

  /*222*/
  font-size: 0.875rem;
  /*  font-family: */
  font-weight: 400;
  line-height: 1.43;

  /*244*/


}

.cart__summary__display {
    top: 0;
    right: 10;
    transform: scale(1) translate(50%, -50%);
    transform-origin: 100% 0%;



    color: rgba(0, 0, 0, 0.87);
    /*background-color: #69A740;*/
    background-color: #EFEFEF;
    border: 1px solid #888888;


    height: 20px;
    display: flex;
    padding: 0 6px;
    z-index: 1;
    position: absolute;
    flex-wrap: wrap;
    font-size: 0.75rem;
    min-width: 20px;
    box-sizing: border-box;
    transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    align-items: center;
    /*font-family: Euclid-Regular, Arial, sans-serif, Helvetica, Arial;*/
    font-weight: 500;
    line-height: 1;
    align-content: center;
    border-radius: 10px;
    flex-direction: row;
    justify-content: center;
}


.jumbotron{

  background-size: cover;
  height:1067px;


  background-image: url(/img/jumbotron/wine-toast-1600x1067.jpg);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat !important;
  /*
  height: 100%;
  left: 0;
  top: 0;*/
  width:80%;
  max-width: 1067px;



}
/*
.jumbotron-fluid .container-fluid {
  position: relative;
  z-index: 2;


}*/

.jumborton_controls a {
  text-decoration:none;
color: inherit;
}

.jumbotron__scrolldown{
  /*font-size: 2em;
  color: #006670;
  cursor:pointer;*/


  z-index: 100;
  position: absolute;
  top: 55%;
  left:50%;
  /*left: -2%;*/

  border: .0625rem solid #d8d9db;
  border-radius: 50%;
  /*border-color: rgba(0, 0, 0, 0.5);*/
  height: 42px;
  width: 42px;
  background-color: rgba(255, 255, 255, 0.5);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0.125rem 0.5rem hsla(0, 0%, 60%, .4);
  /*opacity: 50%;*/
}

/*
.intro .goBack:hover {
  cursor: pointer;
}*/

.jumbotron__scrolldown:hover
{
  cursor: pointer;
  /*opacity: 70%;*/
  background-color: rgba(255, 255, 255, 0.7);
  border-color: rgba(0, 0, 0, 0.7);
  transition: .25s ease-in-out;
}



/* The Homepage Hero Styling*/
.homepage__hero_original {

  position: relative;
  /*background: url(../img/jumbotron/tuscany_1600x1062.jpg);*/
  /*background: url(`${JumboImg}`);*/
  -mox-background-size: cover;
  background-size: cover;
  text-align: center;

  padding-top: 110px;
  /*min-height: 500px;*/
  min-height:800px;
  letter-spacing: 2px;
  /*width: 1488px; height:837px*/

  width: 100%;
  object-fit: contain;
  margin-bottom: 20px;
}


/* The Homepage Hero Styling*/
.homepage__hero {
  position: relative;
  /*background: url(/img/jumbotron/tuscany_1600x1062.jpg) no-repeat center center fixed;*/
  background-size: cover;
  text-align: center;
  color: #fff;
  padding-top: 110px;
  min-height: 500px;
  letter-spacing: 2px;
  width:100%;

}

.user_card {
  width: 350px;
  margin-top: auto;
  margin-bottom: auto;
  background: #74cfbf;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
}

.form_container {
  margin-top: 20px;
}

.login_btn {
  width: 100%;
  /*background: #33ccff !important;*/
  /*color: white !important;*/
  color: #212121 !important;
  border-color:#212121 !important;
  background: transparent;
  transition: border-color 0.2s;
  align: center;

}

.login_btn:hover {
  cursor: pointer;
  color: white !important;
  border-color: white;
  background-color:#212121 !important;
}
.login_container {
  padding: 0 2rem;
}

.input-group-text {
  /*background: #f7ba5b !important;*/
  background: rgba(0, 0, 0, 0.12) !important;
  color: white !important;
  border: 0 !important;
  border-radius: 0.25rem 0 0 0.25rem !important;

  /*some extra styling*/
  outline: 0;
  border-bottom: 1px solid #d2d2d2 !important;
  transition: border-color 0.2s !important;
}


.authentication__page__shell {
  --page-shell-max-content-width: 1440px !important;
}
.authentication__page__content {
      display: block;
}

@media (min-height: 475px) and (min-width: 744px)
..authentication__panel__container  {
    padding: 40px !important;
}

.authentication__panel__container {
  -webkit-box-pack: center !important;
  display: flex !important;
  justify-content: center !important;
  margin-bottom: 40px;
}
.authentication__panel {
  border: 1px solid #B0B0B0B0 !important;
  border-radius: 12px !important;
  position: relative !important;
  max-width: 568px !important;
  width: 100% !important;
  margin-top: 32px !important;
  overflow: visible !important;

  /* some fancy stylings*/
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
  -webkit-box-shadow: 0 .5px 1px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 0 .5px 1px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
  border-radius: 2px;
}

@media (min-width: 744px) .panel__header {
  min-height: 64px !important;
}

.panel__header {
  -webkit-box-pack: justify !important;
  -webkit-box-align: center !important;
  margin-top: 20px;

  flex: 0 0 auto !important;
  align-items: center !important;
  justify-content: space-between !important;
  border-bottom: 1px solid #EBEBEB !important;
  padding-top: 0px !important;
  padding-left: 24px !important;
  padding-right: 24px !important;
  color: #222222 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  font-weight: 800 !important;

}


.panel__header  .pannel__header__leftbox {
  flex-grow: 0 !important;
  flex-shrink: 0 !important;
  flex-basis: 16px !important;
}
.panel__header  .pannel__header__title{
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  flex-grow: 0 !important;
  flex-basis: auto !important;
  text-align: center !important;
  margin-left: 16px;
  margin-right: 16px;
}
.panel__header  .pannel__header__rightbox {
  flex-grow: 0 !important;
  flex-shrink: 0 !important;
  box-sizing: border-box;
}

.login__panel__containter {
  padding: 32px !important;
  margin-top: 20px;
}

.registration__panel__containter {
  padding: 32px !important;
  margin-top: 20px;
}

.login__panel {

}
.authentication__page__bottom {
  min-height: 10px;
}

.login__register__link:hover {
  cursor: pointer !important;
  color: #000000 !important;
  text-decoration: underline !important;
}

.login__register__link:visited {
  color: #717171 !important;
  text-decoration: underline !important;
}

.login__register__link {
  font-size: inherit !important;
  font-family: inherit !important;
  font-style: inherit !important;
  font-feature-settings: inherit !important;
  font-variant: inherit !important;
  line-height: inherit !important;
  color: #222222 !important;
  text-decoration: underline !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
  outline: none !important;
}

body {
  width:100% !important;
}
.all-page {

  width: 100%;
  max-width: 2000px;



}
.app-page-content{
    width: 100%;

    /*background-color: #f2f2f2;*/
    background-color: #FFFFFF;
    /*object-fit: contain;*/

}




h1 {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
}
li a {
  display: block;
  color: black;
  text-align: center;
  padding: 14px;
  text-decoration: none;
}

.main-page-intro{
  font-family: 'Open Sans', sans-serif;
  font-size: 17px;

}

.main-page-content {
  font-family: 'Open Sans', sans-serif;

}

.main-page-content .strapline {
  text-align: center;
  text-transform: uppercase;
  /*margin-bottom: 40px;*/
  font-size: 22px;
  margin: 1.5em auto .3em;
  /*margin-top: 20px;*/

}



.main-page-content .intro-copy {
  text-align: center;
  color: #8D8478;
  margin: 1.5em auto .3em;
  margin-bottom: 40px;
}

.main-page-content .body-copy {
  text-align: center;
  color: #8D8478;
  margin-bottom: 40px;
  margin-top: 40px;
}

.infoCard {
  border: none;
  box-shadow: 0 0 2px;
}

.offering-content {
  font-family: 'Open Sans', sans-serif;
  padding: 20px;
  color: #414141;
}
.offering-content .offering-title{
  text-transform: none;
  font-size: 32px;
  margin-bottom: 10px;
  z-index: 2;
  text-align: center;
}

.offering-content .offering-copy {
  font-size: 17px;
  text-align: center;
}


.img-centered {
  margin: 0 auto;

  /*trying to make the image resposive*/
  width: 100%;
  object-fit: contain;
  max-width: 400px;
}




.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /*background-color: #282c34;*/
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*custom buttons*/
.learn_more-button-style{
  margin-top: 10px;
  color: #212121 !important;
  border-color:#212121 !important;
  background: transparent;
  transition: border-color 0.2s;
  align: center;
}

.learn_more-button-style:hover{
  color: white !important;
  border-color: white;
  background-color:#212121 !important;
}

/*Journal Page Styles*/


.main-page-content .journal__page__strapline {
  text-align: center;
  text-transform: uppercase;
  /*margin-bottom: 40px;*/
  font-size: 22px;
  margin: 1.5em auto .3em;
  /*margin-top: 20px;*/
  margin-top: 0px;
  font-weight: 600;
}

.main-page-content .journal__page__intro-copy {
  text-align: center;
  color: #8D8478;
  /*margin: 1.5em auto .3em;
  margin-bottom: 40px;*/


  /* extra syling to constrain the text*/
  max-width: 580px;
  font-weight:300;
  margin-left:auto;
  margin-right: auto;
  font-size: 18px;


}

.main-page-content .journal__page__intro-copy-paragraph {
  margin-top: 20px;
  margin-bottom: 20px;

  text-align: center;
  color: #8D8478;
  /*margin: 1.5em auto .3em;
  margin-bottom: 40px;*/

  max-width: 1000px;
  font-weight:300;
  margin-left:auto;
  margin-right: auto;
  font-size: 16px;

}


.journalMenu-slider__wrapper{

  position: relative;

}
.journalMenu-slider__wrapper .journalMenu-slider{
  /*border: 1px solid red;*/
  overflow: hidden;

  /*margin: 10px auto;*/

}

.slider__controls{

  z-index: 100;
  position: absolute;
  top: 50%;
  transform: translateY(50%);

}

.journalMenu-slider__wrapper .slider__prev{
  /*font-size: 2em;
  color: #006670;
  cursor:pointer;*/

  position: absolute;
  top: 50%;
  z-index: 1;
  left: -2%;


  border: .0625rem solid #d8d9db;
  border-radius: 50%;
  height: 42px;
  width: 42px;
  background-color: #fff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0.125rem 0.5rem hsla(0, 0%, 60%, .4);
}

.journalMenu-slider__wrapper .slider__next{
  /*font-size: 2em;
  color: #006670;
  cursor:pointer;*/

  position: absolute;
  top: 50%;
  z-index: 1;
  right: -2%;

  border: .0625rem solid #d8d9db;
  border-radius: 50%;
  height: 42px;
  width: 42px;
  background-color: #fff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0.125rem 0.5rem hsla(0, 0%, 60%, .4);
}


.journal__post{
  text-align: center;
  /*border: 1px solid black;*/
  /*width: 600px;*/
  /*style={{border:"1px solid red"}}*/
  height: auto;
  margin: 0px 2px;
  overflow: hidden;
}

.journal__post .journal-media{

}

.journal-image {
    /*trying to make the image resposive*/
    /* removed these class elements
    align-self-end mt-3 mb-3
    */
  width: 100%;
  object-fit: contain;
  max-width: 300px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin: auto;
}

.journal-image:hover {
  cursor: pointer;
}


.journal__post .journal__category {

  color: #ccaa6f;
  text-decoration: none;
  text-align: left;
  font-size: 12px; /*.75rem*/
  text-transform: uppercase;
  font-weight: 500;
  margin: auto;
  max-width: 300px; /*Same width as journal-image*/
}
.journal__post .journal-title {
  text-transform: none;
  font-size: 22px; /*1.375rem;*/
  line-height: 26px; /* 1.625rem;*/
  font-weight: 400;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  z-index: 2;
  text-align: center;
  align-items: center;
  max-width: 300px; /*Same width as journal-image*/
  min-height:50px;
}

.journal__post .journal-title {
    cursor: pointer;
    color: #414141;
}

.journal__post .journal-copy {
  font-size: 17px;
  text-align: center;
}

.journal__copy__strapline{
  color: #888888;
  text-decoration: none;
  font-size: 12px; /*.75rem*/
  text-transform: none;
  font-weight: 500;
  align-items:center;
  min-height:30px;
  max-height: 30px;
}

.journal__card__divider {
  content: '';
  display: block;
  width: 25px;
  height: 1px;
  background-color: #888888;
  margin:auto;
  margin-top: 5px;
  align-items:center;
  margin-bottom: 5px;
}

/* Journal Post Stylings*/
.journal__copy__date {
    color: #888888;
    font-weight: 400;
    font-size: 12px;
    text-transform: uppercase;
    align-items:center;
}


.journal__post-content-full
{

}
.journal__post-content
{
  background-color: #FFFFFF;
}

.main-journal-content
{
  font-family: 'Open Sans', sans-serif;
}

.main-journal-content .breadcrumbs {
  text-align: center;
  text-transform: uppercase;
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 16px;
  color: #8D8478;
}

.main-journal-content .strapline {
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 40px;
  font-size: 22px;
  max-width: 300px; /*Same width as journal-image*/
}


.intro {
  position: relative;
}

.main-journal-content .intro .intro-copy {
  text-align: center;
  margin-bottom: 20px;
}

.intro .journal__goBack{
  /*font-size: 2em;
  color: #006670;
  cursor:pointer;*/


  z-index: 100;
  position: absolute;
  top: 50%;
  /*left: -2%;*/

  border: .0625rem solid #d8d9db;
  border-radius: 50%;
  height: 42px;
  width: 42px;
  background-color: #fff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0.125rem 0.5rem hsla(0, 0%, 60%, .4);
}


.main-journal-content .journal__article__intro-copy {
  text-align: center;
  text-transform: uppercase;
  /*margin-bottom: 40px;*/
  font-size: 22px;
  margin: 1.5em auto .3em;
  /*margin-top: 20px;*/
  margin-top: 0px;
  font-weight: 600;
}

.main-journal-content .journal__article__strapline {
  text-align: center;
  color: #8D8478;
  /*margin: 1.5em auto .3em;
  margin-bottom: 40px;*/


  /* extra syling to constrain the text*/
  max-width: 580px;
  font-weight:300;
  margin-left:auto;
  margin-right: auto;
  font-size: 18px;
  margin-bottom: 20px;


}
/*
.intro .goBack:hover {
  cursor: pointer;
}*/

.intro .journal__goBack:hover
{
  cursor: pointer;
}

.journal-article-hero{
  position : relative;
  margin-bottom: 70px;
}



.journal-article-hero .hero-overlay{
  text-align: center;
  position: absolute;
  text-transform: lowercase;
  z-index: 0;
  width: 70%;
  /*top: 95%;*/
  bottom: -5%;
  transform: translateX(25%);
  background: white;
  padding: 20px 0px 20px;

}

.hero-overlay .shadow{
  position: relative;
  box-shadow: 0 1px 4px rgba(156, 156, 156, 0.3)
}

.hero-overlay p {
  text-align: center;
  font-size: 22px;
  margin: 10px 0;
}

.journal__content__paragraph {
    font-family: 'Open Sans', sans-serif;


}

.journal__content__paragraph li {
  margin: 40px;
}

.in-the-mix__soundcloud{

}

.player-wrapper
{
  overflow: hidden;
}

.reactplayer__soundcloud
{
  margin: 10px 0 10px;
  width: auto;
  height: auto;
}

/* The below are the original styling. above are the updated*/

.journal-content {
  font-family: 'Open Sans', sans-serif;
  padding: 20px;
  color: #414141;
}

.journal-post-item:hover {
  cursor: pointer;
}
.journal-content .journal-title {
  text-transform: none;
  font-size: 32px;
  margin-bottom: 10px;
  z-index: 2;
  text-align: center;
}

.journal-content .journal-copy {
  font-size: 17px;
  text-align: center;
}

/*
  Journal Image Sizes
  We should make the below reactive to screen size
*/

.journal-media img {
  height: 400px;
  width: 400px;
}

.journal-article-hero .journal-article-hero__image{
  display: block;
  /* overflow: visible */
  z-index:-1;
  margin-left: auto;
  margin-right: auto;

  /*width: 100%;*/

  /* trying to make the hero responsive*/
  align-items: center;
  width: 100%;
  object-fit: contain;

}

.journal-article-hero .journal-article-hero__image{
  height:600px;
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body{
  font-family: 'Open Sans', sans-serif;
  width: 80%;
  margin: 0 auto;
  background: rgb(251, 254, 255);
}

.newsGrid {
  margin: 50px auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;

}

.newsGridHeading{
  margin-top: 30px;
}

.newsItem{
  max-width: 500px;
  padding: 30px;
  border: 1px solid lightgray;
  border-radius: 5px;
}

.newsItem__title{
  padding-bottom: 10px;
  border-bottom: 1px solid lightgray;
}
.newsItem__desc{
  padding-top: 10px;
  line-height: 25px;
  font-size-adjust: 14px;
  padding-bottom: 10px;
}
.newsItem__author{
  margin-right: 10px;
  color: purple;

}
.newsItem__publishedAt{
  margin-right: 10px;
}
.newsItem__source{

}

.newsItem__source, .newsItem__publishedAt {
  display: inline-block;
  margin-top: 10px;
  color: lightgray;
}

.Events__Experiences__Store_Container{
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 32px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 0.0625rem 0 rgb(0 0 0 / 10%);
}

@media (min-width: 768px)
.Events__Experiences__Store_Header_Container {
    margin: 0 32px;
}

.Events__Experiences__Store_Header{
    padding-bottom: 16px;
}

.Events__Experiences__Store_Header_Copy {
  font-size: 18px;
  text-align: center;
}

@media (min-width: 768px)
.Events__Experiences__Store_Header_Copy {
    margin: 16px 0;
}

.Events__Experiences__Store_Header_Body_Spacer {
  margin: -.0625rem 0 0;
  height: .0625rem;
  border: none;
  width:100%
}

.Events__Experiences__Store_Front_Container{
  display: flex;
  margin: 0 16px;
  width:100%;
}

@media (min-width: 768px)
.Events__Experiences__Store_Front_Container {
    margin-left: 32px;
    margin-right: 0;
}

.Events__Experiences__Store_Front_List_Container{
  width: 66.6666%;
}
@media (min-width: 1056px)
.Events__Experiences__Store_Front_List_Container {

}

.Events__Experiences__Store_Front_Map_Container {
  position: -webkit-sticky;
  position: sticky;
  top: 16px;
  margin-bottom: 32px;

  display: inline-block;
  width: 33.33333%;
  height: 25rem;
}

@media (min-width: 1056px)
.Events__Experiences__Store_Front_Map_Container {
    display: inline-block;
    width: 33.33333%;
    height: 25rem;
}

.Events__Experiences__Store_Front_Map_Area {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 95%;
  height: 100%;
  margin-left: auto;


}

.Events__Experiences__Store_Front_Img_Centered {
  margin: 0 auto;

  /*trying to make the image resposive*/
  width: 100%;
  object-fit: contain;
  max-width: 400px;
  max-height: 150px;
  height: auto;
  border-radius: 4px;
}

.Events__Experiences__Store_Front_Card_Row_Format {
  margin-top:auto;
  margin-bottom: 16px;
  padding: 0 0 16px;
  border-bottom: .0625rem solid #d8d9db;
}

.Events__Experiences__Store_Front_Event-title {
  text-transform: none;
  font-size: 18px; /*1.375rem;*/
  /*line-height: 26px; /* 1.625rem;*/
  font-weight: 400;
  /*margin: auto;*/
  /*margin-top: 10px;*/
  /*margin-bottom: 10px;*/
  /*z-index: 2;*/
  text-align: left;
  align-items: left;
  /*max-width: 300px; /*Same width as journal-image*/
  /*min-height:50px;*/
}

/*.journal-title {
    cursor: pointer;
    color: #414141;
}*/


.Events__Experiences__Store_Front__copy__strapline{
  color: #888888;
  text-decoration: none;
  font-size: 12px; /*.75rem*/
  text-transform: uppercase;
  font-weight: 500;
  align-items:left;
  /*min-height:30px;
  max-height: 30px;*/
}

.Events__Experiences__Store_Front__copy__description{
  color: #888888;
  text-decoration: none;
  font-size: 12px; /*.75rem*/
  text-transform: none;
  font-weight: 500;
  align-items:left;
  /*min-height:30px;
  max-height: 30px;*/
}

.Events__Experiences__Store_Front__copy__date {
    color: #888888;
    font-weight: 400;
    font-size: 12px;
    text-transform: uppercase;
    align-items:left;
    text-align: left;
}

.Events__Experiences__Store_Front__category {

  color: #ccaa6f;
  text-decoration: none;
  text-align: left;
  font-size: 12px; /*.75rem*/
  text-transform: uppercase;
  font-weight: 500;

  /*max-width: 300px; /*Same width as journal-image*/
}
.event-experience-info-wrapper{
  padding: 50px 0px;
}

.event-experience-name-wrapper{
    display: flex;
    align-items: center;
    margin-bottom: 14px;
}

.event-experience-name{
  text-align: center;
  text-transform: uppercase;
  font-size: 22px;
  margin: 0 auto .3em;
  font-weight: 600;
}

.event-experience_Img_wrapper{

}

.event-experience_Img_Centered {
  margin: 0 auto;

  /*trying to make the image resposive*/
  width: 100%;
  object-fit: contain;
  height: auto;
  border-radius: 4px;
}

.venue-location-summary{
  width: 100%;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  background-color: #fff;
  margin-top: 20px;
}

.venue-location-summary-content{
  display: flex;
  flex-direction: column;
  line-height: 24px;
}
.venue-location-summary_Map_Area{
  /*height: 100%;
  width: 100%;
  object-fit: contain;*/

  position: relative;
  /*top: 0;
  right: 0;
  bottom: 0;
  left: 0;*/
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 150px;
  margin-left: auto;


}

.venue-location-summary-Map_Area_Wrapper{
  /*height: 100px;*/
}

.venue-location-summary-content-info{
  flex: 1 1;
  margin-right: 15px;
  padding: 20px;
}
.event-experience-name-breadcrumb{
  text-transform: uppercase;
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 16px;
  color: #8d8478;
}

.venue-location-summary-content-info-breadcrumb{

  color: #8d8478;
  max-width: 580px;
  font-weight: 300;
  margin-left: auto;
  margin-right: auto;
  font-size: 18px;


}

.venue-location-summary-content-row{
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 50px;
  border-top: 1px solid #eaeaea;
  padding: 0 20px;
  font-size: 16px;
  text-overflow: ellipsis;
  color: #336dde;;
}

.spacer {
  margin-right: "20px"
}

.e9508c55 {
    border: 1px solid #d8d9db;
    padding: .25rem .25rem .5rem;
    border-radius: 4px;
    margin-bottom: 1rem;
}

.event-experience-meta {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 2;
  color: #8d8478;
  font-weight: 300;
  font-size: 18px;
}


.event-experience__intro-copy-paragraph {
    /*margin-top: 20px;
    margin-bottom: 20px;*/
    margin-top: 0px;
    margin-bottom: 0px;
    color: #8D8478;
    max-width: 1000px;
    font-weight: 300;
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
}

.separator {
    background: #919191;
    border-radius: 50%;
    margin: 0 10px;
    width: 5px;
    height: 5px;
}

.event-experience-date-and-time-wrapper {
  align-items: center;
}

.event-experience-date-and-time {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 2;
  /*color: #8d8478;*/
  color: #000;
  font-weight: 400;
  font-size: 18px;
  text-transform: uppercase;
  text-align: center;
}

.event-experience-inpage-title{
  text-align: center;
  text-transform: uppercase;
  font-size: 18px;
  margin: 0 auto .3em;
  font-weight: 600;
}

.event-experience-inpage-details-title{
  color: #737373;
  font-weight: bold;
  font-size: 16px;
  text-transform: capitalize;
}

.event-experience-inpage-sub-details-title {
  font-weight:500;
  color: #8d8478
}


.mh-select {
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    color: #212121;
    background-color: #fff;
    padding: 0 1.25rem;
}
.form-field-wrapper{

  -webkit-box-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: middle;

}
.event-experience-select-form-field{

  border: 0;
  /*position: relative;
  top: 50%;*/
  border-bottom: 1px solid #d2d2d2;
  outline: 0;
  /*font-size: 14px;*/
  color: #212121;
  background: transparent;
  transition: border-color 0.2s;
  width: 80%;
}

.event-experience-add-to-cart {
  /*margin-top: 1rem;*/
  background-color: #212121;
  color: white;

  display: block;
  width: 100%;
  margin-left: 0;
  margin-right: 0;

  /*border styling*/
  border: 1px solid #212121;
  border-radius: .25rem;

  /*button text format*/
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  padding: 1rem 1.5rem;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  text-align: center;
  line-height: 1rem;
}

.event-experience-add-to-cart:hover {
    background-color: #212121;
    color: #767676;
}

.fa-edit {
  color: #8d8478;

}
.fa-edit:hover {
  cursor: pointer;
  color: #AAAAAA;
  /*color: #EFEFEF;*/
}

.event_experience-stack {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}


.event_experience-stack-element-inner {
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    width: 100%;
}

.event_experience-cards{
  width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.event_experience-entry{
  /*box-shadow:hsl(0, 0%, 80%) 0 0 16px;*/
  box-shadow: 0 0.125rem 0.5rem hsl(0deg 0% 60% / 40%)
}

.event_experience-entry-inner {
    margin: 0 0 0 0;
    padding: 0 5px 0 5px;
    width: 100%;
}

.event_experience-entry-title {
  text-transform: none;
  font-size: 18px; /*1.375rem;*/
  font-weight: 400;
  text-align: left;
  align-items: left;
}


.event_experience-entry__description {
    color: #888888;
    text-decoration: none;
    font-size: 12px;
    text-transform: none;
    font-weight: 500;
    align-items: left;
}

.event-experience-summary {
  background: #f2f4f6;
  /*-webkit-box-shadow: inset 0 0 0 1000px #f2f4f6;*/
  box-shadow: inset 0 0 0 1000px #f2f4f6;
  border-radius: 4px;
  margin-Bottom: 5px;
}

.event-experience-summary-header {
  background: #fff;
}

.confirm-details {
  text-transform: uppercase;
  font-size: 9px;
}

.confirm-details-highlight {
  color: #0896ff;
  font-weight: bold;
  font-size: 13px;
}

.event-experience-summary-footer {
  background: #fff;
}

.my-profile-container {
  min-width:550px;
}
.my-profile{
  border: 1px solid #bdbdbd;
  width: 100%;
  min-width:500px;
}

.my-profile-dashboard {
  border-bottom: 1px solid #e6e6e6;
}

.hidden{
        display:none !important;
    }

.my-dashboard-container {
  max-width: 600px;
  margin: 0 auto;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  background-color: #fff;

  margin-top: 10px;
  margin-bottom: 100px;

  justify-content: space-around;
  align-items: flex-start;

}

.my-dashboard-form-wrapper {
  position: -webkit-sticky;
  position: sticky;
  top: 0rem;
  border-bottom: 1px solid #e9e9e9;
  background-color: #fff;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.25);
  padding: 40px;
}

.my-dashboard-submit {
  background-color: #36d9b6 !important;
  border-radius: 0;
  border: 0;
  color: #fff;
}

.flex-wrapper {
  display: flex;
}

.my-dashboard-element-wrapper {
  cursor: pointer;
  border-bottom: 1px solid #e9e9e9;
  color: #686868;
  margin: 5px;
  padding: 20px;
}

.my-dashboard-element-wrapper:hover {
  background-color: #F0F0F0;

}

.my-dashboard-title-wrapper {
  cursor: pointer;
  border-bottom: 1px solid #e9e9e9;
  color: #686868;
  margin: 5px;
  padding: 20px;
}

.my-dashboard-title-wrapper-footer {
  cursor: pointer;
  border-bottom: 1px solid #e9e9e9;
  border-top: 1px solid #e9e9e9;
  color: #686868;
  margin: 5px;
  padding: 20px;
}



/* Original Styles*/
.my-dashboard-flex-row {
  display: flex;
  flex-direction: row;
}

.my-dashboard-flex-grow {
    flex-grow: 1;
    width: 0;
}

.my-dashboard-page {
  outline: none;

}

.my-dashboard-page-title {
  margin-top: 4px;
  margin-bottom: 16px;

  /*bui-title*/
  display: flex;
  flex-direction: column;
}

.my-dashboard__title_text {
  margin: 0;

  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  text-align: left;
}
.my-dashboard__subtitle {
  margin-top: 4px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #6b6b6b;
  margin: 0;
  text-align: left;
}

.bui-button {
  border: 1px solid #0071c2;
  border-radius: 2px;

  display: inline-flex;
  align-items: center;
  display: block;
  justify-content: center;
  text-align: left;
  padding: 7px 15px;
  cursor: pointer;
  font-family: inherit;
  text-decoration: none;

  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;


  box-sizing: border-box;
  vertical-align: middle;
  position: relative;


  min-height: 36px;
  min-width: 36px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}


.bui-button-tertiary {
  padding-left: 7px;
    padding-right: 7px;
    background-color: transparent;
    color: #0071c2;
    border-color: transparent;

    font-weight: 500;

    /*display: block;
    align-items: center;*/

}

.bui-button-tertiary:hover {
  padding-left: 7px;
    padding-right: 7px;
    background-color: #fff;
    color: #0071c2;
    border-color: transparent;
    border-color: #0071c2;
    font-weight: 500;
}

.bui-button-tertiary-outlined {
  padding-left: 7px;
    padding-right: 7px;
    background-color: transparent;
    color: #0071c2;
    border-color: transparent;
    border-color: #0071c2;
    font-weight: 600;
}

.bui-button-tertiary-outlined:hover {
  padding-left: 7px;
    padding-right: 7px;
    background-color: #fff;
    color: #0071c2;

    border-color: #0071c2;
    font-weight: 600;
}


.bui-button-tertiary-activate-outlined {
  padding-left: 7px;
    padding-right: 7px;
    background-color: #97e59c;
    color: #000000;
    border-color: transparent;
    border-color: #000000;
    font-weight: 600;
}

.bui-button-tertiary-activate-outlined:hover {
  padding-left: 7px;
    padding-right: 7px;
    background-color: #97e59c;
    color: #fff;

    border-color: #000;
    font-weight: 600;
}

.bui-button-tertiary-danger {
  padding-left: 7px;
    padding-right: 7px;
    background-color: transparent;
    color: #0071c2;
    border-color: transparent;

    font-weight: 500;
}

.bui-button-tertiary-danger:hover {
  padding-left: 7px;
    padding-right: 7px;
    background-color: #fff;
    color: #0071c2;
    border-color: transparent;
    border-color: #0071c2;
    font-weight: 500;
}


.bui-button__text {
    display: inline-block;
    vertical-align: middle;

}

.my-dashboard-profile_pic-wrapper {
    position: relative;
}

.bui-profile_pic {
    position: relative;

    border-radius: 50%;


    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #333;
    color: #fff;
    text-transform: uppercase;
}

.bui-profile_pic--larger {
    height: 64px;
    width: 64px;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
}

.bui-profile_pic--outline-complement {
    border: 2px solid #febb02;
}

.bui-icon {
    display: inline-block;

}

.my-dashboard-profile_pic-edit-icon {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;

    overflow: hidden;
    text-align: center;
    padding-bottom: 4px;
    z-index: 1;
    opacity: .9;
}

.bui-icon--smaller {
    height: 14px;
}

.bui-icon--color-white {
    color: #fff;
    fill: #fff;
}

.bui-icon svg {
    display: inline-block;
    vertical-align: top;
    height: 100%;
    width: auto;
}


.my-dashboard-edit-profile {
  border-bottom: 1px solid #e6e6e6;
}

.bui-f-font-featured {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.my-dashboard-row {


  display: flex;
  flex-direction: row;
}

.my-dashboard-row-edit {
  color: #333;
  border-top: 1px solid #e6e6e6;
  position: relative;
}



@media (min-width: 992px)
.my-dashboard-row-edit {
    display: flex;
    padding: 0;
}


@media (min-width: 992px)
.my-dashboard-col-shrink {
    width: 130px;
    min-width: 130px;
    overflow-x: hidden;
    position: relative;
}
.my-dashboard-col-shrink{
  white-space: normal;
}

.my-dashboard-col-grow {

  white-space: normal;
  flex-grow: 1;
  align-items: center;

}

@media (min-width: 992px)
.my-dashboard-cell-spacing {
    padding: 16px 16px 12px;
}
.my-dashboard-cell-spacing {

}
.my-dashboard-flex-row-reverse {
  flex-direction: row-reverse;



}

@media (min-width: 992px)
.my-dashboard-col-edit {
    width: 130px;
    min-width: 130px;
}

.my-dashboard-col-edit {
  /*width: 110px;
  min-width: 110px;*/
  text-align: right;
}

.my-dashboard-display-mode {

}

.bui-f-font-body {
    font-weight: 700;
}


.profile-pic {

  /*width: 100%;
  object-fit: contain;
  max-width: 400px;
  margin-left:20px;
  margin-right:20px;*/

  display: block;
  /* overflow: visible; */

  margin-left: auto;
  margin-right: auto;
  /* width: 100%; */
  align-items: center;
  width: 90%;
  object-fit: contain;

}

/* dashboard form styles*/
.dashboard__select__button__style {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #333;
  background: #fff;
  border-radius: 2px;
  border: 1px solid #bdbdbd;
  display: block;
  /*font-family*/
  padding: 7px;
  width: 100%;
  cursor: pointer;
  text-align: left;
  transition: border-color .15s cubic-bezier(.4,0,.2,1);

  margin-top: 5px;
  margin-bottom: 5px;


}
.dashboard__input__text__label {
  width:100%;
}

.dashboard__filter-bar {
  width:90%;
  border: 1px solid #c5c5c5;
  height: 150px;
  overflow-y: scroll;
  position: relative;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
}

.filter-bar-li {
  padding: 3px 0 3px 10px;
}
.filter-bar-label {
  margin: 0;
  font-size: 12px;
  cursor: pointer;
}
.dashboard__select__button__style:hover {
  border-color: #6b6b6b;
}

.dashboard__select__checkbox__style {
  cursor: pointer;
  margin-top: 5px;
  margin-bottom: 5px;
}

.dashboard__input__text__control {
  /*bui-inout-text__control*/
  border-radius: 2px;
      margin: 0;
      /*font-family: */
  background: none;
  display: block;
  flex-grow: 1;
  border: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  padding: 7px;
  outline: none;

  /*Further PG Stylings*/
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  border-radius: 2px;
  border: 1px solid #bdbdbd;
  cursor: pointer;
  text-align: left;
  color: #333;
  background: #fff;
  width: 100%;

}

.dashboard__input__text__field {
  flex-grow: 1;
  align-items: center;
  width: 100%;
}

.awrapper {
  width:100%;
}

.dashboard-iux-phone-number {
  position: relative;
}

.dashbaord-iux-phone-number__country-code {
  max-width: 94px;

  /*bui-dropdown*/
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.dashboard-input-information-caption {
  max-width: 75%;
  white-space: normal;
  font-weight: 400!important;
  color: #6b6b6b;
  font-size: 12px;

  margin-top: 8px !important;
}

/* Events, Experiences & Wine Lists styles*/

.my-dashboard__card-list{
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.my-dashboard__list__item {
  border-bottom: 1px solid #eeedf2;
}

.my-dashboard-listings-cell-container {
  background-color: transparent;
}

.my-dashboard-listings-cell-interior{
    /*width: 90%;*/
    width: 100%

}


.my-dashboard-listings-cell {

  /*eds-g-group */
  font-size: 12;
  letter-spacing: 0;


  /*eds-list-item */
  /*edit-list-item */
  /*eds-align--space-between*/
  display: flex;
  justify-content: space-between;
  /* eds-l-pad-all-4*/
  padding: 16px;
  /* eds-l-mn-pad-hor-6*/
  /* eds-l-md-pad-hor-6*/
  /* eds-l-mw-pad-hor-6*/

  /* eds-l-lw-pad-hor-6*/

}
@media (min-width: 1272px) and (max-width: 1607px)
.my-dashboard-listings-cell {
  /* eds-l-ln-pad-hor-6*/
  /* eds-l-lg-pad-hor-6*/
  padding-left: 24px;
  padding-right: 24px;

}

@media (min-width: 792px)
.my-dashboard-listings-cell {
  /*eds-list-item */
  align-items: center;
}

.my-dashboard-listings-edit-link {
  background:none;
  outline: none;
  box-sizing: border-box;
  width: 100%;
  padding: 0;
  text-align: left;
  text-decoration: none;
}

.my-dashboard-listings-edit-link:hover {
  cursor: pointer;
}

.my-dashboard-list-item
{
  /*eds-list-tem*/
  display: flex;
  -webkit-box-psck: justify;
  justify-content: space-between;

  /*eds-list-item-align-middle*/
  align-items: center;

}
.my-dashboard-list-item-cell {
  /*eds-list-item__aside*/
  flex: 0 0 auto;
  font-size: 8 !important;

  /*eds-list-item__extra-content*/

  /*eds-l-pad-right-4*/
  padding-right: 16px;

}

.my-dashboard-list-item-cell-container {

}

.my-dashboard-list-item-cell-thumbnail-date-container {
  /*date-thumbnail eds-text--center*/

  /* eds-l-mar-top-1*/
  margin-top: 4px;

}

.my-dashboard-list-item-cell-thumbnail-date-month {
  /*date-thumbnail__month*/

  /*eds-text-color--primary-brand*/
  /*color: #d1410c;*/
  color: #ccaa6f;
  text-transform: uppercase;
  font-weight: 500;

  /*eds-text-bs*/
}

@media (min-width: 960px)
.my-dashboard-list-item-cell-thumbnail-date-month {
    font-size: .75rem;
    line-height: 1rem;
}

.my-dashboard-list-item-cell-thumbnail-date-day {
  /*date-thumbnail__day eds-text-bl*/

  /*eds-text-color--ui-600*/
  color: #6f7287;
}
@media (min-width: 960px)
.my-dashboard-list-item-cell-thumbnail-date-day {
    font-size: 1.125rem;
    line-height: 1.5rem;
}

.my-dashboard-list-item-cell-thumbnail-img-container{
  /*eds-list-item__aside*/
  flex: 0 0 auto;

  /*eds-list-item__graphic*/
  overflow: hidden;
  margin-right: 16px;
  position: relative;

  /*eds-list-item__graphic--square*/
  width: 56px;
  height: 56px;
  border-radius: 2px;





}

.my-dashboard-list-item-cell__contents {
  padding: 0;
  margin: 0;
  flex: 1 1 auto;
  min-width: 0;
  align-items:flex-start;
}

.my-dashboard-list-item-cell__contents_title {
  color: #1e0a3c;
  font-size: 12px;
  font-weight: 600;


}

.my-dashboard-list-item-cell__contents_venue {
  color: #1e0a3c;
  font-size: 12px;
  font-weight: 400;
}
.my-dashboard-list-item-cell__contents_date {
  font-size: 12px;
}

.my-dashboard-list-item-cell-event-analytics-container {
  width: 100%;
  display: block;
  box-sizing: border-box;
}

/* new dashboard listing styles*/
.my-dashboard__list {
  padding: 0px !important;
  margin-left: -8px !important;
  margin-right: -8px!important;

}

.my-dashboard__list__item__wrapper {
  display: inline-block !important;
  vertical-align: top !important;
  white-space: normal !important;
}

@media (min-width: 1128px)
.my-dashboard__list__item__wrapper {
  width: 100% !important;
}

.my-dashboard__list__item__wrapper__interior{
  width: 100% !important;
  height: 100% !important;
  padding-top: 8px !important;
  padding-bottom: 12px !important;
}

@media (min-width: 744px)
.my-dashboard__list__item__wrapper__interior{
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.my-dashboard__list__item__wrapper__interior__spacer {
  margin-top: 12px;
  margin-bottom: 24px;
}

.my-dashboard__list__item__wrapper__interior__spacer_hr {
  border-bottom: 1px solid rgb(235, 235, 235) !important;
}

.my-dashboard__list__item__content {
  display: flex !important;
  height: 100% !important;
  position: relative !important;
  z-index: 1 !important;
}

.my-dashboard__list__item__content__link {
  inset: 0px !important;
  position: absolute !important;
}

@media (min-width: 744px)
.my-dashboard__list__item__content__link {
  border-radius: 8px !important;
}

.my-dashboard__list__item__content__img__container {
  flex-grow: 0 !important;
  flex-shrink: 0 !important;
  /*width: 300px !important;
  height: 200px !important;*/
  width: 56px;
  height: 56px;
}


.my-dashboard__list__item__content__img__wrapper{
  position: relative !important;
  -webkit-mask-image: -webkit-radial-gradient(center, white, black) !important;
  contain: none !important;
  overflow: hidden !important;
}

@media (min-width: 744px)
.my-dashboard__list__item__content__img__wrapper{
  border-radius: 8px !important;
}

.my-dashboard__list__item__content__img__interior {
  position: relative !important;
}

.my-dashboard__list__item__content__img__mask {
  padding-top: 66.6667%;
  background: rgb(216, 216, 216);
  position: relative !important;
  width: 100% !important;
  z-index: 0 !important;
  contain: none !important;

}
.my-dashboard__list__item__content__img__mask__interior {
  position: absolute !important;
  inset: 0px !important;
  height: 100% !important;
  width: 100% !important;
}

._ttw0d {
    overflow: hidden !important;
    height: 100% !important;
    width: 100% !important;
    position: relative !important;
}

._15tommw {
    display: block !important;
    height: 100% !important;
    width: 100% !important;
    position: absolute !important;
    top: 0px !important;
    left: 0px !important;
    z-index: 1 !important;
}

._yydl86 {
    width: 100% !important;
    height: 100% !important;
    white-space: nowrap !important;
}
._1na7kj9b {
    display: inline-block !important;
    height: 100% !important;
    width: 100% !important;
    vertical-align: middle !important;
}

._1h6n1zu {
    display: inline-block;
    vertical-align: bottom;
    height: 100%;
    width: 100%;
    min-height: 1px;
    border-radius: 0px;
    background-position: 50% 50% !important;
    background-repeat: no-repeat !important;
}

._9ofhsl {
    object-fit: cover;
    vertical-align: bottom;
    border-radius: 0px;

    height: 100% !important;
    width: 100% !important;
    position: static !important;
}

/* moredashboard listing styles*/
.my-dashboard-cards{
  width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.my-dashboard-cards__acard {
    display: flex;

    position: relative;
    margin-bottom: 16px;

}

.my-dashboard-cards__acard:hover {
  position: relative;
  box-shadow: 0 1px 4px rgba(156, 156, 156, 0.3);
  cursor: pointer;

}

.my-dashboard-cards__acard__img {
  min-height: 56px;
  margin-bottom: 4px;
}




@media (min-width: 1056px)
.my-dashboard-cards__acard__img {
    min-width: 12.8125rem;
}

@media (min-width: 768px)
.my-dashboard-cards__acard__img {
    width: 25%;
    min-width: 5.625rem;
}
.my-dashboard-cards__acard__card_body{
  width: 100%;

}



@media (min-width: 768px)
.my-dashboard-cards__acard__card_body {
  margin-left: 16px;
}

.my-dashboard-cards__acard__img__src {
    width: 100%;
    height: auto;
    border-radius: 4px;
    margin-left: 16px;
}

.my-dashboard-cards__acard__title_elements {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 4px;
  margin-left: 32px;
}

.my-dashboard-cards__acard__title_elements__title {
  color: #247f9e;
  margin: 0;
  font-size: 12px;
  font-weight: 700;
  line-height: 24px;
  word-break: break-word;
}
.my-dashboard-cards__acard__event_status {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 4px 0 0;
  white-space: nowrap;
}

.my-dashboard-cards__acard__event_status_title {
  color: #2d333f;
  font-size: 12px;
  font-weight: 600;
}
.my-dashboard-cards__acard__event_status_status {
  display: inline-block;
  color: #2d333f;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  width: calc(100% - 2.1875rem);
  text-overflow: ellipsis;
  overflow: hidden;
  margin-left: 4px;
}


.my-dashboard-listings__img {
  display: block;
  /* overflow: visible */
  /*z-index:-1;/*
  margin-left: auto;
  margin-right: auto;

  /*width: 100%;*/

  /* trying to make the hero responsive*/
  align-items: center;
  width: 100%;
  object-fit: contain;

}

.dashboard-containeer {

  margin: 0 auto;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  background-color: #fff;

  margin-top: 10px;
  margin-bottom: 100px;

  justify-content: space-around;
  align-items: flex-start;

}
.dashboard-form {
  border: 1px solid #bdbdbd;
}

.dashboard-row-wrapper {
    cursor: pointer;
    border-bottom: 1px solid #e9e9e9;
    color: #686868;
    margin: 5px;
    padding: 20px;
}

.dashboard-row-wrapper:hover {
  cursor: pointer;
}

.dashboard-row-title {
    font-size: 1.25rem;
    text-align: left;
    /*font-family: Euclid-Regular, Arial, sans-serif, Helvetica, Arial;*/
    font-weight: 500;
    line-height: 1.6;
    margin: 0;
}

.events_and_experiences__hero {
  display: flex;
  flex-direction: row;

}

.events_and_experiences__hero-md {
  margin-bottom: 30px;
  padding-top: 50px;
}

.events_and_experiences__hero-container {
  -webkit-box-flex: 0;
  /*flex: 0 0 83.33%;*/
  /*max-width: 83.33%;*/

  width: 100%;
  padding: 0;
  position: relative;
}

.events_and_experiences__hero-image {
  /*BlogHero__Image*/
  /*display:flex;*/
  /*BlogHero-Image--md*/
  margin-left: auto;

  /*BlogHero-Image--lg*/
  margin-right: 30px;

  /*BlogHero-Image--xl*/
  /*height: 428px;*/
  /*width: 760px;*/
  width: 100%;
  object-fit: contain;
}


.event-experience_Hero_Img_Centered {
    margin: 0 auto;
    width: 100%;
    object-fit: contain;
    height: auto;
    border-radius: 4px;
}

.events_and_experiences__hero-image-img-content {
  background: center center no-repeat;
  /*background-size: cover;*/
  display: flex;
  width: 100%;
  object-fit: contain;


}

.events_and_experiences__hero-image-img-content_{
  display: block;
  /* overflow: visible */
  z-index:-1;
  margin-left: auto;
  margin-right: auto;

  /*width: 100%;*/

  /* trying to make the hero responsive*/
  align-items: center;
  width: 100%;
  object-fit: contain;
  height:600px;

}

.Link__article {
  color: #2a2a2a;
  /*font-family: Beatrice,GT America,Helvetica,Verdana,sans-serif;*/
  font-weight: 700;
  line-height: 1.38;
  letter-spacing: -.25px;
  text-decoration: none;
}

.Link {
      font-size: 16px;
      transition: background .15s ease-out,color .25s ease-out,opacity .25s ease-out,box-shadow .15s ease-out;
}

.events_and_experiences__hero-page-description {

  left: 0;

  /*BlogHero__Content*/
  background: #fff;
  padding: 15px;

  /*BlogHero__Content-md*/
  bottom: 0;
  margin-right: 0;
  margin-bottom: 0;
  padding-left: 0;
  padding-bottom: 0;
  position: absolute;
  top: auto;


  /*BlogHero__Content-lg*/
  width: 420px;

  /*BlogHero__Content-xl*/
}

.events_and_experiences__hero-page-description-title {
  /*Link*/
  font-size: 16px;
  transition: background .15s ease-out,color .25s ease-out,opacity .25s ease-out,box-shadow .15s ease-out;


  /*Link__article*/
  color: #2a2a2a;
  /*font-family: Beatrice,GT America,Helvetica,Verdana,sans-serif;*/
  font-weight: 700;
  line-height: 1.38;
  letter-spacing: -.25px;
  text-decoration: none;

  /*BlogHero__Tag*/
  /*cursor: pointer;*/


}

.events_and_experiences__hero-page-description-title-main {
  /*Link*/
  font-size: 16px;
  transition: background .15s ease-out,color .25s ease-out,opacity .25s ease-out,box-shadow .15s ease-out;


  /*Link__article*/
  color: #2a2a2a;
  /*font-family: Beatrice,GT America,Helvetica,Verdana,sans-serif;*/
  font-weight: 700;
  line-height: 1.38;
  letter-spacing: -.25px;
  text-decoration: none;

  margin-bottom: 2px;
}

.events_and_experiences__hero-page-description-subtitle {
  font-size: 14px;
  line-height: 1.43;
  color: #737373;
  font-weight: normal;

}

.HeroDetails {
  display: flex;
  -webkit-box-orient: vertical;
  -flex-direction: column;
  padding-left: 10px;
  padding-right: 15px;
}

.Blurb {
  padding: 10px 0;
  margin-bottom: 5px;
  min-width:200px;
  width: 100%;
}

.Blurb__Content {
  font-size: 12px;
  margin-bottom: 10px;
}

.HeroDetails__SocialMedia {
  display: flex;
  margin-bottom: 35px;
}
.HeroDetails__social-media_link{
  font-size: 21px;
  margin-right: 20px;
  cursor: pointer;
}

.HeroDetails__social-media_link:hover {

}

.review-header {
    font-weight: 700!important;
    font-size: 24px;
    line-height: 32px;
    text-rendering: optimizeLegibility;
}

.review-sub-heading {
  font-weight: 700!important;
  font-size: 18px;
  line-height: 24px;
}

.review-divider {
  margin-bottom: 0!important;

  background-color: transparent;
  display: block;
  height: 1px;
  border-width: 0;
  border-top: 1px solid #e7e7e7;
  line-height: 19px;
  margin-top: 0;

  width: calc(100% - 28px);
}

.review-form {

  width: 100%;

}

.review-comment-section {
  position: relative;
  padding: 14px;
}

.comment-area {
  height: 134px;
  margin-top: 0px;
  margin-bottom: 0px;

  background-color: #fff;
  width: 100%;
  padding: 5px 7px;
  resize: vertical;

  border: 1px solid #888C8C;
  border-radius: 3px;
  box-shadow: 0 1px 2px rgb(15 17 17 / 15%) inset;
  outline: 0;

  overflow: auto;
  vertical-align: top;
}

.review-button-section {
      text-align: right!important;
      padding: 14px;
}

.review-button {
  border-color: #D5D9D9;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  display: inline-block;
  padding: 0;
  text-align: center;
  text-decoration: none!important;
  vertical-align: middle;

  border-radius: 8px;
  box-shadow: 0 2px 5px 0 rgb(213 217 217 / 50%);
}
.review-button:hover {
  cursor: pointer;
}
.review-button-normal {

}
.review-button-primary {
  background: #FFD814;
  border-color: #FCD200;
}

.review-button-text {
    background-color: transparent;
    border: 0;
    display: block;
    font-size: 13px;
    line-height: 29px;
    margin: 0;
    outline: 0;
    padding: 0 10px 0 11px;
    text-align: center;
    white-space: nowrap;
}

.start-rating:hover {
  cursor: pointer;
}

/* Will edit selected date or the endpoints of a range of dates*/
.CalendarDay__selected {
  background: green;
  color: white;
}

.cart__page__strapline {
    text-align: center;
    text-transform: uppercase;
    /* margin-bottom: 40px; */
    font-size: 22px;
    margin: 1.5em auto .3em;
    /* margin-top: 20px; */
    margin-top: 0px;
    font-weight: 600;
}

.stack {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.stack-element {
    margin: 0 0 1.5rem 1.5rem;
}

.stack-element-main {
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    width: 100%;
    margin-bottom: 5px;
}
.stack-element-inner {
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    width: 100%;
}

.cart-stack-element-title {
  margin: 0 0 1.5rem 1.5rem;
  flex: auto;
  max-width: 100%;
  position: relative;
  justify-content: center;
}

.spacing__large {
    margin-bottom: 1.5rem;
}

.cart__page__intro-copy {
    text-align: center;
    color: #8D8478;
    max-width: 580px;
    font-weight: 300;
    margin-left: auto;
    margin-right: auto;
    font-size: 22px;
}


.shopping-links-wrapper {
  display:flex;
  align-items: center;
  margin-top: 10px;
}
.shopping-links {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    z-index: 2;
    /* color: #8d8478; */
    color: #000;
    font-weight: 400;
    font-size: 14px;
    text-transform: uppercase;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    color: #8D8478;

}

.shopping-links-separator {
    background: #919191;
    border-radius: 50%;
    margin: 0 10px;
    width: 5px;
    height: 5px;
    display: flex;
    align-items: center;
}

.cart-link {
  text-decoration: none;
  color: #8D8478;
}

.cart-link:hover {
  cursor: pointer;
  text-decoration: none;
  color: black;

}

@media (min-width: 64rem)
.show-for-large-formats {
    display: block;
}
@media (min-width: 30rem)
.hide-for-small-screens {
    display: none;
}

.cart-page {
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #fff;

}

.cart-page-elevation {
  box-shadow: none;
}

.cart-title-container {
  /*width: 100%;
  display: flex;
  padding: 1rem 0;
  flex-wrap: nowrap;
  align-items: center;
  flex-direction: row;
  text-transform: uppercase;*/

  display: flex;
  justify-content: center;
  align-items: center;

}
.title__ruler{
  width: 25%;
  color: #ABABAB;
  /*background: #ABABAB;*/



}

.cart-entry{
  /*box-shadow:hsl(0, 0%, 80%) 0 0 16px;*/
  box-shadow: 0 0.125rem 0.5rem hsl(0deg 0% 60% / 40%)
}
.sticky-wrapper {
  position: relative;

}

.sticky-summary{
  position: -webkit-sticky;
  position: sticky;
  /*position: fixed;
  width: 25%;*/
  /*position: relative;*/
  /*right: 0;*/
  /*top: 0;*/
  z-index: 1;
  /*right:0;*/

}



.cart-summary-title-container {
  width: 100%;
  display: block;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;

}

@media (min-width: 600px)
.cart-summary-title-container {
    padding-left: 24px;
    padding-right: 24px;
}

@media (min-width: 1280px)
.cart-summary-title-container-maxWidthLg {
    max-width: 1440px;
}

.cart__element__strapline {
    font-size: 1.25rem;
    text-align: center;
    /*font-family: Euclid-Regular, Arial, sans-serif, Helvetica, Arial;*/
    font-weight: 500;
    line-height: 1.6;
    margin: 0;
}
.product-image {
  width: 100%
}

.image-map-wrapper {
      text-align: center;
}

.cart_Img_Centered {
  margin: 0 auto;

  /*trying to make the image resposive*/
  width: 100%;
  object-fit: contain;
  max-width: 400px;
  max-height: 150px;
  height: auto;
  border-radius: 4px;
}

.cart_Event-title {
  text-transform: none;
  font-size: 18px; /*1.375rem;*/
  /*line-height: 26px; /* 1.625rem;*/
  font-weight: 400;
  /*margin: auto;*/
  /*margin-top: 10px;*/
  /*margin-bottom: 10px;*/
  /*z-index: 2;*/
  text-align: left;
  align-items: left;
  /*max-width: 300px; /*Same width as journal-image*/
  /*min-height:50px;*/
}

.text-right {
  text-align: right;
}

.remove-icon-wrapper {
  display: inline-block;
  position: relative;
}

.remove-icon-wrapper:hover {
  cursor: pointer;
}

.cart__copy__description {
    color: #888888;
    text-decoration: none;
    font-size: 12px;
    text-transform: none;
    font-weight: 500;
    align-items: left;
}

.my-cart-cards{
  width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.cart-summary-element-container {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #fff;
  max-width: 100%;
  flex-basis: 100%;
  border: 1px solid grey;
}

.cart-summary-content {
    margin: 12px 0;
    display: flex;
    flex-grow: 1;
    transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.quantity-button:hover {
    cursor: pointer;
}

.cart__buttons {
  z-index: 100;
  /*position: absolute;*/
  /*top: 50%;*/
  /* left: -2%; */
  /*border: .0625rem solid #d8d9db;*/
  border: none;
  /*border-radius: 50%;*/
  height: auto;
  /*width: 42px;*/
  background-color: #fff;
  /*display: inline-flex;*/
  justify-content: center;
  align-items: center;
  /*box-shadow: 0 0.125rem 0.5rem hsl(0deg 0% 60% / 40%);*/
  font-weight: 900;
}

.cart__buttons:hover {
    cursor: pointer;
}

.button_cursor:hover {
  cursor: pointer
}

.inc_dec_buttons {
  /*font-size:20px;
  height:20;
  color: #000;*/
  text-align: center;
  border: 1px solid;
  border-color: #888888;
  background: #EFEFEF;
  width: 24px;
  font-weight: 900;
}

.disable_inc {
  color: #fff
}

.quantity__controls {
    /*style = {{display:"flex", alignItems:"center",textAlign:"center"}}*/
    display: inline-flex;
    justify-content: center;
    align-items: center;


}

.quantity__controls__container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
}

.quantity__control_display{
  text-align: center;
  border: 1px solid;
  border-color: #888888;
  background: #FFFFFF;
  width: 24px;
}

.btn_hover_styles {
  background-color: #EFEFEF;
  color: red;
}

.bui-button {
  /*border: 1px solid #0071c2;*/
  /*border-radius: 2px;*/
  border: none;

  display: inline-flex;
  align-items: center;
  display: block;
  justify-content: center;
  text-align: left;
  padding: 7px 15px;
  cursor: pointer;
  font-family: inherit;
  text-decoration: none;

  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;


  box-sizing: border-box;
  vertical-align: middle;
  position: relative;


  min-height: 36px;
  min-width: 36px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.bui-button:hover {
  text-decoration: underline;
}

.bui-button_cross{
  border: none;



}
.bui-button_cross_original {
  /*border: 1px solid #0071c2;*/
  /*border-radius: 2px;*/
  border: none;

  display: inline-flex;
  align-items: center;
  display: block;
  justify-content: center;
  text-align: left;
  padding: 7px 15px;
  cursor: pointer;
  font-family: inherit;
  text-decoration: none;

  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;


  box-sizing: border-box;
  vertical-align: middle;
  position: relative;


  min-height: 36px;
  min-width: 36px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.order-summary-item-container {

    text-align: left;
    border: 1px solid;
    border-color: #888888;
    background: #EFEFEF;
    /*margin-bottom: 20px;*/
    margin:2px 2px 20px 2px;
    /*width: 24px;
    font-weight: 900;*/

}

.checkout-button {
  /*margin-top: 1rem;*/
  background-color: #212121;
  color: white;

  display: block;
  width: 100%;
  margin-left: 0;
  margin-right: 0;

  /*border styling*/
  border: 1px solid #212121;
  border-radius: .25rem;

  /*button text format*/
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  padding: 1rem 1.5rem;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  text-align: center;
  line-height: 1rem;

}

.checkout-button:hover {
    background-color: #212121;
    color: #767676;
}

.checkout-link {
  text-decoration: none;

}

.address-form-container {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #fff;
  width: 100%;

}

.payment-details-form-container {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #EFEFEF;;
  width: 100%;

}
.cart-title-container {
  width:100%
}

.address-form-wrapper {
  padding: 16px;
  flex-grow: 0;
  max-width: 100%;
  flex-basis: 100%;
  margin: 0;
  box-sizing: border-box;
}

.payment-details-form-wrapper {
  padding: 16px;
  flex-grow: 0;
  max-width: 100%;
  flex-basis: 100%;
  margin: 0;
  box-sizing: border-box;
}


.address-form-elements-wrapper {
    border: 0;
    margin: 0;
    display: inline-flex;
    padding: 0;
    position: relative;
    min-width: 0;
    flex-direction: column;
    vertical-align: top;
    text-transform: uppercase;
}

.address-form-input-wrapper {
  color: rgba(0, 0, 0, 0.87);
  cursor: text;
  display: inline-flex;

  font-size: 1rem;
  box-sizing: border-box;

  align-items: center;

  font-weight: 400;
  line-height: 1;
  line-height: 1.1876em;
  background-color: rgb(255, 255, 255);

}

.cart-page-forms-titles {
    color: #000;
    display: inline-block;
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 500;
    text-transform: uppercase;
}

.address-form-input {
    color: currentColor;
    width: 100%;
    border: 1px solid rgb(214, 214, 214);
    height: auto;
    margin: 0;
    display: block;
    padding: 0.75rem 1rem;
    position: relative;
    font-size: 0.875rem;
    background: none;
    box-sizing: content-box;

    -webkit-animation-name: mui-auto-fill-cancel;

            animation-name: mui-auto-fill-cancel;
    letter-spacing: inherit;
    -webkit-animation-duration: 10ms;
            animation-duration: 10ms;
    -webkit-tap-highlight-color: transparent;
}

.payment-details-title-wrapper {
  justify-content: space-between;
  align-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.payment-details-title-container {
  display: flex;
  flex-grow: 1;
  transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  margin: 20px 5px 0 5px;
}

.age_confirm__container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
}

.cart-form-styles {

  /*class = "form-control form-control-sm form-field"*/
  vertical-align: middle;
  text-indent: 10px;
  width: 100%;
  border: 1px solid #ced4da;
  border-radius: 3px;
  background-color: #fff;
  font-size: 16px;
  height: calc(1.5em + .45rem + 2px);
  font: 400 ;


}  ::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aab7c4;
  opacity: 1; /* Firefox */
}  :-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aab7c4;
  opacity: 1; /* Firefox */
}  ::-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aab7c4;
  opacity: 1; /* Firefox */
}  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aab7c4;
  opacity: 1; /* Firefox */
}

.cart-form-styles:focus {
  outline: none;
}

.cart-form-styles:hover {
  cursor: text;
}

.payment-form-cursor:hover {

}

.cart-form-text-element{
  display: flex;
  /*justify-content: center;*/
  vertical-align:middle;
  align-items: left;
  margin: 5px 0px;
  font-size: 12px;
}

.Side__Navbar {
  white-space: nowrap;
  margin-bottom: auto;
  /*margin-right: 24px;*/
  /*width       : 0;*/
  /*min-width: 308px;*/
  width: 100%;
  padding: 0;
  border-radius: 2px;
  border: 1px solid #e6e6e6;
  height: 100%;
}

.side__navigation__menu_flex {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}


.nav_links ul{
  margin: 0px 0px 0px 0px !important;
  padding: 0px 0px 0px 0px !important;
  /*margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
  list-style-type: none;*/

  /*
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #ffffff;
  text-align: center;
  */


  /*background-color: #f2f2f2*/
  /*box-shadow*/
}


.nav_links li {

  margin: 0;
  padding: 16px;
  width: 100%;
  text-decoration: none;
  display: list-item;
  /*text-align: -webkit-match-parent;*/

  border-style: none;
  display: flex;
  overflow: hidden;

  text-decoration: none;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 400;


  /*margin: 0 ;
  padding: 0px;
  border-bottom:  1px solid rgba(156,156,156,0.3);
  font-size: 14px;*/

  /*display: inline-block;*/
  /*float: right;*/

}

.nav_links_content{
  width: 100%;
}

.nav_links li :hover {
  color: #0071c2 !important;
  font-size: 16px;
  cursor: pointer;
  /*border-bottom:  1px solid black;*/

  /*text-transform: uppercase;*/
  text-decoration: none;
  font-weight: 400;
}

.nav_links_content :after {
  /*content: " ";
  height: 0;
  margin: 0 16px;
  border-radius: 2px;
  display: block;
  border: 1px #e6e6e6;
  border-style: solid solid none;*/
}

.icon {
       vertical-align: middle;

     }

/*
.nav_links ul li {
  margin: 0 ;
  padding: 0px;
  display: inline-block;
}*/

.navbarOther {
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
}
.menu-bars {
  /*margin-left: 2rem;*/
  color: black !important;
  background: none;

  width: 100%;
  text-decoration: none;
  display: list-item;
  text-align: -webkit-match-parent;

  border-style: none;
  display: flex;
  overflow: hidden;
  /*padding: 16px;*/
  text-decoration: none;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 400;
}

.menu-bars :hover {
  cursor: pointer;
  color: #0071c2 !important;
}

.sidebar-nav-menu {
  /*background-color: #060b26;*/
  background-color: white;
  width: 275px;
  height: 100%;
  display: flex;
  justify-content: center;
  /*position: fixed;*/
  transform: translateX(-0%);
  /*position: relative;*/
  position: absolute; /*this is a good one to use*/
  /*position: static;*/
  top: 0;
  left: -200%;
  transition: 850ms;
  z-index:5;

}

.sidebar-nav-menu.active {
  left: 0;
  /*align-items: left;*/
  transition: 350ms;
}

.dashboard-contents-container {
  position: relative;
  min-height: 800px;
}

.search-form-styles {

  /*class = "form-control form-control-sm form-field"*/
  display: block;
  vertical-align: middle;
  text-indent: 10px;
  width: 100%;
  border: none;
  border-radius: 3px;
  background-color: #fff;
  font-size: 16px;
  height: calc(1.5em + .45rem + 2px);
  font: 400 ;
  border-bottom:1px solid #ced4da;


}  ::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aab7c4;
  opacity: 1; /* Firefox */
}  :-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aab7c4;
  opacity: 1; /* Firefox */
}  ::-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aab7c4;
  opacity: 1; /* Firefox */
}  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aab7c4;
  opacity: 1; /* Firefox */
}

.search-form-styles:focus {
  outline: none;


}

.search-form-styles:hover {
  cursor: text;
  border-bottom:2px solid black;
  /*opacity: 0;
  transition: opacity 195ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  visibility: hidden;*/
}

.search-form-styles-no-styles {

  /*class = "form-control form-control-sm form-field"*/
  vertical-align: middle;
  text-indent: 10px;
  width: 100%;
  border: 1px solid #ced4da;
  border-radius: 3px;
  background-color: #fff;
  font-size: 16px;
  height: calc(1.5em + .45rem + 2px);
  font: 400 ;


}

.bui-button {
  /*border: 1px solid #0071c2;*/
  /*border-radius: 2px;*/
  border: none;

  display: inline-flex;
  align-items: center;
  display: block;
  justify-content: center;
  text-align: left;
  padding: 7px 15px;
  cursor: pointer;
  font-family: inherit;
  text-decoration: none;

  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;


  box-sizing: border-box;
  vertical-align: middle;
  position: relative;



  min-height: 36px;
  min-width: 36px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  background-color: #fff;
}

.bui-button:hover {
  text-decoration: underline;
}

.search-field-container {
  /*search-form-styles*/
  /*style = {{display:"flex", justifyContent: "center", marginLeft:"2px", marginRight:"2px"}}*/
  display: block;
  width: 100%;
  z-index: 0;
  position: relative;

  border: none;

  background-color: #fff;
  font-size: 16px;
  height: calc(1.5em + .45rem + 2px);
  font: 400 ;
  border-bottom:1px solid #ced4da;


}::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aab7c4;
  opacity: 1; /* Firefox */
}:-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aab7c4;
  opacity: 1; /* Firefox */
}::-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aab7c4;
  opacity: 1; /* Firefox */
}::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aab7c4;
  opacity: 1; /* Firefox */
}

.search-field-container:focus {
  outline: none;


}

.search-field-container:hover {
  /*cursor: text;
  border-bottom:2px solid black;*/
  /*opacity: 0;
  transition: opacity 195ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  visibility: hidden;*/
}

.search-field-view {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.search__input {
    width: 100%;
    border-radius: 2px;

    line-height: 1.75;
    font-weight: 400;
    font-size: 14px;
    height: 34px;


    border-color: #e1e9ee;


    border-color: var(--cool-gray-30,#e1e9ee);
    background-color: var(--voyager-color-background-input-search);
    border-radius: var(--corner-radius-small);
    color: var(--color-text-active);
    padding: 0 8px 0 40px;

    border: none;
    box-shadow: none;

}

/*.search__input, input.search__input:hover {*/
.search__input:hover {
    border: none;
    box-shadow: none;
    cursor: text;
}

.search-global-typeahead__search-icon-container {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    padding: 5px 4px;
    border-radius: 0 2px 2px 0;
    width: 28px;
    height: 34px;
    margin: 0;
    box-shadow: none;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color .15s;
}

.wine_profile__page__strapline {
  text-align: center;
  text-transform: uppercase;
  /*margin-bottom: 40px;*/
  font-size: 22px;
  margin: 1.5em auto .3em;
  /*margin-top: 20px;*/
  margin-top: 0px;
  font-weight: 600;
}

.wine_profile__page__intro-copy {
  text-align: center;
  color: #8D8478;
  /*margin: 1.5em auto .3em;
  margin-bottom: 40px;*/


  /* extra syling to constrain the text*/
  max-width: 580px;
  font-weight:300;
  margin-left:auto;
  margin-right: auto;
  font-size: 18px;


}

.wine_profile__page__intro-copy-paragraph {
  /*margin-top: 20px;
  margin-bottom: 20px;*/
  margin-top: 0px;
  margin-bottom: 0px;
  color: #8D8478;
  max-width: 1000px;
  font-weight: 300;
  margin-left: auto;
  margin-right: auto;
  font-size: 16px;

}

.wine_profile-info {

}

.wine_profile-info-wrapper{
  padding: 50px 0;
}

.wine_profile_Img_wrapper{
      text-align: center;
}

.wine_profile_Img_Centered {
  /*margin: 0 auto;*/
  /*trying to make the image resposive*/
  /*width: 100%;
  object-fit: contain;
  height: auto;
  border-radius: 4px;

  margin: 0 auto;*/

  /*trying to make the image resposive*/
  width: 100%;
  object-fit: contain;
  max-width: 400px;
  max-height: 300px;
  height: auto;
  border-radius: 4px;

}

.wine_profile-name-wrapper{
    display: flex;
    align-items: center;
    margin-bottom: 14px;
}

.wine_profile_origin-name-wrapper{
    display: flex;
    align-items: center;
    margin-bottom: 14px;
}
.wine_profile-inpage-details-title{
  color: #737373;
  font-weight: bold;
  font-size: 16px;
  text-transform: capitalize;
}

.wine_profile__row__strapline {
  text-align: left;
  text-transform: capitalize;
  /*margin-bottom: 40px;*/
  font-size: 22px;
  margin: 1.5em auto .3em;
  /*margin-top: 20px;*/
  margin-top: 0px;
  font-weight: 600;
}

.wine_profile_origin-info-wrapper {
  padding: 16px;
  flex-grow: 0;
  max-width: 100%;
  flex-basis: 100%;
  margin: 0;
  box-sizing: border-box;
}

.wine_profile_origin-info-container {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #fff;
  width: 100%;

}

.wine_profile_origin-info-container-two {
  border: none;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #fff;
  width: 100%;

}

.wine_profile-inpage-elements-wrapper {
  /*.wine_profile-name-wrapper*/
    border: 0;
    margin: 0;
    display: inline-flex;
    padding: 0;
    position: relative;
    min-width: 0;
    flex-direction: column;
    vertical-align: top;
    text-transform: uppercase;

    color: #737373;
    font-weight: bold;
    font-size: 16px;

}

.cart-form-text-element{
  display: flex;
  /*justify-content: center;*/
  vertical-align:middle;
  align-items: left;
  margin: 5px 0px;
  font-size: 14px;
}

.wine_profile_fields_container {
  padding: 5px 5px 5px 5px ;
}

.range {
  height: 80px;
  width: 100%;
  /*border: 1px solid black;
  border-radius: 10px;
  padding: 0 65px 0 45px;
  */
  padding: 0 10px 0 10px;
}

.rangeField {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.rangeField-Input {
  -webkit-appearance: none;
  height: 8px;
  width: 100%;
  /*background: #ddd;*/
  background: #f7f3f0;
  border-radius: 5px;
  outline: none;
  border: none;
}

.rangeField-Input::-webkit-slider-thumb{
  -webkit-appearance: none;
  height: 8px;
  /*width: 10px;*/

  background: #ba1628;
  border-radius: 50%;
  border: 1px solid #ba1628;

  cursor: pointer;
  width: 15%;
  border-radius: 7px;
}

.rangeField-Input::-moz-range-progress{

  background:  #664AFF; /*this progress background color is only shown on Mozilla Firefox*/
}

.rangeField_value {
  position: absolute;
  font-size: 12px;
  font-weight: 600;
  /*color: #664AFF;*/
}
.rangeField-value_left {
  left: -22px;
  font-size: 18px;
  font-weight: 600;
  margin-right: 5px;
  /*color: #664AFF;*/
}

.rangeField-value_right {
  right: -43px;
  font-size: 18px;
  font-weight: 600;
  margin-left: 5px;
  /*color: #664AFF;*/
}

.sliderValue{
  /*position: relative;*/
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #664AFF;
}

.sliderValue_Text {
  position: absolute;
  height: 45px;
  width: 45px;
  /*background: #664AFF;*/
  /*background: red;*/
  color: #fff;
  font-weight: 500;
  top: -40px;

  /*transform: translateX(-50%) scale(0);*/
  transform: translateX(-50%);
  transform-origin: bottom;
  transition: transform 0.3s ease-in-out;
  line-height: 55px;
  z-index: 2;
}

.sliderValue_Text:after {
  position: absolute;
  content: "";
  height: 45px;
  width: 45px;
  background: #664AFF;
  /*background: red;*/
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  border: 3px solid #fff;
  z-index: -1;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  border-bottom-left-radius: 50%;}

.sliderValue_Text .show{
  transform: translateX(-50%) scale(1);
}

.output-area {
  display: flex;
  align-items: center;
  justify-content: center;
}


.wine_profile_textarea_input {
  width: 100%;
  height: 150px;
  padding: 15px 20px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #ced4da;

  /* search__input
  width: 100%;
  border-radius: 2px;

  line-height: 1.75;*/
  font-weight: 400;
  font-size: 14px;
  /*height: 34px;*/
  border-color: #e1e9ee;
  border-color: var(--cool-gray-30,#e1e9ee);
  background-color: var(--voyager-color-background-input-search);
  border-radius: var(--corner-radius-small);
  color: var(--color-text-active);
  /*padding: 0 8px 0 40px;


  border: none;
  box-shadow: none;
  */
}
.wine_profile_textarea_input:focus {
  border: 1px solid #DFE5EB;
  /*outline: 0;*/
}

.wine_profile_textarea_input:hover {
    /*border: none;*/
    box-shadow: none;
    cursor: text;
}

.wine_profile_box_input {
  vertical-align: middle;
  text-indent: 10px;
  width: 100%;
  border: 1px solid #ced4da;
  border-radius: 3px;
  background-color: #fff;
  font-size: 16px;
  height: calc(1.5em + .45rem + 2px);
  font: 400 ;

} ::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aab7c4;
  opacity: 1; /* Firefox */
} :-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aab7c4;
  opacity: 1; /* Firefox */
} ::-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aab7c4;
  opacity: 1; /* Firefox */
} ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aab7c4;
  opacity: 1; /* Firefox */
}

.wine_profile_box_input:focus {
  border: 1px solid #DFE5EB;
  /*outline: 0;*/
  /*outline: none;*/
}

.wine_profile_box_input:hover {
    /*border: none;*/
    box-shadow: none;
    cursor: text;
}

.wine_profile_search_box_input {
  vertical-align: middle;
  text-indent: 25px;
  width: 100%;
  border-bottom: 1px solid #ced4da;
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 3px;
  background-color: #fff;
  font-size: 16px;
  height: calc(1.5em + .45rem + 2px);
  font: 400 ;

}::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aab7c4;
  opacity: 1; /* Firefox */
}:-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aab7c4;
  opacity: 1; /* Firefox */
}::-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aab7c4;
  opacity: 1; /* Firefox */
}::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aab7c4;
  opacity: 1; /* Firefox */
}

.wine_profile_search_box_input:focus {
  border-bottom: 1px solid #DFE5EB;

  /*outline: 0;*/
  outline: none;
}

.wine_profile_search_box_input:hover {
    border-bottom: 1px solid #DFE5EB;
    box-shadow: none;
    cursor: text;
}

.search_box_styles {
  color: #aab7c4
}

.wine_profile_for_paragraph {
  min-height: 14px;
}

.wine_profile_select_box_input {
  vertical-align: middle;
  text-indent: 10px;
  width: 100%;
  border: 1px solid #ced4da;
  border-radius: 3px;
  background-color: #fff;
  font-size: 16px;
  height: calc(1.5em + .45rem + 2px);
  font: 400 ;

} ::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aab7c4;
  opacity: 1; /* Firefox */
} :-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aab7c4;
  opacity: 1; /* Firefox */
} ::-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aab7c4;
  opacity: 1; /* Firefox */
} ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aab7c4;
  opacity: 1; /* Firefox */
}

.wine_profile_select_box_input:focus {
  border: 1px solid #DFE5EB;
  /*outline: 0;*/
  /*outline: none;*/
}

.wine_profile_select_box_input:hover {
    /*border: none;*/
    box-shadow: none;
    cursor:pointer;

}

.binary_selection_input_buttons {
  list-style: none;
  margin-left: 0;
  border-bottom: 1px solid #ced4da;
  /*padding-bottom: 1rem;*/
}

.binary_selection_input_buttons_value {
  display: block;
      margin: 0 0 .25rem;
}

.checkbox_label {
  display: block;
  padding-left: 15px;
  text-indent: -15px;
}

.wine_profile_checkbox_input {
  /*vertical-align: middle;*/
  vertical-align: middle;
  position: relative;
  top: -1px;
  text-indent: 10px;
  /*width: 100%;*/
  height: 15px;
  width: 15px;
  border: 1px solid #ced4da;
  border-radius: 3px;
  background-color: #fff;
  font-size: 16px;
  height: calc(1.5em + .45rem + 2px);
  font: 400 ;



} ::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aab7c4;
  opacity: 1; /* Firefox */
} :-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aab7c4;
  opacity: 1; /* Firefox */
} ::-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aab7c4;
  opacity: 1; /* Firefox */
} ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aab7c4;
  opacity: 1; /* Firefox */
}

.wine_profile_checkbox_input:focus {
  border: 1px solid #DFE5EB;
  /*outline: 0;*/
  /*outline: none;*/
}

.wine_profile_checkbox_input:hover {
    /*border: none;*/
    box-shadow: none;
    cursor: pointer;
}

.confirm-update-button {
  /*margin-top: 1rem;*/
  background-color: #212121;
  color: white;

  display: block;
  width: 100%;
  margin-left: 0;
  margin-right: 0;

  /*border styling*/
  border: 1px solid #212121;
  border-radius: .25rem;

  /*button text format*/
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  padding: 1rem 1.5rem;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  text-align: center;
  line-height: 1rem;

}

.confirm-update-button:hover {
    background-color: #212121;
    color: #767676;
}

.cancel-update-button {
  /*margin-top: 1rem;*/
  background-color: white;
  color: #212121;

  display: block;
  width: 100%;
  margin-left: 0;
  margin-right: 0;

  /*border styling*/
  border: 1px solid #212121;
  border-radius: .25rem;

  /*button text format*/
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  padding: 1rem 1.5rem;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  text-align: center;
  line-height: 1rem;

}

.cancel-update-button:hover {
    background-color: #212121;
    color: #767676;
}

.wrapper_search_box {
  /*Controls the responsiveness of the search list*/
  /*width:100%;*/

  display: block;
  width: 100%;
  /*z-index: 9999;*/
  z-index: 0;
  position: relative;

  border: none;

  /*background-color: #fff;
  font-size: 16px;
  height: calc(1.5em + .45rem + 2px);
  font: 400 ;
  border-bottom:1px solid #ced4da;*/

}::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aab7c4;
  opacity: 1; /* Firefox */
}:-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aab7c4;
  opacity: 1; /* Firefox */
}::-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aab7c4;
  opacity: 1; /* Firefox */
}::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aab7c4;
  opacity: 1; /* Firefox */
}

.wrapper_search_box:focus {
  outline: none;
}

.wrapper_search_box:hover {
}

.search-input {
  position: relative;
  background: #fff;
  width: 100%;
  border-radius: 5px;
  /*z-index:100;*/
  z-index: 0;
  opacity: 1;
  /*box-shadow: 0px 1px 5px 3px rgba(0, 0, 0, 0.12);*/

}

.search-input.active {

}

.search-input  input  {
  vertical-align: middle;
  text-indent: 25px;
  width: 100%;
  border-bottom: 1px solid #ced4da;
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 3px;
  /*background-color: #fff;*/
  background-color: transparent;
  font-size: 16px;
  height: calc(1.5em + .45rem + 2px);
  font: 400 ;
  /*outline: none;
  border: none;
  border-radius: 5px;
  padding: 0 60px 0 20px;
  font-size: 18px;*/
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  z-index: 0 !important;
}::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aab7c4;
  opacity: 1; /* Firefox */
}:-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aab7c4;
  opacity: 1; /* Firefox */
}::-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aab7c4;
  opacity: 1; /* Firefox */
}::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aab7c4;
  opacity: 1; /* Firefox */
}


.search-input  input:focus {
  border-bottom: 1px solid #DFE5EB;
  outline: none;
}

.search-input  input:hover {
    border-bottom: 1px solid #DFE5EB;
    box-shadow: none;
    cursor: text;
}

.search-input_icon {
  color: #aab7c4;
  /*height: 55px;
  width: 55px;
  line-height: 55px;
  position: absolute;
  top: 0;
  right: 0;
  text-align: center;
  font-size: 20px;
  cursor: pointer;*/
}

.autocom-box {
  /*background: #fff;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0px 1px 5px 3px rgba(0, 0, 0, 0.12);
  z-index: 10;*/
  padding: 0px; /*hide contents*/
  max-height: 280px;
  overflow-y: auto;
  opacity: 0; /*hide contents*/
  pointer-events: none; /*hide contents*/
  border-bottom: 1px solid #ced4da;
  border-left: 1px solid #ced4da;
  border-right: 1px solid #ced4da;
  /*z-index: 100 !important;*/
  z-index: 0;


}

.autocom-box.active {
  padding: 10px 8px;
  opacity: 1 !important; /*hide contents*/
  pointer-events: auto !important; /*hide contents*/
  /*z-index: 9999;*/
  z-index: 0;

}

.autocom-box li {
  list-style: none;
  padding: 8px 12px;
  width: 100%;
  cursor: default;
  border-radius: 3px;
  display: none; /*hide contents*/
  /*z-index: 9999;*/
  z-index: 0;
}

.autocom-box.active li {
  display: block;
  /*z-index: 9999;*/
  z-index: 0;
}


.autocom-box li:hover {
  background: #efefef;
  cursor: pointer;
}

.section-title-wrapper {

  /*border-bottom: 1px solid #e9e9e9;
  color: #686868;*/
  margin: 5px;
  /*padding: 20px;*/
}

.section-button-wrapper {

  /*border-bottom: 1px solid #e9e9e9;
  color: #686868;*/
  margin: 5px;
  /*padding: 20px;*/
}

.split-with-separator-alignment {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  /*z-index: 2;
  /*color: #8d8478;*/
  /*color: #000;
  font-weight: 400;
  font-size: 18px;
  text-transform: uppercase;
  text-align: center;*/
}

.wineListItem {

}

.wineListItem:hover {
    cursor:pointer;
}

.winelist-stack {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.winelist-stack-element-main {
    margin: 0 0 0 0;
    padding: 0 10px 0 10px;
    width: 100%;
    margin-bottom: 5px;
}

.winelist-stack-element-inner {
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    width: 100%;
}

.winelist-stack-entry-inner {
    margin: 0 0 0 0;
    padding: 0 5px 0 5px;
    width: 100%;
}

.winelist-listing-cards{
  width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.winelist-listing-entry{
  /*box-shadow:hsl(0, 0%, 80%) 0 0 16px;*/
  box-shadow: 0 0.125rem 0.5rem hsl(0deg 0% 60% / 40%)
}



.winelist-listing-entry-title {
  text-transform: none;
  font-size: 18px; /*1.375rem;*/
  font-weight: 400;
  text-align: left;
  align-items: left;
}

.winelist-listing-entry-title:hover {
    cursor:pointer;
}

.winelist-listing-entry__description {
    color: #888888;
    text-decoration: none;
    font-size: 12px;
    text-transform: none;
    font-weight: 500;
    align-items: left;
}

.add_to_event_experience_btn {
  width: 100%;
  /*background: #33ccff !important;*/
  /*color: white !important;*/
  color: #212121 !important;
  border-color:#212121 !important;
  background: transparent;
  transition: border-color 0.2s;
  align: center;

}

.add_to_event_experience_btn:hover {
  cursor: pointer;
  color: white !important;
  border-color: white;
  background-color:#212121 !important;
}

.event_experience_select_wrapper {
  padding: 5px 5px 5px 5px;
}

.body-class {
  margin: 0;
  padding: 0;
  background: #fff !important;
}

.privacy-strapline {
  text-align: left;
  /*text-transform: uppercase;*/
  /*margin-bottom: 40px;*/
  font-size: 16px;
  margin: 1.5em auto .3em;
  /*margin-top: 20px;*/
  font-weight: 600;
}

.policy-copy {
  font-size: 14px;
  text-align: left;
}

.policy-headings {
  text-align: left;
  font-size: 14px;
  margin: 1.5em auto .3em;
  font-weight: 630;
  text-transform: uppercase;
}

.stripe-link {
  color: black;
}

.stripe-link:hover {
  color: black;
  cursor: pointer;
}

ul {
  display: block;
  list-style-type: disc;
  margin-top: 1em;
  margin-bottom: 1 em;
  margin-left: 0;
  margin-right: 0;
  padding-left: 40px;
}

/*
  Testimonial stylings can be found at
  https://codepen.io/feyinway/pen/LjMPgv?editors=0100
*/


*,
*:after,
*:before {
    margin: 0;
    padding: 0;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    cursor: default;
}

.testimonial {
  width: 100%;
  /*position: absolute;*/
  /*top: 50%;*/


}

.testimonial .wrap {
  position: relative;
  width: 100%;
  /*max-width: 1020px;*/
  padding: 40px 20px;
  margin: auto;
  
}

.testimonial .arrow {
  display: block;
  position: absolute;
  color: black;
  /*color: #eee;*/
  cursor: pointer;
  font-size: 2em;
  top: 50%;
  transform: translateY(-50%);
  transition: all .3s ease-in-out;
  padding: 5px;
  z-index: 99;
}

.testimonial .arrow:before {
  cursor: pointer;
}

.testimonial .arrow:hover {
  /*color: #ea830e;*/
  color: black;
}

.testimonial .arrow.left {
  left : 10px;
  color: rgba(255, 255, 255, 0.0 ); /* hidden the arrow from view*/
}

.testimonial .arrow.right {
  right : 10px;
  color: rgba(255, 255, 255, 0.0 ); /* hidden the arrow from view*/
}

.testimonial .dots {
    text-align: center;
    position: absolute;
    width: 100%;
    bottom: 60px;
    left: 0;

    display: block;
    z-index: 92;
		height: 12px;
}

.testimonial .dots .dot {
    list-style-type: none;
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    /*border: 1px solid #eee;*/
    border: 1px solid black;
    margin: 0 10px;
    cursor: pointer;
    transition: all .5s ease-in-out;
		position: relative;
    background: rgba(141, 132, 120, 1.0); /*color: #8D8478;*/
    border-color: rgba(141, 132, 120, 0.0);

}

.testimonial .dots .dot.active
{
    background: rgba(30, 30, 30, 1.0); /* #1e1e1e*/
    border-color: rgba(30, 30, 30, 0.0);
}
.testimonial .dots .dot:hover {
    background: rgba(0, 0, 0, 1.0);

    border: 4px solid rgba(255, 255, 255, 1.0);
}

.testimonial .dots .dot.active {
    -webkit-animation: testim-scale .5s ease-in-out forwards;
    animation: testim-scale .5s ease-in-out forwards;
}

.testimonial .testim-content{
    position: relative;
		overflow: hidden;
}

.testimonial .testim-content > div {
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 0 70px 0;
    opacity: 0;
}

.testimonial .testim-content > div.inactive {
    opacity: 0;
    /* I changed the opacity to zero so it is not visible */
}

.testimonial .testim-content > div.active {
    position: relative;
    opacity: 1;
}

.testimonial .testim-content div h3 {
    color: #ea830e;
    font-size: 1em;
    margin: 15px 0;
}


.testimonial .testim-content .testimonial__text {
    font-size: 24px;
    /*color: #eee;*/
    color: black;
    width: 80%;
    margin: auto;
    font-weight: 900;
}

.testimonial .testim-content .testimonial__experience_text {
    font-size: 10px;
    text-transform: uppercase;
    /*color: #eee;*/
    color: black;
    width: 80%;
    margin: auto;
}

.testimonial .testim-content .testimonial__author {
    font-size: 12px;
    text-transform: uppercase;
    /*color: #eee;*/
    color: black;
    width: 80%;
    margin: auto;
}
/*.testimonial .testim-content div p {
    font-size: 1.15em;
    /*color: #eee;*/
/*    color: black;
    width: 80%;
    margin: auto;
}*/

.testimonial .testim-content div.active .img img {
    -webkit-animation: testim-show .5s ease-in-out forwards;
    animation: testim-show .5s ease-in-out forwards;
}

.testimonial .testim-content div.active h3 {
    -webkit-animation: testim-content-in .4s ease-in-out forwards;
    animation: testim-content-in .4s ease-in-out forwards;
}

.testimonial .testim-content div.active p {
    -webkit-animation: testim-content-in .5s ease-in-out forwards;
    animation: testim-content-in .5s ease-in-out forwards;
}

.testimonial .testim-content div.inactive .img img {
    -webkit-animation: testim-hide .5s ease-in-out forwards;
    animation: testim-hide .5s ease-in-out forwards;
}

.testimonial .testim-content div.inactive h3 {
    -webkit-animation: testim-content-out .4s ease-in-out forwards;
    animation: testim-content-out .4s ease-in-out forwards;
}

.testimonial .testim-content div.inactive p {
    -webkit-animation: testim-content-out .5s ease-in-out forwards;
    animation: testim-content-out .5s ease-in-out forwards;
}

@-webkit-keyframes testim-content-in {
    from {
        opacity: 0;
        transform: translateY(100%);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes testim-content-in {
    from {
        opacity: 0;
        transform: translateY(100%);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@-webkit-keyframes testim-content-out {
    from {
        opacity: 1;
        transform: translateY(0);
    }

    to {
        opacity: 0;
        transform: translateY(-100%);
    }
}

@keyframes testim-content-out {
    from {
        opacity: 1;
        transform: translateY(0);
    }

    to {
        opacity: 0;
        transform: translateY(-100%);
    }
}

@-webkit-keyframes testim-show {
    from {
        opacity: 0;
        transform: scale(0);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes testim-show {
    from {
        opacity: 0;
        transform: scale(0);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}

@-webkit-keyframes testim-hide {
    from {
        opacity: 1;
        transform: scale(1);
    }

    to {
        opacity: 0;
        transform: scale(0);
    }
}

@keyframes testim-hide {
    from {
        opacity: 1;
        transform: scale(1);
    }

    to {
        opacity: 0;
        transform: scale(0);
    }
}

@media all and (max-width: 300px) {
	body {
		font-size: 14px;
	}
}

@media all and (max-width: 500px) {
	.testimonial .arrow {
		font-size: 1.5em;
	}

	.testimonial .testim-content div p {
		line-height: 25px;
	}

}

.cookie__popup {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 2rem;
  background-color: #fff;
  opacity: 1;
  transition: opacity .8s ease;

  /*&.hidden*/
  .hidden {
    opacity:0 ;
  }
}

