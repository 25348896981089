.my-profile-container {
  min-width:550px;
}
.my-profile{
  border: 1px solid #bdbdbd;
  width: 100%;
  min-width:500px;
}

.my-profile-dashboard {
  border-bottom: 1px solid #e6e6e6;
}

.hidden{
        display:none !important;
    }

.my-dashboard-container {
  max-width: 600px;
  margin: 0 auto;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  background-color: #fff;

  margin-top: 10px;
  margin-bottom: 100px;

  justify-content: space-around;
  align-items: flex-start;

}

.my-dashboard-form-wrapper {
  position: -webkit-sticky;
  position: sticky;
  top: 0rem;
  border-bottom: 1px solid #e9e9e9;
  background-color: #fff;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.25);
  padding: 40px;
}

.my-dashboard-submit {
  background-color: #36d9b6 !important;
  border-radius: 0;
  border: 0;
  color: #fff;
}

.flex-wrapper {
  display: flex;
}

.my-dashboard-element-wrapper {
  cursor: pointer;
  border-bottom: 1px solid #e9e9e9;
  color: #686868;
  margin: 5px;
  padding: 20px;
}

.my-dashboard-element-wrapper:hover {
  background-color: #F0F0F0;

}

.my-dashboard-title-wrapper {
  cursor: pointer;
  border-bottom: 1px solid #e9e9e9;
  color: #686868;
  margin: 5px;
  padding: 20px;
}

.my-dashboard-title-wrapper-footer {
  cursor: pointer;
  border-bottom: 1px solid #e9e9e9;
  border-top: 1px solid #e9e9e9;
  color: #686868;
  margin: 5px;
  padding: 20px;
}



/* Original Styles*/
.my-dashboard-flex-row {
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
}

.my-dashboard-flex-grow {
    -webkit-box-flex: 1;
    flex-grow: 1;
    width: 0;
}

.my-dashboard-page {
  outline: none;

}

.my-dashboard-page-title {
  margin-top: 4px;
  margin-bottom: 16px;

  /*bui-title*/
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

.my-dashboard__title_text {
  margin: 0;

  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  text-align: left;
}
.my-dashboard__subtitle {
  margin-top: 4px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #6b6b6b;
  margin: 0;
  text-align: left;
}

.bui-button {
  border: 1px solid #0071c2;
  border-radius: 2px;

  display: inline-flex;

  -webkit-box-align: center;
  align-items: center;
  display: block;

  -webkit-box-pack: center;
  justify-content: center;
  text-align: left;
  padding: 7px 15px;
  cursor: pointer;
  font-family: inherit;
  text-decoration: none;

  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;


  box-sizing: border-box;
  vertical-align: middle;
  position: relative;


  min-height: 36px;
  min-width: 36px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}


.bui-button-tertiary {
  padding-left: 7px;
    padding-right: 7px;
    background-color: transparent;
    color: #0071c2;
    border-color: transparent;

    font-weight: 500;

    /*display: block;
    align-items: center;*/

}

.bui-button-tertiary:hover {
  padding-left: 7px;
    padding-right: 7px;
    background-color: #fff;
    color: #0071c2;
    border-color: transparent;
    border-color: #0071c2;
    font-weight: 500;
}

.bui-button-tertiary-outlined {
  padding-left: 7px;
    padding-right: 7px;
    background-color: transparent;
    color: #0071c2;
    border-color: transparent;
    border-color: #0071c2;
    font-weight: 600;
}

.bui-button-tertiary-outlined:hover {
  padding-left: 7px;
    padding-right: 7px;
    background-color: #fff;
    color: #0071c2;

    border-color: #0071c2;
    font-weight: 600;
}


.bui-button-tertiary-activate-outlined {
  padding-left: 7px;
    padding-right: 7px;
    background-color: #97e59c;
    color: #000000;
    border-color: transparent;
    border-color: #000000;
    font-weight: 600;
}

.bui-button-tertiary-activate-outlined:hover {
  padding-left: 7px;
    padding-right: 7px;
    background-color: #97e59c;
    color: #fff;

    border-color: #000;
    font-weight: 600;
}

.bui-button-tertiary-danger {
  padding-left: 7px;
    padding-right: 7px;
    background-color: transparent;
    color: #0071c2;
    border-color: transparent;

    font-weight: 500;
}

.bui-button-tertiary-danger:hover {
  padding-left: 7px;
    padding-right: 7px;
    background-color: #fff;
    color: #0071c2;
    border-color: transparent;
    border-color: #0071c2;
    font-weight: 500;
}


.bui-button__text {
    display: inline-block;
    vertical-align: middle;

}

.my-dashboard-profile_pic-wrapper {
    position: relative;
}

.bui-profile_pic {
    position: relative;

    border-radius: 50%;


    display: flex;

    -webkit-box-align: center;
    align-items: center;

    -webkit-box-pack: center;
    justify-content: center;
    background-color: #333;
    color: #fff;
    text-transform: uppercase;
}

.bui-profile_pic--larger {
    height: 64px;
    width: 64px;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
}

.bui-profile_pic--outline-complement {
    border: 2px solid #febb02;
}

.bui-icon {
    display: inline-block;

}

.my-dashboard-profile_pic-edit-icon {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;

    overflow: hidden;
    text-align: center;
    padding-bottom: 4px;
    z-index: 1;
    opacity: .9;
}

.bui-icon--smaller {
    height: 14px;
}

.bui-icon--color-white {
    color: #fff;
    fill: #fff;
}

.bui-icon svg {
    display: inline-block;
    vertical-align: top;
    height: 100%;
    width: auto;
}


.my-dashboard-edit-profile {
  border-bottom: 1px solid #e6e6e6;
}

.bui-f-font-featured {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.my-dashboard-row {


  display: flex;

  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
}

.my-dashboard-row-edit {
  color: #333;
  border-top: 1px solid #e6e6e6;
  position: relative;
}



@media (min-width: 992px)
.my-dashboard-row-edit {
    display: flex;
    padding: 0;
}


@media (min-width: 992px)
.my-dashboard-col-shrink {
    width: 130px;
    min-width: 130px;
    overflow-x: hidden;
    position: relative;
}
.my-dashboard-col-shrink{
  white-space: normal;
}

.my-dashboard-col-grow {

  white-space: normal;
  -ms-flex-positive: 1;
  -webkit-box-flex: 1;
  flex-grow: 1;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;

}

@media (min-width: 992px)
.my-dashboard-cell-spacing {
    padding: 16px 16px 12px;
}
.my-dashboard-cell-spacing {

}
.my-dashboard-flex-row-reverse {

  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  flex-direction: row-reverse;



}

@media (min-width: 992px)
.my-dashboard-col-edit {
    width: 130px;
    min-width: 130px;
}

.my-dashboard-col-edit {
  /*width: 110px;
  min-width: 110px;*/
  text-align: right;
}

.my-dashboard-display-mode {

}

.bui-f-font-body {
    font-weight: 700;
}


.profile-pic {

  /*width: 100%;
  object-fit: contain;
  max-width: 400px;
  margin-left:20px;
  margin-right:20px;*/

  display: block;
  /* overflow: visible; */

  margin-left: auto;
  margin-right: auto;
  /* width: 100%; */
  align-items: center;
  width: 90%;
  object-fit: contain;

}

/* dashboard form styles*/
.dashboard__select__button__style {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #333;
  background: #fff;
  border-radius: 2px;
  border: 1px solid #bdbdbd;
  display: block;
  /*font-family*/
  padding: 7px;
  width: 100%;
  cursor: pointer;
  text-align: left;
  transition: border-color .15s cubic-bezier(.4,0,.2,1);

  margin-top: 5px;
  margin-bottom: 5px;


}
.dashboard__input__text__label {
  width:100%;
}

.dashboard__filter-bar {
  width:90%;
  border: 1px solid #c5c5c5;
  height: 150px;
  overflow-y: scroll;
  position: relative;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
}

.filter-bar-li {
  padding: 3px 0 3px 10px;
}
.filter-bar-label {
  margin: 0;
  font-size: 12px;
  cursor: pointer;
}
.dashboard__select__button__style:hover {
  border-color: #6b6b6b;
}

.dashboard__select__checkbox__style {
  cursor: pointer;
  margin-top: 5px;
  margin-bottom: 5px;
}

.dashboard__input__text__control {
  /*bui-inout-text__control*/
  border-radius: 2px;
      margin: 0;
      /*font-family: */
  background: none;
  display: block;

  -webkit-box-flex: 1;
  flex-grow: 1;
  border: none;
  appearance: none;
  padding: 7px;
  outline: none;

  /*Further PG Stylings*/
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  border-radius: 2px;
  border: 1px solid #bdbdbd;
  cursor: pointer;
  text-align: left;
  color: #333;
  background: #fff;
  width: 100%;

}

.dashboard__input__text__field {
  -webkit-box-flex: 1;
  flex-grow: 1;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
}

.awrapper {
  width:100%;
}

.dashboard-iux-phone-number {
  position: relative;
}

.dashbaord-iux-phone-number__country-code {
  max-width: 94px;

  /*bui-dropdown*/
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.dashboard-input-information-caption {
  max-width: 75%;
  white-space: normal;
  font-weight: 400!important;
  color: #6b6b6b;
  font-size: 12px;

  margin-top: 8px !important;
}

/* Events, Experiences & Wine Lists styles*/

.my-dashboard__card-list{
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.my-dashboard__list__item {
  border-bottom: 1px solid #eeedf2;
}

.my-dashboard-listings-cell-container {
  background-color: transparent;
}

.my-dashboard-listings-cell-interior{
    /*width: 90%;*/
    width: 100%

}


.my-dashboard-listings-cell {

  /*eds-g-group */
  font-size: 12;
  letter-spacing: 0;


  /*eds-list-item */
  /*edit-list-item */
  /*eds-align--space-between*/
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  /* eds-l-pad-all-4*/
  padding: 16px;
  /* eds-l-mn-pad-hor-6*/
  /* eds-l-md-pad-hor-6*/
  /* eds-l-mw-pad-hor-6*/

  /* eds-l-lw-pad-hor-6*/

}
@media (min-width: 1272px) and (max-width: 1607px)
.my-dashboard-listings-cell {
  /* eds-l-ln-pad-hor-6*/
  /* eds-l-lg-pad-hor-6*/
  padding-left: 24px;
  padding-right: 24px;

}

@media (min-width: 792px)
.my-dashboard-listings-cell {
  /*eds-list-item */
  -webkit-box-align: center;
  align-items: center;
}

.my-dashboard-listings-edit-link {
  background:none;
  outline: none;
  box-sizing: border-box;
  width: 100%;
  padding: 0;
  text-align: left;
  text-decoration: none;
}

.my-dashboard-listings-edit-link:hover {
  cursor: pointer;
}

.my-dashboard-list-item
{
  /*eds-list-tem*/
  display: flex;
  -webkit-box-psck: justify;
  justify-content: space-between;

  /*eds-list-item-align-middle*/
  -webkit-box-align: center;
  align-items: center;

}
.my-dashboard-list-item-cell {
  /*eds-list-item__aside*/
  -webkit-box-flex: 0;
  flex: 0 0 auto;
  font-size: 8 !important;

  /*eds-list-item__extra-content*/

  /*eds-l-pad-right-4*/
  padding-right: 16px;

}

.my-dashboard-list-item-cell-container {

}

.my-dashboard-list-item-cell-thumbnail-date-container {
  /*date-thumbnail eds-text--center*/

  /* eds-l-mar-top-1*/
  margin-top: 4px;

}

.my-dashboard-list-item-cell-thumbnail-date-month {
  /*date-thumbnail__month*/

  /*eds-text-color--primary-brand*/
  /*color: #d1410c;*/
  color: #ccaa6f;
  text-transform: uppercase;
  font-weight: 500;

  /*eds-text-bs*/
}

@media (min-width: 960px)
.my-dashboard-list-item-cell-thumbnail-date-month {
    font-size: .75rem;
    line-height: 1rem;
}

.my-dashboard-list-item-cell-thumbnail-date-day {
  /*date-thumbnail__day eds-text-bl*/

  /*eds-text-color--ui-600*/
  color: #6f7287;
}
@media (min-width: 960px)
.my-dashboard-list-item-cell-thumbnail-date-day {
    font-size: 1.125rem;
    line-height: 1.5rem;
}

.my-dashboard-list-item-cell-thumbnail-img-container{
  /*eds-list-item__aside*/
  -webkit-box-flex: 0;
  flex: 0 0 auto;

  /*eds-list-item__graphic*/
  overflow: hidden;
  margin-right: 16px;
  position: relative;

  /*eds-list-item__graphic--square*/
  width: 56px;
  height: 56px;
  border-radius: 2px;





}

.my-dashboard-list-item-cell__contents {
  padding: 0;
  margin: 0;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  min-width: 0;
  align-items:flex-start;
}

.my-dashboard-list-item-cell__contents_title {
  color: #1e0a3c;
  font-size: 12px;
  font-weight: 600;


}

.my-dashboard-list-item-cell__contents_venue {
  color: #1e0a3c;
  font-size: 12px;
  font-weight: 400;
}
.my-dashboard-list-item-cell__contents_date {
  font-size: 12px;
}

.my-dashboard-list-item-cell-event-analytics-container {
  width: 100%;
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

/* new dashboard listing styles*/
.my-dashboard__list {
  padding: 0px !important;
  margin-left: -8px !important;
  margin-right: -8px!important;

}

.my-dashboard__list__item__wrapper {
  display: inline-block !important;
  vertical-align: top !important;
  white-space: normal !important;
}

@media (min-width: 1128px)
.my-dashboard__list__item__wrapper {
  width: 100% !important;
}

.my-dashboard__list__item__wrapper__interior{
  width: 100% !important;
  height: 100% !important;
  padding-top: 8px !important;
  padding-bottom: 12px !important;
}

@media (min-width: 744px)
.my-dashboard__list__item__wrapper__interior{
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.my-dashboard__list__item__wrapper__interior__spacer {
  margin-top: 12px;
  margin-bottom: 24px;
}

.my-dashboard__list__item__wrapper__interior__spacer_hr {
  border-bottom: 1px solid rgb(235, 235, 235) !important;
}

.my-dashboard__list__item__content {
  display: flex !important;
  height: 100% !important;
  position: relative !important;
  z-index: 1 !important;
}

.my-dashboard__list__item__content__link {
  inset: 0px !important;
  position: absolute !important;
}

@media (min-width: 744px)
.my-dashboard__list__item__content__link {
  border-radius: 8px !important;
}

.my-dashboard__list__item__content__img__container {
  -webkit-box-flex: 0 !important;
  flex-grow: 0 !important;
  flex-shrink: 0 !important;
  /*width: 300px !important;
  height: 200px !important;*/
  width: 56px;
  height: 56px;
}


.my-dashboard__list__item__content__img__wrapper{
  position: relative !important;
  -webkit-mask-image: -webkit-radial-gradient(center, white, black) !important;
  contain: none !important;
  overflow: hidden !important;
}

@media (min-width: 744px)
.my-dashboard__list__item__content__img__wrapper{
  border-radius: 8px !important;
}

.my-dashboard__list__item__content__img__interior {
  position: relative !important;
}

.my-dashboard__list__item__content__img__mask {
  padding-top: 66.6667%;
  background: rgb(216, 216, 216);
  position: relative !important;
  width: 100% !important;
  z-index: 0 !important;
  contain: none !important;

}
.my-dashboard__list__item__content__img__mask__interior {
  position: absolute !important;
  inset: 0px !important;
  height: 100% !important;
  width: 100% !important;
}

._ttw0d {
    overflow: hidden !important;
    height: 100% !important;
    width: 100% !important;
    position: relative !important;
}

._15tommw {
    display: block !important;
    height: 100% !important;
    width: 100% !important;
    position: absolute !important;
    top: 0px !important;
    left: 0px !important;
    z-index: 1 !important;
}

._yydl86 {
    width: 100% !important;
    height: 100% !important;
    white-space: nowrap !important;
}
._1na7kj9b {
    display: inline-block !important;
    height: 100% !important;
    width: 100% !important;
    vertical-align: middle !important;
}

._1h6n1zu {
    display: inline-block;
    vertical-align: bottom;
    height: 100%;
    width: 100%;
    min-height: 1px;
    border-radius: 0px;
    background-position: 50% 50% !important;
    background-repeat: no-repeat !important;
}

._9ofhsl {
    object-fit: cover;
    vertical-align: bottom;
    border-radius: 0px;

    height: 100% !important;
    width: 100% !important;
    position: static !important;
}

/* moredashboard listing styles*/
.my-dashboard-cards{
  width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.my-dashboard-cards__acard {
    display: flex;

    position: relative;
    margin-bottom: 16px;

}

.my-dashboard-cards__acard:hover {
  position: relative;
  box-shadow: 0 1px 4px rgba(156, 156, 156, 0.3);
  cursor: pointer;

}

.my-dashboard-cards__acard__img {
  min-height: 56px;
  margin-bottom: 4px;
}




@media (min-width: 1056px)
.my-dashboard-cards__acard__img {
    min-width: 12.8125rem;
}

@media (min-width: 768px)
.my-dashboard-cards__acard__img {
    width: 25%;
    min-width: 5.625rem;
}
.my-dashboard-cards__acard__card_body{
  width: 100%;

}



@media (min-width: 768px)
.my-dashboard-cards__acard__card_body {
  margin-left: 16px;
}

.my-dashboard-cards__acard__img__src {
    width: 100%;
    height: auto;
    border-radius: 4px;
    margin-left: 16px;
}

.my-dashboard-cards__acard__title_elements {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 4px;
  margin-left: 32px;
}

.my-dashboard-cards__acard__title_elements__title {
  color: #247f9e;
  margin: 0;
  font-size: 12px;
  font-weight: 700;
  line-height: 24px;
  word-break: break-word;
}
.my-dashboard-cards__acard__event_status {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 4px 0 0;
  white-space: nowrap;
}

.my-dashboard-cards__acard__event_status_title {
  color: #2d333f;
  font-size: 12px;
  font-weight: 600;
}
.my-dashboard-cards__acard__event_status_status {
  display: inline-block;
  color: #2d333f;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  width: calc(100% - 2.1875rem);
  text-overflow: ellipsis;
  overflow: hidden;
  margin-left: 4px;
}


.my-dashboard-listings__img {
  display: block;
  /* overflow: visible */
  /*z-index:-1;/*
  margin-left: auto;
  margin-right: auto;

  /*width: 100%;*/

  /* trying to make the hero responsive*/
  align-items: center;
  width: 100%;
  object-fit: contain;

}

.dashboard-containeer {

  margin: 0 auto;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  background-color: #fff;

  margin-top: 10px;
  margin-bottom: 100px;

  justify-content: space-around;
  align-items: flex-start;

}
.dashboard-form {
  border: 1px solid #bdbdbd;
}

.dashboard-row-wrapper {
    cursor: pointer;
    border-bottom: 1px solid #e9e9e9;
    color: #686868;
    margin: 5px;
    padding: 20px;
}

.dashboard-row-wrapper:hover {
  cursor: pointer;
}

.dashboard-row-title {
    font-size: 1.25rem;
    text-align: left;
    /*font-family: Euclid-Regular, Arial, sans-serif, Helvetica, Arial;*/
    font-weight: 500;
    line-height: 1.6;
    margin: 0;
}
