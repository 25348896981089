.Events__Experiences__Store_Container{
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 32px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 0.0625rem 0 rgb(0 0 0 / 10%);
}

@media (min-width: 768px)
.Events__Experiences__Store_Header_Container {
    margin: 0 32px;
}

.Events__Experiences__Store_Header{
    padding-bottom: 16px;
}

.Events__Experiences__Store_Header_Copy {
  font-size: 18px;
  text-align: center;
}

@media (min-width: 768px)
.Events__Experiences__Store_Header_Copy {
    margin: 16px 0;
}

.Events__Experiences__Store_Header_Body_Spacer {
  margin: -.0625rem 0 0;
  height: .0625rem;
  border: none;
  width:100%
}

.Events__Experiences__Store_Front_Container{
  display: flex;
  margin: 0 16px;
  width:100%;
}

@media (min-width: 768px)
.Events__Experiences__Store_Front_Container {
    margin-left: 32px;
    margin-right: 0;
}

.Events__Experiences__Store_Front_List_Container{
  width: 66.6666%;
}
@media (min-width: 1056px)
.Events__Experiences__Store_Front_List_Container {

}

.Events__Experiences__Store_Front_Map_Container {
  position: sticky;
  top: 16px;
  margin-bottom: 32px;

  display: inline-block;
  width: 33.33333%;
  height: 25rem;
}

@media (min-width: 1056px)
.Events__Experiences__Store_Front_Map_Container {
    display: inline-block;
    width: 33.33333%;
    height: 25rem;
}

.Events__Experiences__Store_Front_Map_Area {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 95%;
  height: 100%;
  margin-left: auto;


}

.Events__Experiences__Store_Front_Img_Centered {
  margin: 0 auto;

  /*trying to make the image resposive*/
  width: 100%;
  object-fit: contain;
  max-width: 400px;
  max-height: 150px;
  height: auto;
  border-radius: 4px;
}

.Events__Experiences__Store_Front_Card_Row_Format {
  margin-top:auto;
  margin-bottom: 16px;
  padding: 0 0 16px;
  border-bottom: .0625rem solid #d8d9db;
}

.Events__Experiences__Store_Front_Event-title {
  text-transform: none;
  font-size: 18px; /*1.375rem;*/
  /*line-height: 26px; /* 1.625rem;*/
  font-weight: 400;
  /*margin: auto;*/
  /*margin-top: 10px;*/
  /*margin-bottom: 10px;*/
  /*z-index: 2;*/
  text-align: left;
  align-items: left;
  /*max-width: 300px; /*Same width as journal-image*/
  /*min-height:50px;*/
}

/*.journal-title {
    cursor: pointer;
    color: #414141;
}*/


.Events__Experiences__Store_Front__copy__strapline{
  color: #888888;
  text-decoration: none;
  font-size: 12px; /*.75rem*/
  text-transform: uppercase;
  font-weight: 500;
  align-items:left;
  /*min-height:30px;
  max-height: 30px;*/
}

.Events__Experiences__Store_Front__copy__description{
  color: #888888;
  text-decoration: none;
  font-size: 12px; /*.75rem*/
  text-transform: none;
  font-weight: 500;
  align-items:left;
  /*min-height:30px;
  max-height: 30px;*/
}

.Events__Experiences__Store_Front__copy__date {
    color: #888888;
    font-weight: 400;
    font-size: 12px;
    text-transform: uppercase;
    align-items:left;
    text-align: left;
}

.Events__Experiences__Store_Front__category {

  color: #ccaa6f;
  text-decoration: none;
  text-align: left;
  font-size: 12px; /*.75rem*/
  text-transform: uppercase;
  font-weight: 500;

  /*max-width: 300px; /*Same width as journal-image*/
}
.event-experience-info-wrapper{
  padding: 50px 0px;
}

.event-experience-name-wrapper{
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin-bottom: 14px;
}

.event-experience-name{
  text-align: center;
  text-transform: uppercase;
  font-size: 22px;
  margin: 0 auto .3em;
  font-weight: 600;
}

.event-experience_Img_wrapper{

}

.event-experience_Img_Centered {
  margin: 0 auto;

  /*trying to make the image resposive*/
  width: 100%;
  object-fit: contain;
  height: auto;
  border-radius: 4px;
}

.venue-location-summary{
  width: 100%;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  background-color: #fff;
  margin-top: 20px;
}

.venue-location-summary-content{
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  line-height: 24px;
}
.venue-location-summary_Map_Area{
  /*height: 100%;
  width: 100%;
  object-fit: contain;*/

  position: relative;
  /*top: 0;
  right: 0;
  bottom: 0;
  left: 0;*/
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 150px;
  margin-left: auto;


}

.venue-location-summary-Map_Area_Wrapper{
  /*height: 100px;*/
}

.venue-location-summary-content-info{
  -webkit-box-flex: 1;
  flex: 1;
  margin-right: 15px;
  padding: 20px;
}
.event-experience-name-breadcrumb{
  text-transform: uppercase;
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 16px;
  color: #8d8478;
}

.venue-location-summary-content-info-breadcrumb{

  color: #8d8478;
  max-width: 580px;
  font-weight: 300;
  margin-left: auto;
  margin-right: auto;
  font-size: 18px;


}

.venue-location-summary-content-row{
  cursor: pointer;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  height: 50px;
  border-top: 1px solid #eaeaea;
  padding: 0 20px;
  font-size: 16px;
  text-overflow: ellipsis;
  color: #336dde;;
}

.spacer {
  margin-right: "20px"
}

.e9508c55 {
    border: 1px solid #d8d9db;
    padding: .25rem .25rem .5rem;
    border-radius: 4px;
    margin-bottom: 1rem;
}

.event-experience-meta {
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 2;
  color: #8d8478;
  font-weight: 300;
  font-size: 18px;
}


.event-experience__intro-copy-paragraph {
    /*margin-top: 20px;
    margin-bottom: 20px;*/
    margin-top: 0px;
    margin-bottom: 0px;
    color: #8D8478;
    max-width: 1000px;
    font-weight: 300;
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
}

.separator {
    background: #919191;
    border-radius: 50%;
    margin: 0 10px;
    width: 5px;
    height: 5px;
}

.event-experience-date-and-time-wrapper {
  align-items: center;
}

.event-experience-date-and-time {
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 2;
  /*color: #8d8478;*/
  color: #000;
  font-weight: 400;
  font-size: 18px;
  text-transform: uppercase;
  text-align: center;
}

.event-experience-inpage-title{
  text-align: center;
  text-transform: uppercase;
  font-size: 18px;
  margin: 0 auto .3em;
  font-weight: 600;
}

.event-experience-inpage-details-title{
  color: #737373;
  font-weight: bold;
  font-size: 16px;
  text-transform: capitalize;
}

.event-experience-inpage-sub-details-title {
  font-weight:500;
  color: #8d8478
}


.mh-select {
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    color: #212121;
    background-color: #fff;
    padding: 0 1.25rem;
}
.form-field-wrapper{

  -webkit-box-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: middle;

}
.event-experience-select-form-field{

  border: 0;
  /*position: relative;
  top: 50%;*/
  border-bottom: 1px solid #d2d2d2;
  outline: 0;
  /*font-size: 14px;*/
  color: #212121;
  background: transparent;
  transition: border-color 0.2s;
  width: 80%;
}

.event-experience-add-to-cart {
  /*margin-top: 1rem;*/
  background-color: #212121;
  color: white;

  display: block;
  width: 100%;
  margin-left: 0;
  margin-right: 0;

  /*border styling*/
  border: 1px solid #212121;
  border-radius: .25rem;

  /*button text format*/
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  padding: 1rem 1.5rem;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  text-align: center;
  line-height: 1rem;
}

.event-experience-add-to-cart:hover {
    background-color: #212121;
    color: #767676;
}

.fa-edit {
  color: #8d8478;

}
.fa-edit:hover {
  cursor: pointer;
  color: #AAAAAA;
  /*color: #EFEFEF;*/
}

.event_experience-stack {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}


.event_experience-stack-element-inner {
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    width: 100%;
}

.event_experience-cards{
  width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.event_experience-entry{
  /*box-shadow:hsl(0, 0%, 80%) 0 0 16px;*/
  box-shadow: 0 0.125rem 0.5rem hsl(0deg 0% 60% / 40%)
}

.event_experience-entry-inner {
    margin: 0 0 0 0;
    padding: 0 5px 0 5px;
    width: 100%;
}

.event_experience-entry-title {
  text-transform: none;
  font-size: 18px; /*1.375rem;*/
  font-weight: 400;
  text-align: left;
  align-items: left;
}


.event_experience-entry__description {
    color: #888888;
    text-decoration: none;
    font-size: 12px;
    text-transform: none;
    font-weight: 500;
    align-items: left;
}

.event-experience-summary {
  background: #f2f4f6;
  /*-webkit-box-shadow: inset 0 0 0 1000px #f2f4f6;*/
  box-shadow: inset 0 0 0 1000px #f2f4f6;
  border-radius: 4px;
  margin-Bottom: 5px;
}

.event-experience-summary-header {
  background: #fff;
}

.confirm-details {
  text-transform: uppercase;
  font-size: 9px;
}

.confirm-details-highlight {
  color: #0896ff;
  font-weight: bold;
  font-size: 13px;
}

.event-experience-summary-footer {
  background: #fff;
}
