*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body{
  font-family: 'Open Sans', sans-serif;
  width: 80%;
  margin: 0 auto;
  background: rgb(251, 254, 255);
}

.newsGrid {
  margin: 50px auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;

}

.newsGridHeading{
  margin-top: 30px;
}

.newsItem{
  max-width: 500px;
  padding: 30px;
  border: 1px solid lightgray;
  border-radius: 5px;
}

.newsItem__title{
  padding-bottom: 10px;
  border-bottom: 1px solid lightgray;
}
.newsItem__desc{
  padding-top: 10px;
  line-height: 25px;
  font-size-adjust: 14px;
  padding-bottom: 10px;
}
.newsItem__author{
  margin-right: 10px;
  color: purple;

}
.newsItem__publishedAt{
  margin-right: 10px;
}
.newsItem__source{

}

.newsItem__source, .newsItem__publishedAt {
  display: inline-block;
  margin-top: 10px;
  color: lightgray;
}
