.body-class {
  margin: 0;
  padding: 0;
  background: #fff !important;
}

.privacy-strapline {
  text-align: left;
  /*text-transform: uppercase;*/
  /*margin-bottom: 40px;*/
  font-size: 16px;
  margin: 1.5em auto .3em;
  /*margin-top: 20px;*/
  font-weight: 600;
}

.policy-copy {
  font-size: 14px;
  text-align: left;
}

.policy-headings {
  text-align: left;
  font-size: 14px;
  margin: 1.5em auto .3em;
  font-weight: 630;
  text-transform: uppercase;
}

.stripe-link {
  color: black;
}

.stripe-link:hover {
  color: black;
  cursor: pointer;
}

ul {
  display: block;
  list-style-type: disc;
  margin-top: 1em;
  margin-bottom: 1 em;
  margin-left: 0;
  margin-right: 0;
  padding-left: 40px;
}
