.search-form-styles {

  /*class = "form-control form-control-sm form-field"*/
  display: block;
  vertical-align: middle;
  text-indent: 10px;
  width: 100%;
  border: none;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background-color: #fff;
  font-size: 16px;
  height: calc(1.5em + .45rem + 2px);
  font: 400 ;
  border-bottom:1px solid #ced4da;


}  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aab7c4;
  opacity: 1; /* Firefox */
}

.search-form-styles:focus {
  outline: none;


}

.search-form-styles:hover {
  cursor: text;
  border-bottom:2px solid black;
  /*opacity: 0;
  transition: opacity 195ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  visibility: hidden;*/
}

.search-form-styles-no-styles {

  /*class = "form-control form-control-sm form-field"*/
  vertical-align: middle;
  text-indent: 10px;
  width: 100%;
  border: 1px solid #ced4da;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background-color: #fff;
  font-size: 16px;
  height: calc(1.5em + .45rem + 2px);
  font: 400 ;


}

.bui-button {
  /*border: 1px solid #0071c2;*/
  /*border-radius: 2px;*/
  border: none;

  display: inline-flex;

  -webkit-box-align: center;
  align-items: center;
  display: block;

  -webkit-box-pack: center;
  justify-content: center;
  text-align: left;
  padding: 7px 15px;
  cursor: pointer;
  font-family: inherit;
  text-decoration: none;

  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;


  box-sizing: border-box;
  vertical-align: middle;
  position: relative;



  min-height: 36px;
  min-width: 36px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  background-color: #fff;
}

.bui-button:hover {
  text-decoration: underline;
}

.search-field-container {
  /*search-form-styles*/
  /*style = {{display:"flex", justifyContent: "center", marginLeft:"2px", marginRight:"2px"}}*/
  display: block;
  width: 100%;
  z-index: 0;
  position: relative;

  border: none;

  background-color: #fff;
  font-size: 16px;
  height: calc(1.5em + .45rem + 2px);
  font: 400 ;
  border-bottom:1px solid #ced4da;


}::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aab7c4;
  opacity: 1; /* Firefox */
}

.search-field-container:focus {
  outline: none;


}

.search-field-container:hover {
  /*cursor: text;
  border-bottom:2px solid black;*/
  /*opacity: 0;
  transition: opacity 195ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  visibility: hidden;*/
}

.search-field-view {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.search__input {
    width: 100%;
    border-radius: 2px;

    line-height: 1.75;
    font-weight: 400;
    font-size: 14px;
    height: 34px;


    border-color: var(--cool-gray-30,#e1e9ee);
    background-color: var(--voyager-color-background-input-search);
    border-radius: var(--corner-radius-small);
    color: var(--color-text-active);
    padding: 0 8px 0 40px;

    border: none;
    box-shadow: none;

}

/*.search__input, input.search__input:hover {*/
.search__input:hover {
    border: none;
    box-shadow: none;
    cursor: text;
}

.search-global-typeahead__search-icon-container {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    padding: 5px 4px;
    border-radius: 0 2px 2px 0;
    width: 28px;
    height: 34px;
    margin: 0;
    box-shadow: none;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color .15s;
}

.wine_profile__page__strapline {
  text-align: center;
  text-transform: uppercase;
  /*margin-bottom: 40px;*/
  font-size: 22px;
  margin: 1.5em auto .3em;
  /*margin-top: 20px;*/
  margin-top: 0px;
  font-weight: 600;
}

.wine_profile__page__intro-copy {
  text-align: center;
  color: #8D8478;
  /*margin: 1.5em auto .3em;
  margin-bottom: 40px;*/


  /* extra syling to constrain the text*/
  max-width: 580px;
  font-weight:300;
  margin-left:auto;
  margin-right: auto;
  font-size: 18px;


}

.wine_profile__page__intro-copy-paragraph {
  /*margin-top: 20px;
  margin-bottom: 20px;*/
  margin-top: 0px;
  margin-bottom: 0px;
  color: #8D8478;
  max-width: 1000px;
  font-weight: 300;
  margin-left: auto;
  margin-right: auto;
  font-size: 16px;

}

.wine_profile-info {

}

.wine_profile-info-wrapper{
  padding: 50px 0;
}

.wine_profile_Img_wrapper{
      text-align: center;
}

.wine_profile_Img_Centered {
  /*margin: 0 auto;*/
  /*trying to make the image resposive*/
  /*width: 100%;
  object-fit: contain;
  height: auto;
  border-radius: 4px;

  margin: 0 auto;*/

  /*trying to make the image resposive*/
  width: 100%;
  object-fit: contain;
  max-width: 400px;
  max-height: 300px;
  height: auto;
  border-radius: 4px;

}

.wine_profile-name-wrapper{
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin-bottom: 14px;
}

.wine_profile_origin-name-wrapper{
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin-bottom: 14px;
}
.wine_profile-inpage-details-title{
  color: #737373;
  font-weight: bold;
  font-size: 16px;
  text-transform: capitalize;
}

.wine_profile__row__strapline {
  text-align: left;
  text-transform: capitalize;
  /*margin-bottom: 40px;*/
  font-size: 22px;
  margin: 1.5em auto .3em;
  /*margin-top: 20px;*/
  margin-top: 0px;
  font-weight: 600;
}

.wine_profile_origin-info-wrapper {
  padding: 16px;
  flex-grow: 0;
  max-width: 100%;
  flex-basis: 100%;
  margin: 0;
  box-sizing: border-box;
}

.wine_profile_origin-info-container {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #fff;
  width: 100%;

}

.wine_profile_origin-info-container-two {
  border: none;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #fff;
  width: 100%;

}

.wine_profile-inpage-elements-wrapper {
  /*.wine_profile-name-wrapper*/
    border: 0;
    margin: 0;
    display: inline-flex;
    padding: 0;
    position: relative;
    min-width: 0;
    flex-direction: column;
    vertical-align: top;
    text-transform: uppercase;

    color: #737373;
    font-weight: bold;
    font-size: 16px;

}

.cart-form-text-element{
  display: flex;
  /*justify-content: center;*/
  vertical-align:middle;
  align-items: left;
  margin: 5px 0px;
  font-size: 14px;
}

.wine_profile_fields_container {
  padding: 5px 5px 5px 5px ;
}

.range {
  height: 80px;
  width: 100%;
  /*border: 1px solid black;
  border-radius: 10px;
  padding: 0 65px 0 45px;
  */
  padding: 0 10px 0 10px;
}

.rangeField {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.rangeField-Input {
  -webkit-appearance: none;
  height: 8px;
  width: 100%;
  /*background: #ddd;*/
  background: #f7f3f0;
  border-radius: 5px;
  outline: none;
  border: none;
}

.rangeField-Input::-webkit-slider-thumb{
  -webkit-appearance: none;
  height: 8px;
  /*width: 10px;*/

  background: #ba1628;
  border-radius: 50%;
  border: 1px solid #ba1628;

  cursor: pointer;
  width: 15%;
  border-radius: 7px;
}

.rangeField-Input::-moz-range-progress{

  background:  #664AFF; /*this progress background color is only shown on Mozilla Firefox*/
}

.rangeField_value {
  position: absolute;
  font-size: 12px;
  font-weight: 600;
  /*color: #664AFF;*/
}
.rangeField-value_left {
  left: -22px;
  font-size: 18px;
  font-weight: 600;
  margin-right: 5px;
  /*color: #664AFF;*/
}

.rangeField-value_right {
  right: -43px;
  font-size: 18px;
  font-weight: 600;
  margin-left: 5px;
  /*color: #664AFF;*/
}

.sliderValue{
  /*position: relative;*/
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #664AFF;
}

.sliderValue_Text {
  position: absolute;
  height: 45px;
  width: 45px;
  /*background: #664AFF;*/
  /*background: red;*/
  color: #fff;
  font-weight: 500;
  top: -40px;

  /*transform: translateX(-50%) scale(0);*/
  transform: translateX(-50%);
  transform-origin: bottom;
  transition: transform 0.3s ease-in-out;
  line-height: 55px;
  z-index: 2;
}

.sliderValue_Text:after {
  position: absolute;
  content: "";
  height: 45px;
  width: 45px;
  background: #664AFF;
  /*background: red;*/
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  border: 3px solid #fff;
  z-index: -1;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  border-bottom-left-radius: 50%;}

.sliderValue_Text .show{
  transform: translateX(-50%) scale(1);
}

.output-area {
  display: flex;
  align-items: center;
  justify-content: center;
}


.wine_profile_textarea_input {
  width: 100%;
  height: 150px;
  padding: 15px 20px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #ced4da;

  /* search__input
  width: 100%;
  border-radius: 2px;

  line-height: 1.75;*/
  font-weight: 400;
  font-size: 14px;
  /*height: 34px;*/
  border-color: var(--cool-gray-30,#e1e9ee);
  background-color: var(--voyager-color-background-input-search);
  border-radius: var(--corner-radius-small);
  color: var(--color-text-active);
  /*padding: 0 8px 0 40px;


  border: none;
  box-shadow: none;
  */
}
.wine_profile_textarea_input:focus {
  border: 1px solid #DFE5EB;
  /*outline: 0;*/
}

.wine_profile_textarea_input:hover {
    /*border: none;*/
    box-shadow: none;
    cursor: text;
}

.wine_profile_box_input {
  vertical-align: middle;
  text-indent: 10px;
  width: 100%;
  border: 1px solid #ced4da;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background-color: #fff;
  font-size: 16px;
  height: calc(1.5em + .45rem + 2px);
  font: 400 ;

} ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aab7c4;
  opacity: 1; /* Firefox */
}

.wine_profile_box_input:focus {
  border: 1px solid #DFE5EB;
  /*outline: 0;*/
  /*outline: none;*/
}

.wine_profile_box_input:hover {
    /*border: none;*/
    box-shadow: none;
    cursor: text;
}

.wine_profile_search_box_input {
  vertical-align: middle;
  text-indent: 25px;
  width: 100%;
  border-bottom: 1px solid #ced4da;
  border-top: none;
  border-left: none;
  border-right: none;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background-color: #fff;
  font-size: 16px;
  height: calc(1.5em + .45rem + 2px);
  font: 400 ;

}::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aab7c4;
  opacity: 1; /* Firefox */
}

.wine_profile_search_box_input:focus {
  border-bottom: 1px solid #DFE5EB;

  /*outline: 0;*/
  outline: none;
}

.wine_profile_search_box_input:hover {
    border-bottom: 1px solid #DFE5EB;
    box-shadow: none;
    cursor: text;
}

.search_box_styles {
  color: #aab7c4
}

.wine_profile_for_paragraph {
  min-height: 14px;
}

.wine_profile_select_box_input {
  vertical-align: middle;
  text-indent: 10px;
  width: 100%;
  border: 1px solid #ced4da;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background-color: #fff;
  font-size: 16px;
  height: calc(1.5em + .45rem + 2px);
  font: 400 ;

} ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aab7c4;
  opacity: 1; /* Firefox */
}

.wine_profile_select_box_input:focus {
  border: 1px solid #DFE5EB;
  /*outline: 0;*/
  /*outline: none;*/
}

.wine_profile_select_box_input:hover {
    /*border: none;*/
    box-shadow: none;
    cursor:pointer;

}

.binary_selection_input_buttons {
  list-style: none;
  margin-left: 0;
  border-bottom: 1px solid #ced4da;
  /*padding-bottom: 1rem;*/
}

.binary_selection_input_buttons_value {
  display: block;
      margin: 0 0 .25rem;
}

.checkbox_label {
  display: block;
  padding-left: 15px;
  text-indent: -15px;
}

.wine_profile_checkbox_input {
  /*vertical-align: middle;*/
  vertical-align: middle;
  position: relative;
  top: -1px;
  text-indent: 10px;
  /*width: 100%;*/
  height: 15px;
  width: 15px;
  border: 1px solid #ced4da;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background-color: #fff;
  font-size: 16px;
  height: calc(1.5em + .45rem + 2px);
  font: 400 ;



} ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aab7c4;
  opacity: 1; /* Firefox */
}

.wine_profile_checkbox_input:focus {
  border: 1px solid #DFE5EB;
  /*outline: 0;*/
  /*outline: none;*/
}

.wine_profile_checkbox_input:hover {
    /*border: none;*/
    box-shadow: none;
    cursor: pointer;
}

.confirm-update-button {
  /*margin-top: 1rem;*/
  background-color: #212121;
  color: white;

  display: block;
  width: 100%;
  margin-left: 0;
  margin-right: 0;

  /*border styling*/
  border: 1px solid #212121;
  border-radius: .25rem;

  /*button text format*/
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  padding: 1rem 1.5rem;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  text-align: center;
  line-height: 1rem;

}

.confirm-update-button:hover {
    background-color: #212121;
    color: #767676;
}

.cancel-update-button {
  /*margin-top: 1rem;*/
  background-color: white;
  color: #212121;

  display: block;
  width: 100%;
  margin-left: 0;
  margin-right: 0;

  /*border styling*/
  border: 1px solid #212121;
  border-radius: .25rem;

  /*button text format*/
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  padding: 1rem 1.5rem;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  text-align: center;
  line-height: 1rem;

}

.cancel-update-button:hover {
    background-color: #212121;
    color: #767676;
}

.wrapper_search_box {
  /*Controls the responsiveness of the search list*/
  /*width:100%;*/

  display: block;
  width: 100%;
  /*z-index: 9999;*/
  z-index: 0;
  position: relative;

  border: none;

  /*background-color: #fff;
  font-size: 16px;
  height: calc(1.5em + .45rem + 2px);
  font: 400 ;
  border-bottom:1px solid #ced4da;*/

}::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aab7c4;
  opacity: 1; /* Firefox */
}

.wrapper_search_box:focus {
  outline: none;
}

.wrapper_search_box:hover {
}

.search-input {
  position: relative;
  background: #fff;
  width: 100%;
  border-radius: 5px;
  /*z-index:100;*/
  z-index: 0;
  opacity: 1;
  /*box-shadow: 0px 1px 5px 3px rgba(0, 0, 0, 0.12);*/

}

.search-input.active {

}

.search-input  input  {
  vertical-align: middle;
  text-indent: 25px;
  width: 100%;
  border-bottom: 1px solid #ced4da;
  border-top: none;
  border-left: none;
  border-right: none;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  /*background-color: #fff;*/
  background-color: transparent;
  font-size: 16px;
  height: calc(1.5em + .45rem + 2px);
  font: 400 ;
  /*outline: none;
  border: none;
  border-radius: 5px;
  padding: 0 60px 0 20px;
  font-size: 18px;*/
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  z-index: 0 !important;
}::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aab7c4;
  opacity: 1; /* Firefox */
}


.search-input  input:focus {
  border-bottom: 1px solid #DFE5EB;
  outline: none;
}

.search-input  input:hover {
    border-bottom: 1px solid #DFE5EB;
    box-shadow: none;
    cursor: text;
}

.search-input_icon {
  color: #aab7c4;
  /*height: 55px;
  width: 55px;
  line-height: 55px;
  position: absolute;
  top: 0;
  right: 0;
  text-align: center;
  font-size: 20px;
  cursor: pointer;*/
}

.autocom-box {
  /*background: #fff;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0px 1px 5px 3px rgba(0, 0, 0, 0.12);
  z-index: 10;*/
  padding: 0px; /*hide contents*/
  max-height: 280px;
  overflow-y: auto;
  opacity: 0; /*hide contents*/
  pointer-events: none; /*hide contents*/
  border-bottom: 1px solid #ced4da;
  border-left: 1px solid #ced4da;
  border-right: 1px solid #ced4da;
  /*z-index: 100 !important;*/
  z-index: 0;


}

.autocom-box.active {
  padding: 10px 8px;
  opacity: 1 !important; /*hide contents*/
  pointer-events: auto !important; /*hide contents*/
  /*z-index: 9999;*/
  z-index: 0;

}

.autocom-box li {
  list-style: none;
  padding: 8px 12px;
  width: 100%;
  cursor: default;
  border-radius: 3px;
  display: none; /*hide contents*/
  /*z-index: 9999;*/
  z-index: 0;
}

.autocom-box.active li {
  display: block;
  /*z-index: 9999;*/
  z-index: 0;
}


.autocom-box li:hover {
  background: #efefef;
  cursor: pointer;
}

.section-title-wrapper {

  /*border-bottom: 1px solid #e9e9e9;
  color: #686868;*/
  margin: 5px;
  /*padding: 20px;*/
}

.section-button-wrapper {

  /*border-bottom: 1px solid #e9e9e9;
  color: #686868;*/
  margin: 5px;
  /*padding: 20px;*/
}

.split-with-separator-alignment {
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  /*z-index: 2;
  /*color: #8d8478;*/
  /*color: #000;
  font-weight: 400;
  font-size: 18px;
  text-transform: uppercase;
  text-align: center;*/
}

.wineListItem {

}

.wineListItem:hover {
    cursor:pointer;
}

.winelist-stack {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.winelist-stack-element-main {
    margin: 0 0 0 0;
    padding: 0 10px 0 10px;
    width: 100%;
    margin-bottom: 5px;
}

.winelist-stack-element-inner {
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    width: 100%;
}

.winelist-stack-entry-inner {
    margin: 0 0 0 0;
    padding: 0 5px 0 5px;
    width: 100%;
}

.winelist-listing-cards{
  width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.winelist-listing-entry{
  /*box-shadow:hsl(0, 0%, 80%) 0 0 16px;*/
  box-shadow: 0 0.125rem 0.5rem hsl(0deg 0% 60% / 40%)
}



.winelist-listing-entry-title {
  text-transform: none;
  font-size: 18px; /*1.375rem;*/
  font-weight: 400;
  text-align: left;
  align-items: left;
}

.winelist-listing-entry-title:hover {
    cursor:pointer;
}

.winelist-listing-entry__description {
    color: #888888;
    text-decoration: none;
    font-size: 12px;
    text-transform: none;
    font-weight: 500;
    align-items: left;
}

.add_to_event_experience_btn {
  width: 100%;
  /*background: #33ccff !important;*/
  /*color: white !important;*/
  color: #212121 !important;
  border-color:#212121 !important;
  background: transparent;
  transition: border-color 0.2s;
  align: center;

}

.add_to_event_experience_btn:hover {
  cursor: pointer;
  color: white !important;
  border-color: white;
  background-color:#212121 !important;
}

.event_experience_select_wrapper {
  padding: 5px 5px 5px 5px;
}
