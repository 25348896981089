/*
  Testimonial stylings can be found at
  https://codepen.io/feyinway/pen/LjMPgv?editors=0100
*/
@import url(//cdn.rawgit.com/rtaibah/dubai-font-cdn/master/dubai-font.css);


*,
*:after,
*:before {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    -moz-user-select: none;
    user-select: none;
    cursor: default;
}

.testimonial {
  width: 100%;
  /*position: absolute;*/
  /*top: 50%;*/


}

.testimonial .wrap {
  position: relative;
  width: 100%;
  /*max-width: 1020px;*/
  padding: 40px 20px;
  margin: auto;
  
}

.testimonial .arrow {
  display: block;
  position: absolute;
  color: black;
  /*color: #eee;*/
  cursor: pointer;
  font-size: 2em;
  top: 50%;

  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  padding: 5px;
  z-index: 99;
}

.testimonial .arrow:before {
  cursor: pointer;
}

.testimonial .arrow:hover {
  /*color: #ea830e;*/
  color: black;
}

.testimonial .arrow.left {
  left : 10px;
  color: rgba(255, 255, 255, 0.0 ); /* hidden the arrow from view*/
}

.testimonial .arrow.right {
  right : 10px;
  color: rgba(255, 255, 255, 0.0 ); /* hidden the arrow from view*/
}

.testimonial .dots {
    text-align: center;
    position: absolute;
    width: 100%;
    bottom: 60px;
    left: 0;

    display: block;
    z-index: 92;
		height: 12px;
}

.testimonial .dots .dot {
    list-style-type: none;
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    /*border: 1px solid #eee;*/
    border: 1px solid black;
    margin: 0 10px;
    cursor: pointer;
    -webkit-transition: all .5s ease-in-out;
    -ms-transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
		position: relative;
    background: rgba(141, 132, 120, 1.0); /*color: #8D8478;*/
    border-color: rgba(141, 132, 120, 0.0);

}

.testimonial .dots .dot.active
{
    background: rgba(30, 30, 30, 1.0); /* #1e1e1e*/
    border-color: rgba(30, 30, 30, 0.0);
}
.testimonial .dots .dot:hover {
    background: rgba(0, 0, 0, 1.0);

    border: 4px solid rgba(255, 255, 255, 1.0);
}

.testimonial .dots .dot.active {
    -webkit-animation: testim-scale .5s ease-in-out forwards;
    -moz-animation: testim-scale .5s ease-in-out forwards;
    -ms-animation: testim-scale .5s ease-in-out forwards;
    -o-animation: testim-scale .5s ease-in-out forwards;
    animation: testim-scale .5s ease-in-out forwards;
}

.testimonial .testim-content{
    position: relative;
		overflow: hidden;
}

.testimonial .testim-content > div {
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 0 70px 0;
    opacity: 0;
}

.testimonial .testim-content > div.inactive {
    opacity: 0;
    /* I changed the opacity to zero so it is not visible */
}

.testimonial .testim-content > div.active {
    position: relative;
    opacity: 1;
}

.testimonial .testim-content div h3 {
    color: #ea830e;
    font-size: 1em;
    margin: 15px 0;
}


.testimonial .testim-content .testimonial__text {
    font-size: 24px;
    /*color: #eee;*/
    color: black;
    width: 80%;
    margin: auto;
    font-weight: 900;
}

.testimonial .testim-content .testimonial__experience_text {
    font-size: 10px;
    text-transform: uppercase;
    /*color: #eee;*/
    color: black;
    width: 80%;
    margin: auto;
}

.testimonial .testim-content .testimonial__author {
    font-size: 12px;
    text-transform: uppercase;
    /*color: #eee;*/
    color: black;
    width: 80%;
    margin: auto;
}
/*.testimonial .testim-content div p {
    font-size: 1.15em;
    /*color: #eee;*/
/*    color: black;
    width: 80%;
    margin: auto;
}*/

.testimonial .testim-content div.active .img img {
    -webkit-animation: testim-show .5s ease-in-out forwards;
    -moz-animation: testim-show .5s ease-in-out forwards;
    -ms-animation: testim-show .5s ease-in-out forwards;
    -o-animation: testim-show .5s ease-in-out forwards;
    animation: testim-show .5s ease-in-out forwards;
}

.testimonial .testim-content div.active h3 {
    -webkit-animation: testim-content-in .4s ease-in-out forwards;
    -moz-animation: testim-content-in .4s ease-in-out forwards;
    -ms-animation: testim-content-in .4s ease-in-out forwards;
    -o-animation: testim-content-in .4s ease-in-out forwards;
    animation: testim-content-in .4s ease-in-out forwards;
}

.testimonial .testim-content div.active p {
    -webkit-animation: testim-content-in .5s ease-in-out forwards;
    -moz-animation: testim-content-in .5s ease-in-out forwards;
    -ms-animation: testim-content-in .5s ease-in-out forwards;
    -o-animation: testim-content-in .5s ease-in-out forwards;
    animation: testim-content-in .5s ease-in-out forwards;
}

.testimonial .testim-content div.inactive .img img {
    -webkit-animation: testim-hide .5s ease-in-out forwards;
    -moz-animation: testim-hide .5s ease-in-out forwards;
    -ms-animation: testim-hide .5s ease-in-out forwards;
    -o-animation: testim-hide .5s ease-in-out forwards;
    animation: testim-hide .5s ease-in-out forwards;
}

.testimonial .testim-content div.inactive h3 {
    -webkit-animation: testim-content-out .4s ease-in-out forwards;
    -moz-animation: testim-content-out .4s ease-in-out forwards;
    -ms-animation: testim-content-out .4s ease-in-out forwards;
    -o-animation: testim-content-out .4s ease-in-out forwards;
    animation: testim-content-out .4s ease-in-out forwards;
}

.testimonial .testim-content div.inactive p {
    -webkit-animation: testim-content-out .5s ease-in-out forwards;
    -moz-animation: testim-content-out .5s ease-in-out forwards;
    -ms-animation: testim-content-out .5s ease-in-out forwards;
    -o-animation: testim-content-out .5s ease-in-out forwards;
    animation: testim-content-out .5s ease-in-out forwards;
}

@-webkit-keyframes testim-content-in {
    from {
        opacity: 0;
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
    }

    to {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@-moz-keyframes testim-content-in {
    from {
        opacity: 0;
        -moz-transform: translateY(100%);
        transform: translateY(100%);
    }

    to {
        opacity: 1;
        -moz-transform: translateY(0);
        transform: translateY(0);
    }
}

@-ms-keyframes testim-content-in {
    from {
        opacity: 0;
        -ms-transform: translateY(100%);
        transform: translateY(100%);
    }

    to {
        opacity: 1;
        -ms-transform: translateY(0);
        transform: translateY(0);
    }
}

@-o-keyframes testim-content-in {
    from {
        opacity: 0;
        -o-transform: translateY(100%);
        transform: translateY(100%);
    }

    to {
        opacity: 1;
        -o-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes testim-content-in {
    from {
        opacity: 0;
        transform: translateY(100%);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@-webkit-keyframes testim-content-out {
    from {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    to {
        opacity: 0;
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
    }
}

@-moz-keyframes testim-content-out {
    from {
        opacity: 1;
        -moz-transform: translateY(0);
        transform: translateY(0);
    }

    to {
        opacity: 0;
        -moz-transform: translateY(-100%);
        transform: translateY(-100%);
    }
}

@-ms-keyframes testim-content-out {
    from {
        opacity: 1;
        -ms-transform: translateY(0);
        transform: translateY(0);
    }

    to {
        opacity: 0;
        -ms-transform: translateY(-100%);
        transform: translateY(-100%);
    }
}

@-o-keyframes testim-content-out {
    from {
        opacity: 1;
        -o-transform: translateY(0);
        transform: translateY(0);
    }

    to {
        opacity: 0;
        transform: translateY(-100%);
        transform: translateY(-100%);
    }
}

@keyframes testim-content-out {
    from {
        opacity: 1;
        transform: translateY(0);
    }

    to {
        opacity: 0;
        transform: translateY(-100%);
    }
}

@-webkit-keyframes testim-show {
    from {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    to {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@-moz-keyframes testim-show {
    from {
        opacity: 0;
        -moz-transform: scale(0);
        transform: scale(0);
    }

    to {
        opacity: 1;
        -moz-transform: scale(1);
        transform: scale(1);
    }
}

@-ms-keyframes testim-show {
    from {
        opacity: 0;
        -ms-transform: scale(0);
        transform: scale(0);
    }

    to {
        opacity: 1;
        -ms-transform: scale(1);
        transform: scale(1);
    }
}

@-o-keyframes testim-show {
    from {
        opacity: 0;
        -o-transform: scale(0);
        transform: scale(0);
    }

    to {
        opacity: 1;
        -o-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes testim-show {
    from {
        opacity: 0;
        transform: scale(0);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}

@-webkit-keyframes testim-hide {
    from {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    to {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
    }
}

@-moz-keyframes testim-hide {
    from {
        opacity: 1;
        -moz-transform: scale(1);
        transform: scale(1);
    }

    to {
        opacity: 0;
        -moz-transform: scale(0);
        transform: scale(0);
    }
}

@-ms-keyframes testim-hide {
    from {
        opacity: 1;
        -ms-transform: scale(1);
        transform: scale(1);
    }

    to {
        opacity: 0;
        -ms-transform: scale(0);
        transform: scale(0);
    }
}

@-o-keyframes testim-hide {
    from {
        opacity: 1;
        -o-transform: scale(1);
        transform: scale(1);
    }

    to {
        opacity: 0;
        -o-transform: scale(0);
        transform: scale(0);
    }
}

@keyframes testim-hide {
    from {
        opacity: 1;
        transform: scale(1);
    }

    to {
        opacity: 0;
        transform: scale(0);
    }
}

@media all and (max-width: 300px) {
	body {
		font-size: 14px;
	}
}

@media all and (max-width: 500px) {
	.testimonial .arrow {
		font-size: 1.5em;
	}

	.testimonial .testim-content div p {
		line-height: 25px;
	}

}
